import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaStar, FaSearch } from 'react-icons/fa';
import restaurantsData from './list_of_resturants_c.json';

const RestaurantGrid = () => {
  const { city } = useParams();
  const navigate = useNavigate();

  const cityRestaurants = restaurantsData[city] || [];

  const [visibleRestaurants, setVisibleRestaurants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const observer = useRef();
  const itemsPerPage = 9;

  const filteredRestaurants = cityRestaurants.filter(
    (restaurant) =>
      restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      restaurant.tags.some((tag) =>
        tag.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const loadMoreRestaurants = useCallback(() => {
    if (loading) return;
    setLoading(true);
    const startIndex = visibleRestaurants.length;
    const endIndex = startIndex + itemsPerPage;
    const nextRestaurants = filteredRestaurants.slice(startIndex, endIndex);
    setVisibleRestaurants((prev) => [...prev, ...nextRestaurants]);
    setLoading(false);
  }, [visibleRestaurants, filteredRestaurants, loading]);

  const lastRestaurantRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && visibleRestaurants.length < filteredRestaurants.length) {
          loadMoreRestaurants();
        }
      });
      if (node) observer.current.observe(node);
    },
    [visibleRestaurants, filteredRestaurants, loadMoreRestaurants]
  );

  useEffect(() => {
    setVisibleRestaurants([]);
    loadMoreRestaurants();
  }, [searchTerm, city]);

  const handleRestaurantClick = (uuid) => {
    navigate(`/user-dashboard/restaurant/${uuid}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="bg-gray-900 min-h-screen p-8">
      {/* Title and Subheading */}
      <div className="text-center mb-8">
        <h1 className="text-4xl text-white font-bold mb-4">
          Restaurants in {city}
        </h1>
        <p className="text-gray-400">
          Discover exclusive gastronomy deals in {city} with WorldLynk! Whether it's coffee house flair,
          exquisite burger creations, heavenly desserts, or authentic Asian dishes – our platform opens
          the door to a world full of taste experiences. Get access to special offers like our 2-for-1
          main courses and enjoy exquisite meals at unique prices.
        </p>
      </div>

      {/* Search Bar and Filters */}
      <div className="flex flex-col md:flex-row items-center justify-between gap-4">
        {/* Search Bar */}
        <div className="flex items-center border border-gray-700 rounded-full px-4 py-2 w-full md:w-1/2">
          <FaSearch className="text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Search for a restaurant"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full outline-none bg-transparent text-white placeholder-gray-400"
          />
        </div>

        {/* Filter Options */}
        <div className="flex items-center text-white flex-wrap gap-2">
          {[
            { name: 'Event Deal', icon: '⭐' },
            { name: 'Café', icon: '☕' },
            { name: 'Meat', icon: '🥩' },
            { name: 'Drinks', icon: '🍹' },
            { name: 'Breakfast', icon: '🍳' },
            { name: 'Burgers', icon: '🍔' },
            { name: 'Asian', icon: '🍱' },
            { name: 'Dessert', icon: '🍨' },
          ].map((filter, index) => (
            <button
              key={index}
              className="flex items-center gap-1 border border-gray-700 px-3 py-1 rounded-full text-sm hover:bg-gray-800 transition"
            >
              <span>{filter.icon}</span>
              <span>{filter.name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Grid of Restaurants */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
        {visibleRestaurants.map((restaurant, index) => (
          <div
            key={`${restaurant.uuid}-${index}`}
            ref={visibleRestaurants.length === index + 1 ? lastRestaurantRef : null}
            className="bg-gray-800 rounded-lg overflow-hidden shadow-md cursor-pointer"
            onClick={() => handleRestaurantClick(restaurant.uuid)}
          >
            <img
              src={restaurant.images[0]?.url}
              alt={restaurant.name}
              className="w-full h-48 object-cover"
            />

            <div className="p-4 space-y-2">
              {restaurant.label && (
                <span className="text-xs font-bold text-green-500 uppercase">
                  {restaurant.label}
                </span>
              )}

              <h2 className="text-xl font-semibold text-white">{restaurant.name}</h2>

              <div className="flex items-center space-x-2 text-sm text-gray-400">
                <span className="flex items-center text-green-400">
                  <FaStar className="mr-1" />
                  {restaurant.avgRating}
                </span>
                <span>({restaurant.ratingsCount} Reviews)</span>
                <span>|</span>
                <span>{restaurant.tags.map((tag) => tag.name).join(', ')}</span>
              </div>

              <div className="flex flex-wrap gap-2 mt-2">
                {restaurant.deals.map((deal) => (
                  <span
                    key={deal.uuid}
                    className="bg-green-600 text-white text-xs font-bold px-2 py-1 rounded"
                  >
                    {deal.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Loader */}
      {loading && (
        <div className="text-center mt-8 text-white">
          Loading more restaurants...
        </div>
      )}
    </div>
  );
};

export default RestaurantGrid;