import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend)

const JobsDashboard = () => {
  const barData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Applications',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  }

  const lineData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Trends',
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  }

  return (
    <div className="w-4xl mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Applications Analysis Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">Applications Analysis</h2>
          <div className="bg-gray-700 p-6 rounded-lg">
            <Bar data={barData} />
            <p className="text-white text-lg mt-4">Number of Applications: <span className="font-semibold">120</span></p>
            <p className="text-white text-lg">Pending Applications: <span className="font-semibold">30</span></p>
            <p className="text-white text-lg">Approved Applications: <span className="font-semibold">70</span></p>
            <p className="text-white text-lg">Rejected Applications: <span className="font-semibold">20</span></p>
          </div>
        </section>
        
        {/* Trends Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">Trends</h2>
          <div className="bg-gray-700 p-6 rounded-lg">
            <Line data={lineData} />
            <p className="text-white text-lg mt-4">Monthly Application Trends</p>
          </div>
        </section>
        
        {/* Other Metrics Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">Other Metrics</h2>
          <div className="bg-gray-700 p-6 rounded-lg">
            <p className="text-white text-lg">Average Time to Hire: <span className="font-semibold">25 days</span></p>
            <p className="text-white text-lg">Job Openings: <span className="font-semibold">15</span></p>
            <p className="text-white text-lg">Active Recruiters: <span className="font-semibold">5</span></p>
          </div>
        </section>
        
        {/* Performance Metrics Section */}
        <section>
          <h2 className="text-2xl font-bold text-white mb-4">Performance Metrics</h2>
          <div className="bg-gray-700 p-6 rounded-lg">
            <p className="text-white text-lg">Time to Fill: <span className="font-semibold">30 days</span></p>
            <p className="text-white text-lg">Offer Acceptance Rate: <span className="font-semibold">85%</span></p>
            <p className="text-white text-lg">Candidate Satisfaction: <span className="font-semibold">90%</span></p>
          </div>
        </section>
      </div>
    </div>
  )
}

export default JobsDashboard