import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../userProvider/AuthProvider';
import Unauthorized from './Unauthorized';

function PrivateRoute({ role }) {
  const { userRole, currentUser, loading } = useAuth();

  // Debugging logs
  console.log("PrivateRoute currentUser:", currentUser);
  console.log("User role from auth:", userRole);
  console.log("Required role from app:", role);

  // Show a loading spinner or placeholder while authentication state is loading
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mb-4"></div>
          <p className="text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  // If the user is not logged in, redirect to the login page
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If the user is logged in but doesn't have the correct role, redirect to an unauthorized page or 404
  if (role && userRole !== role) {
    return <Unauthorized />; // Updated to use the correct component
  }

  // If user is authenticated and has the correct role, render the requested route
  return <Outlet />;
}

export default PrivateRoute;
