import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import TicketCard from './TicketCard';
import { firestore } from '../../../firebase';
import { ClipLoader } from 'react-spinners';

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchBookings = async () => {
      if (!currentUser) return;

      try {
        const q = query(
          collection(firestore, 'payments'),
          where('user.uid', '==', currentUser.uid)
        );
        const querySnapshot = await getDocs(q);

        const fetchedBookings = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setBookings(fetchedBookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchBookings();
  }, [currentUser, firestore]);

  return (
    <div className="bg-gray-900 min-h-screen">
      <h1 className="text-2xl text-white sticky font-bold mb-6">My Bookings</h1>
      {loading ? ( // Show ClipLoader while loading is true
        <div className="flex justify-center items-center h-64">
          <ClipLoader color="#fff" loading={loading} size={50} />
        </div>
      ) : bookings.length > 0 ? (
        bookings.map(booking => (
          <div key={booking.id} className="mb-4">
            <TicketCard
              ticketId={booking.orderId || booking.id}
              date={booking.user?.eventDate || 'N/A'}
              month={new Date().toLocaleDateString('en-US', { month: 'short' })}
              eventName={booking.user?.eventTitle || 'Event Title Unavailable'}
              eventDay={booking.user?.eventDate || 'N/A'}
              eventTime={booking.user?.eventTime || 'Time Unavailable'}
              location={booking.user?.eventLocation || 'Location Unavailable'}
              qrCodeUrl={booking.qrCodeUrl} 
              booking_time={booking.created}
              status={booking.status}
            />
          </div>
        ))  
      ) : (
        <p>No bookings found.</p>
      )}
    </div>
  );
};

export default MyBookings;
