import React, { useState, useEffect, useRef } from 'react';

const ImageCompressor = ({ src, alt, className }) => {
  const [compressedSrc, setCompressedSrc] = useState(null); // For storing compressed image
  const [loading, setLoading] = useState(true); // For loading state
  const imgRef = useRef(null); // To reference the image element

  // Function to compress the image before rendering it
  const compressImage = async (img) => {
    // Get the image's original width and height using the naturalWidth and naturalHeight
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    // Create a canvas to compress the image
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions to the original width and height
    canvas.width = width;
    canvas.height = height;

    // Draw the image onto the canvas
    ctx.drawImage(img, 0, 0, width, height);

    // Compress the image into a JPEG with 60% quality
    const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.6);

    // Set the compressed image source and stop loading
    setCompressedSrc(compressedDataUrl);
    setLoading(false); // Mark loading as complete
  };

  // Effect to handle image compression when the component mounts
  useEffect(() => {
    const img = new Image();
    img.src = src; // Set the image source
    img.alt = alt; // Set the alt text

    // Wait until the image is fully loaded (via onload) to get the dimensions
    img.onload = () => {
      // Once loaded, we can compress the image
      compressImage(img);
    };
  }, [src, alt]); // Run this effect when `src` or `alt` changes

  // If the image is still loading, show the loading state
  return loading ? (
    <p>Loading...</p>
  ) : (
    <img
      src={compressedSrc} // Use the compressed image source
      alt={alt}
      className={className} // Apply the provided className
    />
  );
};

export default ImageCompressor;
