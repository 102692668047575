import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import gsap from "gsap";
import lottie from "lottie-web";
import animationData from "../../../assets/lotties/students.json";
import googleSvg from "../../../assets/img/googleSvg.svg";
import linkedin from "../../../assets/img/linkedin.svg";
import meta from "../../../assets/img/meta.svg";
import LoginVector from "../../../assets/img/Authentication-rafiki 1.png"
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { serverTimestamp } from "firebase/firestore";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const validatePasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
};

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const Login_Test = () => {
    const { getUserRole, userRole } = useAuth();
    const navigate = useNavigate();
    const container = useRef(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const togglePasswordVisibility_s = () => {
        setFormData((prevState) => ({
          ...prevState,
          showPassword: !prevState.showPassword,
        }));
      };
    const handleSignUp = async () => {
        if (!validateEmail(formData.email)) {
            toast.error("Please enter a valid email address.", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark"
            });
            return;
        }

        if (!validatePasswordStrength(formData.password)) {
            toast.error("Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark"
            });
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            await setDoc(doc(firestore, "users", user.uid), {
                userId: user.uid,
                name: formData.name,
                // lastName: formData.lastName,
                email: formData.email,
                role: "student",
                createdAt: serverTimestamp(), // Added createdAt field
            });

            toast.success("Signup successful!", { position: "top-center", autoClose: 3000, theme: "dark" });

            // Wait for AuthProvider to update currentUser and userRole
            let attempts = 0;
            const maxAttempts = 10;
            const delay = 300; // milliseconds

            while (attempts < maxAttempts) {
                const userRole = await getUserRole(user.uid);
                if (userRole) {
                    navigate("/user-dashboard/events");
                    return;
                }
                attempts++;
                await new Promise((resolve) => setTimeout(resolve, delay));
            }

            toast.error("Failed to retrieve user role. Please try again.", { position: "top-center", autoClose: 5000, theme: "dark" });
        } catch (error) {
            console.error("Signup Error:", error);
            let errorMessage = "Signup failed. Please try again.";

            switch (error.code) {
                case "auth/email-already-in-use":
                    errorMessage = "Email is already in use. Please use a different email.";
                    break;
                case "auth/invalid-email":
                    errorMessage = "Invalid email address. Please enter a valid email.";
                    break;
                case "auth/weak-password":
                    errorMessage = "Password is too weak. Please enter a stronger password.";
                    break;
                case "auth/operation-not-allowed":
                    errorMessage = "Operation not allowed. Please contact support.";
                    break;
                default:
                    errorMessage = `Signup failed: ${error.message}`;
                    break;
            }

            toast.error(errorMessage, { position: "top-center", autoClose: 5000, theme: "dark" });
        }
    };
    useEffect(() => {
        if (userRole) {
            if (userRole === "admin") {
                navigate("/business/overview");
            } else if (userRole === "student") {
                navigate("/user-dashboard/events");
            }
        }
    }, [userRole, navigate]);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: container.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData,
        });

        gsap.fromTo(
            container.current,
            { opacity: 0, scale: 0.5 },
            { opacity: 1, scale: 1, duration: 1, ease: "power3.out", delay: 0.5 }
        );

        return () => {
            animation.destroy();
        };
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const toggleSignUpForm = () => {
        setShowSignUpForm(!showSignUpForm);
        setShowForgotPasswordForm(false);
    };

    const toggleForgotPasswordForm = () => {
        setShowForgotPasswordForm(!showForgotPasswordForm);
        setShowSignUpForm(false);
    };

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user.uid;
            const role = await getUserRole(user);

            if (role === "admin") {
                navigate("/business/overview");
            } else if (role === "student") {
                navigate("/user-dashboard/events");
            } else {
                await signOut(auth);
                throw new Error("Access denied: User is not a student or admin");
            }

            toast.success("Login successful!", {
                position: "top-right",
                autoClose: 1200,
                theme: "dark"
            });
        } catch (error) {
            console.error("Login Error:", error);
            toast.error("Invalid credentials. Please try again.", {
                position: "top-center",
                autoClose: 1500,
                theme: "dark"
            });
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, new GoogleAuthProvider());
            const user = result.user;
            const userDocRef = doc(firestore, "users", user.uid);

            // Check if the user document exists
            let userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // If the user document doesn't exist, create a new one with a 'student' role.
                await setDoc(userDocRef, {
                    userId: user.uid,
                    role: "student",
                    email: user.email,
                    name: user.displayName,
                    createdAt: serverTimestamp(),
                });

                // Fetch the document again after creating it
                userDoc = await getDoc(userDocRef);
                toast.success("Registration successful!", { theme: "dark" });
            }

            // Now, fetch the role from the user document
            const userData = userDoc.data();
            const role = userData.role;

            // Ensure the role is set correctly before navigation
            if (role === "admin") {
                navigate("/business/overview");
            } else if (role === "student") {
                navigate("/user-dashboard/events");
            } else {
                throw new Error("Unexpected role. Access denied.");
            }

            toast.success("Login with Google successful!", { theme: "dark" });
        } catch (error) {
            console.error("Google Login Error:", error);
            toast.error(`Google Login Error: ${error.message}`, { theme: "dark" });
        }
    };



    const handleForgotPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            toast.success("Password reset email sent. Please check your inbox.", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark"
            });
            setShowForgotPasswordForm(false);
        } catch (error) {
            console.error("Forgot Password Error:", error);
            toast.error("Failed to send password reset email. Please try again.", {
                position: "top-center",
                autoClose: 5000,
                theme: "dark"
            });
        }
    };

    return (
        <div className="min-h-screen bg-gray-900">
            <div className="flex flex-col md:flex-row h-screen">
                {/* Lottie container - hidden on mobile */}
                <div className="hidden md:flex md:w-1/2 justify-center items-center">
                    {/* <div ref={container} className="min-h-[300px] min-w-[300px] opacity-100"></div> */}
                    <img src={LoginVector} className="max-w-full h-auto"></img>
                </div>

                {/* Form container - full width on mobile */}
                <div className="w-full md:w-1/2 flex justify-center items-center text-white min-h-screen md:min-h-0">
                    <div className="w-11/12 max-w-md bg-white text-secondary p-8 rounded-3xl my-8 md:my-0">
                        {showSignUpForm ? (
                            <form>
                                <h1 className="text-2xl font-bold mb-6">SignUp</h1>
                                <div className="mt-6 text-center">
                                    <div className="flex justify-center space-x-4">
                                        <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={handleGoogleLogin}>
                                            <img src={googleSvg} alt="Google" className="w-full h-full" />
                                        </div>
                                        <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={() => toast.info("LinkedIn login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                                            <img src={linkedin} alt="LinkedIn" className="w-full h-full" />
                                        </div>
                                        <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={() => toast.info("Meta login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                                            <img src={meta} alt="Meta" className="w-full h-full" />
                                        </div>
                                    </div>
                                </div>
                                <p className="my-3 text-sm text-gray-400">or login with email</p>
                                <div className="relative mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder=" "
                                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label for="fullname" class="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                                        Full Name
                                    </label>
                                </div>
                                {/* <div className="mb-4">
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="w-full p-2 border border-gray-300 rounded text-black"
                  />
                </div> */}
                                <div className="relative mb-3">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder=" "
                                        autoComplete="username"
                                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label for="email" class="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                                        Email Address
                                    </label>
                                </div>
                                <div className="relative mb-3">
        <input
          type={formData.showPassword ? "text" : "password"}
          name="password"
          id="password"
          value={formData.password}
          onChange={handleChange}
          placeholder=" "
          autoComplete="current-password"
          className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
        />
        <label htmlFor="password" className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
          Password
        </label>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          <button type="button" onClick={togglePasswordVisibility_s} className="focus:outline-none">
            {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className="w-full p-0.75 transition-all bg-blue-600 text-white rounded-xl hover:bg-blue-700"
                                        onClick={handleSignUp}
                                    >
                                        Register Account
                                    </button>
                                </div>
                                <div className="mt-4 text-center">
                                    <p>
                                        Already a member?{" "}
                                        <span className="text-blue-600 cursor-pointer" onClick={toggleSignUpForm}>Login now</span>
                                    </p>
                                </div>
                            </form>
                        ) : showForgotPasswordForm ? (
                            <form>
                                <h1 className="text-2xl font-bold mb-6">Forgot Password</h1>
                                <div className="relative mb-4">
                                    <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder=" "
                                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        autoComplete="username"
                                    />
                                    <label for="email" class="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                                        Email Address
                                    </label>
                                </div>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className="w-full p-0.75 transition-all bg-blue-600 text-white rounded-xl hover:bg-blue-700"
                                        onClick={handleForgotPassword}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                                <div className="mt-4 text-center">
                                    <p>
                                        Remember your password?{" "}
                                        <span className="text-blue-600 cursor-pointer" onClick={toggleForgotPasswordForm}>Login now</span>
                                    </p>
                                </div>
                            </form>
                        ) : (
                            <form>
                                <h1 className="text-2xl font-bold mb-6">Login</h1>
                                <div className="mt-6 text-center">
                                    <div className="flex justify-center space-x-4">
                                        <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={handleGoogleLogin}>
                                            <img src={googleSvg} alt="Google" className="w-full h-full" />
                                        </div>
                                        <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={() => toast.info("LinkedIn login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                                            <img src={linkedin} alt="LinkedIn" className="w-full h-full" />
                                        </div>
                                        <div className="w-8 h-8 rounded-full overflow-hidden cursor-pointer" onClick={() => toast.info("Meta login is not available right now. Please use another method.", { position: "top-center", autoClose: 3000, theme: "dark" })}>
                                            <img src={meta} alt="Meta" className="w-full h-full" />
                                        </div>
                                    </div>
                                </div>
                                <p className="my-3 text-sm text-gray-400">or login with email</p>
                                <div className="relative mb-3">
                                    <input

                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder=" "
                                        className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                        autoComplete="username"
                                    />
                                    <label for="email" class="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
                                        Email Address
                                    </label>
                                </div>
                                <div className="relative mb-3">
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder=" "
          className="block rounded-xl px-2.5 pb-1.5 pt-1.1 w-full text-sm text-gray-800 border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          autoComplete="current-password"
        />
        <label htmlFor="password" className="tracking-wide absolute text-sm text-gray-600 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto">
          Password
        </label>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          <button type="button" onClick={togglePasswordVisibility} className="focus:outline-none">
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>
                                <div className="mt-6">
                                    <button
                                        type="button"
                                        className="w-full p-0.75 transition-all bg-blue-600 text-white rounded-xl hover:bg-blue-700"
                                        onClick={handleLogin}
                                    >
                                        Login Account
                                    </button>
                                </div>
                                <div className="mt-4 text-center">
                                    <p>
                                        Not a member?{" "}
                                        <span className="text-blue-600 cursor-pointer" onClick={toggleSignUpForm}>Sign up now</span>
                                    </p>
                                </div>
                                <div className="mt-4 text-center">
                                    <span className="text-red-600 cursor-pointer" onClick={toggleForgotPasswordForm}>Forgot Password?</span>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Login_Test;
