import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEllipsisV } from 'react-icons/fa';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useAuth } from '../../auth/userProvider/AuthProvider';
import { firestore, storage } from '../../firebase';
import { scheduleData, friendsImages } from './utils';
import EditProfileModal from './EditProfileModal';
import { collection, query, where, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Calendar, Clock, User, FileText, ChevronRight } from 'lucide-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const [selectedDate, setSelectedDate] = useState('');
  const [videos, setVideos] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [dates, setDates] = useState([]);
  const videoRefs = useRef([]);
  const [startDate, setStartDate] = useState(new Date());
  const { userData } = useAuth();
  const [showOptions, setShowOptions] = useState({});
  const dropdownRef = useRef(null);
  // console.log(userData)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      if (!userData || !userData.userId) {
        console.log('userData or userId is missing:', userData);
        return;
      }

      console.log('Fetching posts for userId:', userData.userId);
      const postsRef = collection(firestore, 'reliv-posts');
      const q = query(
        postsRef,
        where('userId', '==', userData.userId),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({ ...doc.data(), src: doc.data().videoUrl, id: doc.id });
      });
      console.log('Fetched posts:', posts);
      setVideos(posts);
    };

    fetchPosts();
  }, [userData]);

  useEffect(() => {
    const fetchSchedules = async () => {
      if (!userData || !userData.userId) {
        console.log('userData or userId is missing:', userData);
        return;
      }

      console.log('Fetching schedules for userId:', userData.userId);
      const schedulesRef = collection(firestore, 'schedules');
      const q = query(
        schedulesRef,
        where('userId', '==', userData.userId),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const fetchedSchedules = [];
      querySnapshot.forEach((doc) => {
        fetchedSchedules.push(doc.data());
      });
      console.log('Fetched schedules:', fetchedSchedules);
      setSchedules(fetchedSchedules);
    };

    fetchSchedules();
  }, [userData]);

  useEffect(() => {
    generateDates(startDate);
  }, [startDate]);

  // Function to generate week dates
  const generateDates = (start) => {
    const tempDates = [];

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(start.getFullYear(), start.getMonth(), start.getDate() + i);
      const fullDate = nextDate.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
      tempDates.push(fullDate);
    }

    setDates(tempDates);
    setSelectedDate(tempDates[0]); // Set first date as default selected date
  };

  const getMonthAndYear = (date) => {
    const options = { month: 'long', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date); // E.g., 'October 2023'
  };

  const scrollRef = useRef(null);

  const handleMouseEnter = async (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      try {
        await videoElement.play();
      } catch (error) {
        console.log('Video play was blocked due to autoplay policy:', error);
      }
    }
  };

  const handleMouseLeave = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.pause();
    }
  };

  const scrollLeft = () => scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  const scrollRight = () => scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });

  const handleDelete = async (videoId) => {
    try {
      await deleteDoc(doc(firestore, 'reliv-posts', videoId));
      setVideos((prevVideos) => prevVideos.filter((video) => video.id !== videoId));
      toast.success('Video deleted successfully', { theme: 'dark' });
    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Error deleting video', { theme: 'dark' });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowOptions({}); // Close all dropdowns
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center relative">

      <div className="w-full">


        <div className="max-w-5xl w-full mx-auto px-4 sm:px-6">
          {/* Page Heading */}
          <h1 className="text-3xl md:text-4xl font-bold tracking-wider mb-6 md:mb-8 text-center md:text-left">Your World</h1>

          {/* Info and Profile Picture */}
          <div className="text-white max-w-4xl mx-auto mb-12">
            <div className="flex flex-col md:flex-row items-center md:items-start justify-between mb-10">

              {/* Profile Image Section */}
              <div className="md:w-1/3 flex flex-col items-center md:items-start justify-center md:justify-start mb-6 md:mb-0">
                <div className="relative w-32 h-32 md:w-48 md:h-48 mb-2">
                  <img
                    src={userData?.profileImage || 'https://via.placeholder.com/150'}
                    alt="Profile"
                    className="w-full h-full rounded-full object-cover shadow-md border-4 border-gray-700"
                  />
                  <button
                    onClick={() => navigate('/user-dashboard/profile')}
                    className="absolute bottom-2 right-2 bg-purple-600 hover:bg-purple-700 text-sm px-4 py-1 rounded-full text-white font-semibold transition-transform transform hover:scale-105"
                  >
                    Edit
                  </button>
                </div>
                {/* Username centered */}

              </div>



              {/* User Info Section */}
              <div className="md:w-2/3 w-full bg-gray-800 p-4 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold text-center md:text-left mb-4 text-gradient bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                  User Information
                </h2>
                <div className="space-y-3">
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Name</span>
                    <span className="text-md font-normal text-gray-400">{userData?.name || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Email</span>
                    <span className="text-md font-normal text-gray-400">{userData?.email || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Phone</span>
                    <span className="text-md font-normal text-gray-400">{userData?.phone || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">University</span>
                    <span className="text-md font-normal text-gray-400">{userData?.university || 'N/A'}</span>
                  </div>
                  <div className="flex justify-between border-b border-gray-700 py-1">
                    <span className="text-md font-semibold text-gray-300">Address</span>
                    <span className="text-md font-normal text-gray-400">{userData?.city || 'N/A'}, {userData?.country || 'N/A'}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div className="mt-8 md:mt-12 w-full text-left">
            <h2 className="text-2xl font-bold mb-4">Friends</h2>

            {/* Horizontal Scrollable Friends */}
            <div className="flex space-x-4 overflow-x-auto pb-4 scrollbar-hide">
              {friendsImages.map((src, i) => (
                <img key={i} src={src} alt={`Friend ${i + 1}`} className="w-16 h-16 md:w-24 md:h-24 rounded-full object-cover flex-shrink-0" />
              ))}
              <div className="w-16 h-16 md:w-24 md:h-24 rounded-full bg-gray-700 flex items-center justify-center text-sm flex-shrink-0">
                +154
              </div>
            </div>
          </div>

          {/* Reliv Experiences */}
          <div className="mt-10 relative w-full">
            {/* Header */}
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-semibold text-white mb-4">Reliv Experiences</h2>
              <div className="flex space-x-2">
                <button
                  onClick={scrollLeft}
                  className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white"
                >
                  <IoChevronBack size={24} />
                </button>
                <button
                  onClick={scrollRight}
                  className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white"
                >
                  <IoChevronForward size={24} />
                </button>
              </div>
            </div>

            {/* Videos in a Single Row */}
            <div
              className="flex overflow-x-auto space-x-6 scrollbar-hide"
              ref={scrollRef}
            >
              {videos.length > 0 ? (
                videos.map((video, index) => (
                  <div
                    key={index}
                    className="relative flex-shrink-0 w-64 h-64 rounded-lg overflow-hidden shadow-md bg-gray-800"
                  >
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      src={video.src}
                      className="w-full h-full object-cover"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      muted
                    >
                      Your browser does not support the video tag.
                    </video>
                    <div className="absolute top-2 right-2">
                      <div className="relative" ref={dropdownRef}>
                        <button
                          className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 text-white"
                          onClick={() =>
                            setShowOptions((prev) => ({ ...prev, [index]: !prev[index] }))
                          }
                        >
                          <FaEllipsisV />
                        </button>
                        {showOptions[index] && (
                          <div className="absolute right-0 mt-2 w-32 bg-white rounded-md shadow-lg z-10">
                            <button
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 rounded-md hover:bg-gray-300"
                              onClick={() => handleDelete(video.id)}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-400">
                  <p>No Reliv Experience yet.</p>
                  <p>Start sharing your moments with the world!</p>
                </div>
              )}
            </div>
          </div>




          {/* Schedule Section */}
          <div className="mt-12 w-full">
            <h2 className="text-2xl font-bold mb-4">Schedule</h2>

            {/* Date Navigation */}
            <div className="flex items-center space-x-4 overflow-x-auto pb-4">
              {/* Left Arrow */}
              <button
                onClick={() => {
                  const newStartDate = new Date(startDate.setDate(startDate.getDate() - 7));
                  setStartDate(newStartDate);
                  console.log('Navigated to previous week:', newStartDate);
                }}
              >
                <IoChevronBack size={24} />
              </button>

              {/* Dates (Calendar Tabs) */}
              {dates.map((date, index) => (
                <div
                  key={index}
                  className={`text-center px-4 py-2 rounded-full cursor-pointer ${selectedDate === date ? 'bg-green-200 text-black' : 'text-gray-400'
                    }`}
                  onClick={() => {
                    setSelectedDate(date);
                    console.log('Selected Date:', date);

                    // Debugging schedules filtered by date
                    const filteredSchedules = schedules.filter((schedule) => schedule.date === date);
                    console.log('Schedules for selected date:', filteredSchedules);
                  }}
                >
                  <p>{new Date(date).toLocaleDateString('en-US', { weekday: 'short' })}</p>
                  <p>{String(new Date(date).getDate())}</p> {/* Ensure date is a string */}
                </div>
              ))}

              {/* Right Arrow */}
              <button
                onClick={() => {
                  const newStartDate = new Date(startDate.setDate(startDate.getDate() + 7));
                  setStartDate(newStartDate);
                  console.log('Navigated to next week:', newStartDate);
                }}
              >
                <IoChevronForward size={24} />
              </button>

              {/* Month and Year Display */}
              <div className="ml-auto text-gray-400 text-lg font-bold">
                {getMonthAndYear(startDate)}
              </div>
            </div>

            {/* Event Details */}
            <div className="mt-6 space-y-4 bg-gray-900 p-6 rounded-xl">
              {schedules.filter((schedule) => schedule.date === selectedDate).length > 0 ? (
                schedules
                  .filter((schedule) => schedule.date === selectedDate)
                  .map((schedule, index) => (
                    <div
                      key={index}
                      className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden shadow-lg hover:shadow-xl transition-all duration-200"
                    >
                      {/* Header with status indicator */}
                      <div className="flex items-center justify-between p-4 border-b border-gray-700 bg-gray-800/50">
                        <div className="flex items-center space-x-3">
                          <div className="w-2 h-2 rounded-full bg-emerald-500"></div>
                          <h3 className="text-lg font-semibold text-gray-100">
                            {schedule.name || 'No event'}
                          </h3>
                        </div>
                        <span className="text-sm text-emerald-400 bg-emerald-400/10 px-3 py-1 rounded-full">
                          {schedule.type || 'N/A'}
                        </span>
                      </div>

                      {/* Content Grid */}
                      {/* New Two-Column Layout */}
                      <div className="p-4 grid grid-cols-4 gap-4">
                        {/* Left Column - Profile Image */}
                        <div className="flex items-center justify-center">
                          <div className="relative">
                            <img
                              src={schedule.picture || '/api/placeholder/120/120'}
                              alt="Profile"
                              className="w-24 h-24 rounded-xl object-cover ring-4 ring-emerald-500/20"
                            />
                            <div className="absolute -bottom-2 -right-2 bg-emerald-500 text-gray-900 text-xs font-semibold px-2 py-1 rounded-lg">
                              Host
                            </div>
                          </div>
                        </div>

                        {/* Right Column - Time and Notes */}
                        <div className="col-span-3 space-y-4">
                          {/* Time Row */}
                          <div className="bg-gray-700/30 p-3 rounded-lg">
                            <div className="flex items-center space-x-3">
                              <Clock className="w-5 h-5 text-emerald-400" />
                              <div className="flex flex-col">
                                <span className="text-sm font-medium text-gray-200">Schedule Time</span>
                                <span className="text-sm text-gray-300">{schedule.time}</span>
                              </div>
                            </div>
                          </div>

                          {/* Notes Row */}
                          {schedule.notes && schedule.notes.length > 0 && (
                            <div className="bg-gray-700/30 p-3 rounded-lg">
                              <div className="flex items-start space-x-3">
                                <FileText className="w-5 h-5 text-emerald-400 mt-1" />
                                <div className="flex-1">
                                  <span className="text-sm font-medium text-gray-200 block mb-2">Notes</span>
                                  <ul className="space-y-2">
                                    {schedule.notes.map((note, noteIndex) => (
                                      <li key={noteIndex} className="flex items-start space-x-2 text-sm text-gray-300">
                                        <span className="mt-1.5 w-1.5 h-1.5 rounded-full bg-emerald-400"></span>
                                        <span>{note}</span>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Footer */}
                      <div className="px-4 py-3 bg-gray-900/50 border-t border-gray-700 flex justify-between items-center">
                        <button className="text-sm text-gray-400 hover:text-gray-200 transition-colors duration-200">
                          Dismiss
                        </button>
                        <button
                          className="flex items-center space-x-2 bg-emerald-500/10 text-emerald-400 px-4 py-2 rounded-lg hover:bg-emerald-500/20 transition-all duration-200"
                          onClick={() => {
                            if (schedule.link.startsWith('http')) {
                              window.open(schedule.link, '_blank');
                            } else {
                              navigate(schedule.link);
                            }
                          }}
                        >
                          <span>View details</span>
                          <ChevronRight className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="bg-gray-800 rounded-lg p-8 text-center border border-gray-700">
                  <Calendar className="w-12 h-12 mx-auto text-emerald-400 mb-3" />
                  <p className="text-gray-400">
                    No events scheduled for this date.
                  </p>
                </div>
              )}
            </div>
          </div>


        </div>
      </div>
    </div>
  );



};


export default Profile;
