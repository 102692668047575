import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { detailed_list } from "./detailed_list_updated";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCalendarCheck, FaCheckCircle, FaChevronDown, FaCoins, FaMapMarked, FaPhoneAlt, FaStarHalfAlt, FaTags, FaTaxi, FaTrain, FaWalking } from "react-icons/fa";

const RoomDetails = () => {
  const { room_id } = useParams();
  const roomTypesRef = useRef(null);

  const roomDetails = detailed_list.find((room) => room.room_id === room_id);

  if (!roomDetails) {
    return <div className="text-gray-100">Room details not found.</div>;
  }

  const {
    room_name,
    city,
    location,
    cost,
    distance,
    walking_distance,
    train_distance,
    driving_distance,
    tags,
    offers_and_details,
    description,
    room_types,
    image_urls_list,
  } = roomDetails;

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const handleEnquire = () => {
    toast(
      <span>
        Please contact <a href="mailto:hello@worldlynk.co.uk" style={{ color: 'blue' }}>hello@worldlynk.co.uk</a> for more assistance.
      </span>
    );
  };

  const scrollToRoomTypes = () => {
    if (roomTypesRef.current) {
      roomTypesRef.current.scrollIntoView({ behavior: 'smooth' }); // Step 2: Scroll to the "Room Types" section
    }
  };
  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto py-8 px-4">
        <div className="grid grid-cols-12 gap-8">
          {/* Left Section: Image Slider and Content */}
          <div className="lg:col-span-8 col-span-12">
            <div className="slider_arrows">
              <Slider {...sliderSettings} className="relative group">
                {image_urls_list.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Room Image ${index + 1}`}
                    className="w-full h-96 object-cover rounded-lg"
                  />
                ))}
              </Slider>
            </div>

            {/* Room Name and Address */}
            <div className="flex justify-content-between items-center">
              <div className="mt-4">
                <h1 className="text-3xl font-bold">{room_name}</h1>
                <p className="text-lg text-gray-300">{location}</p>
                {/* Distance Section */}
                <div className="flex items-center">
                  <div>
                    <FaMapMarked className="w-75 h-10"></FaMapMarked>
                  </div>
                  <div>
                    <small className="font-bold">{distance}</small>
                    <div className="flex items-center text-gray-300">
                      <small><FaWalking className="inline"></FaWalking> {walking_distance}</small>
                      <small className="mx-2"><FaTrain className="inline"></FaTrain> {train_distance}</small>
                      <small><FaTaxi className="inline"></FaTaxi> {driving_distance}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="font-bold text-xl">{cost}</p>
              </div>
            </div>
            {/* Tags Section */}
            <div className="mt-4">
              <div className="flex flex-wrap gap-2 mt-2">
                {tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-gray-700 text-gray-300 py-1 px-3 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>

            {/* Offers Section */}
            <div className="mt-8">
              <h3 className="text-2xl font-bold text-gray-100 mb-6">Offers & Details</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3">
                {offers_and_details.map((offer, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gray-800 p-4 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex-shrink-0 p-2.5 bg-blue-500 text-white flex items-center justify-center rounded-lg">
                      <FaTags className="text-lg" />
                    </div>
                    <div className="ml-4">
                      <p className="text-gray-300 m-0 text-sm">{offer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* About the Property Section */}
            <div className="mt-8">
              <h2 className="text-2xl font-bold">About the Property</h2>
              <p className="text-gray-300 mt-2">{description}</p>
            </div>

            {/* Room Types Section */}
            {room_types && room_types.length > 0 ? (
              <div className="hidden lg:block mt-8" ref={roomTypesRef}>
                <h2 className="text-2xl font-bold text-gray-100 mb-6">Room Types</h2>
                <div className="space-y-6">
                  {room_types.map((room, index) => (
                    <div
                      key={index}
                      className="bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-all cursor-pointer overflow-hidden" onClick={handleEnquire}
                    >
                      <div className="flex flex-col md:flex-row">
                        {/* Image Section */}
                        <div className="w-full md:w-1/4 h-48 md:h-auto bg-gray-700 flex items-center justify-center">
                          <img
                            src={room.image_url || "https://ik.imgkit.net/3vlqs5axxjf/TW/ik-seo/dist/images/no-image/Hotel-dell%27Ancora.jpg?tr=w-360%2Ch-216%2Cfo-auto"}
                            alt={room.room_type_name}
                            className="w-full h-full object-cover"
                          />
                        </div>

                        {/* Details Section */}
                        <div className="flex-1 p-6">
                          <h4 className="text-lg font-semibold text-white mb-2">
                            {room.room_type_name}
                          </h4>
                          <p className="text-green-400 font-medium mb-2">
                            {room.availability_date}
                          </p>
                          <p className="text-gray-400 mb-2">
                            Starting Price:
                            <span className="ms-1 font-semibold text-gray-300">
                              {room.starting_price}
                            </span>
                          </p>

                          {/* Features as Tags */}
                          <div className="flex flex-wrap items-center gap-2">
                            {room.features.map((feature, idx) => (
                              <span
                                key={idx}
                                className="bg-gray-700 text-gray-300 py-1 px-3 rounded-full text-sm"
                              >
                                {feature}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>) : (
              <div className="mt-8">
                <h2 className="text-2xl font-bold text-gray-100 mb-6">Room Types</h2>
                <p className="text-gray-300">No room types available for this room.</p>
              </div>
            )}
          </div>


          {/* Right Section: Sticky UI */}
          <div className="lg:col-span-4 col-span-12">
            <div className="lg:sticky lg:top-8 space-y-4 bg-gray-800 p-4 rounded-lg shadow-md">
              <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded w-full" onClick={scrollToRoomTypes}>
                View Rooms
              </button>
              <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded w-full" onClick={() => handleEnquire()}>
                Enquire Now
              </button>
            </div>
            <div className="sticky top-48 space-y-4 bg-gray-800 p-4 rounded-lg shadow-md mt-3">
              <div className="">
                {/* Instant Booking */}
                <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-1" className="peer hidden" />
                  <label
                    htmlFor="item-1"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaCalendarCheck className="me-2"></FaCalendarCheck>Instant Booking</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>Instant booking allows you to quickly book the property by paying the amount.</p>
                  </div>
                </div>

                {/* Lowest Price Guaranteed */}
                <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-2" className="peer hidden" />
                  <label
                    htmlFor="item-2"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaCoins className="me-2"></FaCoins>Lowest Price Guaranteed</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>We guarantee to match the price of your accommodation if you find an identical offer on another.</p>
                  </div>
                </div>

                {/* Verified Properties */}
                <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-3" className="peer hidden" />
                  <label
                    htmlFor="item-3"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaCheckCircle className="me-2"></FaCheckCircle>Verified Properties</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>We guarantee that what you see on our website is exactly what you'll get.</p>
                  </div>
                </div>

                {/* 24x7 Personal Assistance */}
                <div className="border-b border-gray-700">
                  <input type="checkbox" id="item-4" className="peer hidden" />
                  <label
                    htmlFor="item-4"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaPhoneAlt className="me-2"></FaPhoneAlt>24x7 Personal Assistance</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>For any doubts or queries, a quick call is all it takes - we're here to assist you promptly.</p>
                  </div>
                </div>

                {/* 5.8K+ Reviews */}
                <div>
                  <input type="checkbox" id="item-5" className="peer hidden" />
                  <label
                    htmlFor="item-5"
                    className="flex items-center justify-between w-full py-3 cursor-pointer"
                  >
                    <span className="flex items-center"><FaStarHalfAlt className="me-2"></FaStarHalfAlt>5.8K+ Reviews</span>
                    <FaChevronDown className="transform peer-checked:rotate-180 transition-transform duration-300"></FaChevronDown>
                  </label>
                  <div className="text-xs text-gray-400 overflow-hidden max-h-0 peer-checked:max-h-screen transition-all duration-300">
                    <p>We've earned an excellent rating from over 5,800+ students for our outstanding services.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
