import React, { useState } from 'react';
import { 
  Briefcase, 
  FileEdit, 
  Building2, 
  MapPin, 
  Clock, 
  Link2, 
  Image, 
  Plus, 
  X, 
  Send
} from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { storage, firestore } from '../../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import {  ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { toast, Toaster } from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const PostJobs = () => {
  const [jobDetails, setJobDetails] = useState({
    job: '',
    company: '',
    location: '',
    job_type: '',
    Employment_type: '',
    no_of_applicants: '',
    description: '',
    job_url: '',
    image_url: ''
  });
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
console.log(userData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobDetails({ ...jobDetails, [name]: value });
  };

  const handleDescriptionChange = (value) => {
    setJobDetails({ ...jobDetails, description: value });
  };

  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      setTags([...tags, newTag.trim()]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // const storage = getStorage();
      const storageRef = ref(storage, `jobs/${userData.companyName}/Image/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setJobDetails({ ...jobDetails, image_url: url });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (userData.accountType === 'business' && userData.organizationType === 'Jobs' && userData.role === 'admin') {
      try {
        if (!userData.userId) {
          throw new Error('User ID is undefined');
        }
        const jobId = uuidv4();
        const jobData = {
          ...jobDetails,
          jobId,
          tags,
          createdBy: userData.userId,
          createdAt: new Date()
        };
        await addDoc(collection(firestore, 'jobs'), jobData);
        toast.success('Job posted successfully');
      } catch (error) {
        toast.error('Error posting job');
        console.error('Error posting job:', error);
      }
    } else {
      toast.error('You do not have permission to post jobs.');
      console.error('You do not have permission to post jobs.');
    }
    setLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <Toaster position="top-right" toastOptions={{ style: { background: '#333', color: '#fff' } }} />
      <h2 className="text-3xl font-bold mb-6 flex items-center">
        <Briefcase className="mr-3 text-blue-400" />
        Add Job Post
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Job Title and Company */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium">Job Title</label>
            <div className="flex items-center bg-gray-700 rounded">
              <FileEdit className="ml-3 text-gray-400" />
              <input
                type="text"
                name="job"
                placeholder="Software Engineer"
                value={jobDetails.job}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">Company</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Building2 className="ml-3 text-gray-400" />
              <input
                type="text"
                name="company"
                placeholder="Tech Innovations Inc."
                value={jobDetails.company}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
          </div>
        </div>

        {/* Location, Job Type, and Employment Type */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium">Location</label>
            <div className="flex items-center bg-gray-700 rounded">
              <MapPin className="ml-3 text-gray-400" />
              <input
                type="text"
                name="location"
                placeholder="San Francisco, CA"
                value={jobDetails.location}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">Job Type</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Briefcase className="ml-3 text-gray-400" />
              <select
                name="job_type"
                value={jobDetails.job_type}
                onChange={handleChange}
                className="w-full p-3 bg-gray-700 text-white rounded"
              >
                <option value="">Select Job Type</option>
                <option value="full-time">Full-time</option>
                <option value="part-time">Part-time</option>
                <option value="contract">Contract</option>
                <option value="remote">Remote</option>
              </select>
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">Employment Type</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Clock className="ml-3 text-gray-400" />
              <select
                name="Employment_type"
                value={jobDetails.Employment_type}
                onChange={handleChange}
                className="w-full p-3 bg-gray-700 text-white rounded"
              >
                <option value="">Select Employment Type</option>
                <option value="permanent">Permanent</option>
                <option value="temporary">Temporary</option>
                <option value="freelance">Freelance</option>
              </select>
            </div>
          </div>
        </div>

        {/* Job Description */}
        <div>
          <label className="block mb-2 text-sm font-medium">Job Description</label>
          <ReactQuill
            theme="snow"
            value={jobDetails.description}
            onChange={handleDescriptionChange}
            className="bg-gray-800 text-white border-gray-600"
          />
        </div>

        {/* Job URL and Image Upload */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium">Job URL</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Link2 className="ml-3 text-gray-400" />
              <input
                type="url"
                name="job_url"
                placeholder="https://careers.company.com/job"
                value={jobDetails.job_url}
                onChange={handleChange}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium">Upload Image</label>
            <div className="flex items-center bg-gray-700 rounded">
              <Image className="ml-3 text-gray-400" />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="w-full p-3 bg-transparent text-white rounded"
              />
            </div>
          </div>
        </div>

        {/* Tags */}
        <div>
          <label className="block mb-2 text-sm font-medium">Add Tags</label>
          <div className="flex">
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Add job tags (e.g., React, Frontend)"
              className="flex-grow p-3 bg-gray-700 text-white rounded-l"
            />
            <button
              type="button"
              onClick={handleAddTag}
              className="bg-blue-600 px-4 rounded-r hover:bg-blue-500"
            >
              <Plus />
            </button>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {tags.map((tag, index) => (
              <div
                key={`${tag}-${index}`}
                className="flex items-center bg-blue-600 text-white text-sm px-2 py-1 rounded"
              >
                {tag}
                <button onClick={() => handleRemoveTag(tag)} className="ml-2">
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white py-3 rounded-lg flex items-center justify-center"
          disabled={loading}
        >
          {loading ? <FaSpinner className="animate-spin mr-2" /> : <Send className="mr-2" />} Submit Job Post
        </button>
      </form>
    </div>
  );
};

export default PostJobs;