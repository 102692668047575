import React, { useState, useEffect } from 'react';
import { FaEdit, FaSpinner } from 'react-icons/fa';
import { firestore, storage } from '../../../firebase'; // Adjust the import path as needed
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { X } from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';

const CompanyPage = () => {
  const { userData } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    location: "",
    industry: "",
    description: "",
    website: ""
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!userData?.userId) return;

      setIsLoading(true);
      try {
        const userDocRef = doc(firestore, 'users', userData.userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const data = userDocSnap.data();
          setCompanyData(prev => ({
            ...prev,
            ...data,
            logo: data.logo || '',
            banner: data.banner || '',
          }));
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyData();
  }, [userData?.userId]);

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  // Save Company Data
  const handleSave = async () => {
    if (!userData?.userId) return;

    setIsSaving(true);
    try {
      const userDocRef = doc(firestore, 'users', userData.userId);
      await updateDoc(userDocRef, companyData);
      setIsModalOpen(false);
      toast.success('Company data saved successfully!', { theme: "dark" });
    } catch (error) {
      console.error('Error saving company data:', error);
      setError(error);
      toast.error('Error saving company data.',{ theme: "dark" });
    } finally {
      setIsSaving(false);
    }
  };

  // Handle Image Upload
  const handleImageUpload = async (e, field) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `users/${userData.userId}/${field}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      setCompanyData(prev => ({ ...prev, [field]: url }));

      // Update Firestore with the uploaded image URL
      const userDocRef = doc(firestore, 'users', userData.userId);
      await updateDoc(userDocRef, { [field]: url });
    } catch (error) {
      console.error(`Error uploading ${field}:`, error);
      setError(error);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen w-full max-w-7xl flex items-center justify-center text-white">
        <FaSpinner className="animate-spin mr-2" />
        Loading company details...
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen w-full max-w-7xl flex items-center justify-center text-red-500">
        Error loading company details. Please try again later.
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full max-w-9xl text-white">
      <Toaster position="top-right" toastOptions={{ style: { background: '#333', color: '#fff' } }} />
      {/* Banner with Edit Icon */}
      <div className="relative">
        <img 
          src={companyData.banner || 'https://via.placeholder.com/900x200'} 
          alt="Company Banner" 
          className="w-full h-48 object-cover mb-4" 
        />
        <button 
          onClick={() => setIsModalOpen(true)}
          className="absolute top-4 right-4 bg-gray-800 bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition"
        >
          <FaEdit className="text-white" size={24} />
        </button>
      </div>

      {/* Company Details */}
      <div className="max-w-4xl mx-auto space-y-4">
        <div className="flex items-center space-x-4">
          <img 
            src={companyData.logo || 'https://via.placeholder.com/150'} 
            alt="Company Logo" 
            className="w-16 h-16 object-cover rounded-full" 
          />
          <h2 className="text-2xl font-bold">{companyData.companyName}</h2>
        </div>
        <p><strong>Location:</strong> {companyData.location}</p>
        <p><strong>Industry:</strong> {companyData.industry}</p>
        <p><strong>Description:</strong> {companyData.description}</p>
        <a
          href={companyData.website}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded inline-block"
        >
          Visit Website
        </a>
      </div>

      {/* Custom Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4 overflow-y-auto">
          <div className="bg-gray-800 rounded-lg p-6 w-full max-w-2xl my-8">
            <div className="flex justify-between items-center mb-4 sticky top-0 bg-gray-800 py-2">
              <h3 className="text-xl font-bold text-white">Edit Company Details</h3>
              <button onClick={() => setIsModalOpen(false)} className="p-2 hover:bg-gray-700 rounded-full">
                <X className="w-5 h-5 text-white" />
              </button>
            </div>

            <div className="space-y-4 max-h-[50vh] overflow-y-auto px-2">
              <div>
                <label className="block mb-1 text-sm text-white">Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={companyData.companyName}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm text-white">Location</label>
                <input
                  type="text"
                  name="location"
                  value={companyData.location}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm text-white">Industry</label>
                <input
                  type="text"
                  name="industry"
                  value={companyData.industry}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm text-white">Description</label>
                <textarea
                  name="description"
                  value={companyData.description}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 text-white rounded h-32"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm text-white">Website</label>
                <input
                  type="text"
                  name="website"
                  value={companyData.website}
                  onChange={handleInputChange}
                  className="w-full p-2 bg-gray-700 text-white rounded"
                />
              </div>
              <div>
                <label className="block mb-1 text-sm text-white">Logo</label>
                <input 
                  type="file" 
                  onChange={(e) => handleImageUpload(e, 'logo')} 
                  accept="image/*"
                  className="w-full p-2 bg-gray-700 text-white rounded" 
                />
              </div>
              <div>
                <label className="block mb-1 text-sm text-white">Banner</label>
                <input 
                  type="file" 
                  onChange={(e) => handleImageUpload(e, 'banner')} 
                  accept="image/*"
                  className="w-full p-2 bg-gray-700 text-white rounded" 
                />
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-6 pt-4 border-t border-gray-700">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-600 rounded hover:bg-gray-500 text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-blue-600 rounded hover:bg-blue-500 text-white flex items-center"
                disabled={isSaving}
              >
                {isSaving && <FaSpinner className="animate-spin mr-2" />}
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyPage;