export const room_list=[
    {
        "room_name": "iQ East Court, London",
        "location": "Mile End Rd, London, E1 4GG",
        "details": [
            "31 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Lounge",
            "Free Gym",
            "From£414/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/241327/b92d01bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/162810/1756ee1b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/241327/b92d01bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/241327/b92d01bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-east-court-mile-end-2010057445017?ref=search&regionId=14",
        "city": "London",
        "room_id": "9141c419c9654845bc608bbe9e5dc7a8"
    },
    {
        "room_name": "Chapter Old Street, London",
        "location": "Paul St, London, EC2A 4JH",
        "details": [
            "34 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Games Area",
            "Bills Included",
            "Tv Snug",
            "Terrace",
            "From£279/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/212082/85f8e8d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211754/8ad56d72.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/212082/85f8e8d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/212082/85f8e8d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-old-street-london-2203164613042?ref=search&regionId=14",
        "city": "London",
        "room_id": "7551f7a01c3c4bd3a7ff84e111a0ae36"
    },
    {
        "room_name": "Chapter Portobello, London",
        "location": "Alderson St, London, W10 5JY",
        "details": [
            "30 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Game Room",
            "Cinema",
            "Courtyard",
            "From£284/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/213043/6ae6a628.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136701/4f01bad0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/213043/6ae6a628.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/213043/6ae6a628.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-portobello-london-1707310732338?ref=search&regionId=14",
        "city": "London",
        "room_id": "b6357b53b97b4d949cee4e3cd78f881a"
    },
    {
        "room_name": "Chapter Ealing, London",
        "location": "Victoria Rd, London, W3 6UN",
        "details": [
            "42 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Cinema",
            "Game Room",
            "Games Area",
            "On-Site Gym",
            "From£314/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/937387/7edf7d0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144061/53331144.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/937387/7edf7d0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/937387/7edf7d0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-ealing-london-1902122096174?ref=search&regionId=14",
        "city": "London",
        "room_id": "3d46dceda3174f68af8e1f8b33a8f8ff"
    },
    {
        "room_name": "iQ Will Wyatt Court, London",
        "location": "Pitfield St, London, N1 6JP",
        "details": [
            "8 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Outdoor Courtyard",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Courtyard",
            "From£365/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147107/1d989bb4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147106/0fbd5d91.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147107/1d989bb4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147107/1d989bb4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-will-wyatt-court-london-1911148077432?ref=search&regionId=14",
        "city": "London",
        "room_id": "181929b1d0a342408d1eb60e0125553a"
    },
    {
        "room_name": "Canvas Wembley, London",
        "location": "N End Rd, Wembley, HA9 0UU",
        "details": [
            "24 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Study Spaces",
            "From£354/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/936260/adf94ee1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148467/4550269a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/936260/adf94ee1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/936260/adf94ee1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-wembley-2001238048439?ref=search&regionId=14",
        "city": "London",
        "room_id": "25e371e65c864873ae85206dd9f4ea13"
    },
    {
        "room_name": "iQ Aldgate, London",
        "location": "Commercial Rd, London, E1 1NZ",
        "details": [
            "18 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "24/7 Staff Presence",
            "Cinema",
            "Close To Universities",
            "From£353/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/241136/2a367a10.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136693/a3b880b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/241136/2a367a10.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/241136/2a367a10.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-aldgate-london-1707315979267?ref=search&regionId=14",
        "city": "London",
        "room_id": "808ff6e61879418c9b93ed3c2d08021e"
    },
    {
        "room_name": "iQ City, London",
        "location": "Bastwick St, London, EC1V",
        "details": [
            "24 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "24/7 Security",
            "City Centre Location",
            "Bills Included",
            "From£413/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/176184/9bae7f2e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143256/f69e846a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/176184/9bae7f2e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/176184/9bae7f2e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-city-london-1811192172260?ref=search&regionId=14",
        "city": "London",
        "room_id": "924b1adfb872479eb225c27c8c643fdd"
    },
    {
        "room_name": "Canvas Walthamstow, London",
        "location": "Forest Rd, London, E17 6JJ",
        "details": [
            "26 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Cinema",
            "Furnished",
            "Study Spaces",
            "From£339/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148458/990765bf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148458/54a9535e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148458/990765bf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148458/990765bf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-walthamstow-london-2001233197617?ref=search&regionId=14",
        "city": "London",
        "room_id": "8ecee1c61c004d31a8910d98f9beda36"
    },
    {
        "room_name": "iQ Shoreditch, London",
        "location": "East Rd, London, N1 6AT",
        "details": [
            "29 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cinema",
            "From£422/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143202/890586b8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143183/b5950000.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143202/890586b8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143202/890586b8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-shoreditch-london-1811179150260?ref=search&regionId=14",
        "city": "London",
        "room_id": "acb56b79990046d79adb5f09c9293476"
    },
    {
        "room_name": "Mannequin House, London",
        "location": "Blackhorse Ln, London, E17 6SX",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Game Room",
            "Laundry Facility",
            "Bills Included",
            "BBQ",
            "From£308/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/134943/680d945e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134941/2fa4e3bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134943/680d945e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/134943/680d945e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mannequin-house-london-1706057856013?ref=search&regionId=14",
        "city": "London",
        "room_id": "d612ffdc9a314aa7a75d1e5b169ac8d8"
    },
    {
        "room_name": "iQ Hoxton, London",
        "location": "Kingsland Rd, London, E2 8AG",
        "details": [
            "13 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Study Area",
            "Lounge",
            "Courtyard",
            "From£423/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143204/ad521a25.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143203/db93727f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143204/ad521a25.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143204/ad521a25.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-hoxton-london-1811170520327?ref=search&regionId=14",
        "city": "London",
        "room_id": "4ee8c33849464ded874de1a9de4539be"
    },
    {
        "room_name": "iQ Tufnell House, London",
        "location": "Huddleston Rd, London, N7 0EG",
        "details": [
            "15 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Free Gym",
            "From£353/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143156/7db1a0fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143150/4f30af3b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143156/7db1a0fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143156/7db1a0fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-tufnell-house-london-1811160503086?ref=search&regionId=14",
        "city": "London",
        "room_id": "aea230f9c1ef40e2949e2dc0daa88e1e"
    },
    {
        "room_name": "Collegiate Dashwood Studios, London",
        "location": "Walworth Rd, London, SE17 1JL",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Cinema",
            "TV Lounge",
            "Games Room",
            "From£446/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140929/dfbe99d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140908/591ba840.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140929/dfbe99d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140929/dfbe99d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/dashwood-studios-1805049298482?ref=search&regionId=14",
        "city": "London",
        "room_id": "70af3f59da7d477ba98f829d15e2a786"
    },
    {
        "room_name": "Chapter Kings Cross, London",
        "location": "Pentonville Rd, London, N1 9JP",
        "details": [
            "30 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Laundry Facility",
            "Cinema",
            "Courtyard",
            "Games Area",
            "From£295/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/963645/175b66df.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136672/8b96c2d9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/963645/175b66df.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/963645/175b66df.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-kings-cross-london-1707293068339?ref=search&regionId=14",
        "city": "London",
        "room_id": "56b30793795b496f981d0322b9cdf373"
    },
    {
        "room_name": "London Nest Don Gratton House, London",
        "location": "Old Montague St, London, E1 5NN",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Common Room",
            "Laundry Facility",
            "Reception",
            "Common Room",
            "From£359/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/216515/958c14c3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/216515/c4cf600c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/216515/958c14c3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/216515/958c14c3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/don-gratton-house-london-2204182190770?ref=search&regionId=14",
        "city": "London",
        "room_id": "14117e2e209e4b65a6bf8ebf6fbd1a64"
    },
    {
        "room_name": "iQ Highbury, London",
        "location": "Isledon Rd, London, N7 7JR",
        "details": [
            "14 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Games Room",
            "Study Area",
            "Laundry Facility",
            "TV",
            "Lounge",
            "From£369/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/199722/d03b20fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143159/d05d17e7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199722/d03b20fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/199722/d03b20fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-highbury-london-1811163333778?ref=search&regionId=14",
        "city": "London",
        "room_id": "cf69a73c1670405486f6302c0f784d04"
    },
    {
        "room_name": "AXO New Cross, London",
        "location": "New Cross Rd, London, SE14 6AG",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Flexible Tenancy",
            "Bills Included",
            "Bike Storage",
            "Outdoor Area",
            "Communal Kitchen",
            "From£369/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137465/e448f5de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137465/fa372fde.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137465/e448f5de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137465/e448f5de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/axo-new-cross-london-1709159531035?ref=search&regionId=14",
        "city": "London",
        "room_id": "8d0daaa10b414fedbcaee5d17f6854ae"
    },
    {
        "room_name": "AXO Islington, London",
        "location": "Holloway Rd, London, N7 6JP",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "Cinema",
            "Living Areas",
            "From£299/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137459/423fc1a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137456/4a2d739d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137459/423fc1a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137459/423fc1a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/axo-islington-london-1709152706379?ref=search&regionId=14",
        "city": "London",
        "room_id": "6c4a1ed17e5a4d41b019dabc04c025d5"
    },
    {
        "room_name": "Urbanest St Pancras, London",
        "location": "Camley St, London, N1C 4BN",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Gaming Area",
            "CCTV",
            "Lounge",
            "Table Tennis",
            "Meeting Point",
            "From£329/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/944865/e5d871fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132970/3eafcb5e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/944865/e5d871fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/944865/e5d871fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/urbanest-st-pancras-london-1608300359877?ref=search&regionId=14",
        "city": "London",
        "room_id": "05611a97d93e45a28c327579dce2a418"
    },
    {
        "room_name": "Chapter South Bank, London",
        "location": "Great Suffolk St, London, SE1 0NS",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Games Area",
            "Sky Lounge",
            "Resident Events",
            "Tv Snug",
            "Sky Lounge",
            "Games Area",
            "From£435/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/214307/f9d61204.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136665/af9a588d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/214307/f9d61204.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/214307/f9d61204.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-south-bank-london-1707299131831?ref=search&regionId=14",
        "city": "London",
        "room_id": "6e2fe6022ae44a5ea3eb14eabf7e73fb"
    },
    {
        "room_name": "Chapter Aldgate, London",
        "location": "Commercial Rd, London, E1 1DF",
        "details": [
            "45 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cinema",
            "Courtyard",
            "From£284/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/211765/8f67745b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211753/fd5d45a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211765/8f67745b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/211765/8f67745b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-aldgate-london-2203165496617?ref=search&regionId=14",
        "city": "London",
        "room_id": "b43ef430fe6c4319b33cd364971c78dc"
    },
    {
        "room_name": "Londonist Drapery Place, London",
        "location": "Leman St, London, E1 8EU",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Pool",
            "Wifi",
            "From£440/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1093612/803983f5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1093525/06135ab1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1093612/803983f5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1093612/803983f5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-drapery-place-london-2405299082320?ref=search&regionId=14",
        "city": "London",
        "room_id": "e67c599334d84f289f9a211df13f71ad"
    },
    {
        "room_name": "Londonist South Bank Residence, London",
        "location": "Great Suffolk St, London, SE1 0NS",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Wifi",
            "Laundry",
            "From£470/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/249682/fca650a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/249682/35cebc69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/249682/fca650a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/249682/fca650a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-south-bank-residence-london-2301097674521?ref=search&regionId=14",
        "city": "London",
        "room_id": "614f7a8e6ecb4fbe9753e419ad3ac9dd"
    },
    {
        "room_name": "Britannia Students Chapter Lewisham, London",
        "location": "Thurston Rd, London, SE13 7SD",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bike Storage",
            "Outdoor Area",
            "On-Site Gym",
            "Roof Terrace",
            "Study Spaces",
            "From£380/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1200151/867c8602.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200083/1810b0fb.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200151/867c8602.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1200151/867c8602.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/britannia-students-chapter-lewisham-london-2408016222701?ref=search&regionId=14",
        "city": "London",
        "room_id": "2d94c204dd824f99a0efcf8c4300f6e0"
    },
    {
        "room_name": "Host View Studios, London",
        "location": "Spa Rd, London, SE16 3SA",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Study Area",
            "Game Area",
            "Lifts To All Floors",
            "From£350/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/201361/4bb68faf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201361/0838019e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201361/4bb68faf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/201361/4bb68faf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/host-view-studios-2111303227354?ref=search&regionId=14",
        "city": "London",
        "room_id": "2c75eb945e264345afa34406f08ed9f2"
    },
    {
        "room_name": "Element, London",
        "location": "Alscot Rd, London, SE1 3AY",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Cinema Room",
            "Bills Included",
            "Gaming Area",
            "From£340/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/933424/b73c8c8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221257/5b856c08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/933424/b73c8c8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/933424/b73c8c8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/element-bermondsey-london-2206247786822?ref=search&regionId=14",
        "city": "London",
        "room_id": "eeb59fe1d51e40ac8d34a157f222b7e4"
    },
    {
        "room_name": "Chapter Islington, London",
        "location": "Market Rd, London, N7 9AW",
        "details": [
            "28 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Games Area",
            "Art Studio",
            "From£390/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/136692/8d66666d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136682/64c7b3ce.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136692/8d66666d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/136692/8d66666d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-islington-london-1707290711220?ref=search&regionId=14",
        "city": "London",
        "room_id": "d095832a2b7b4178876709833a3468d1"
    },
    {
        "room_name": "iQ Vega, London",
        "location": "Miles St, London, SW8 1RZ",
        "details": [
            "29 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cinema",
            "Bills Included",
            "Laundry Facility",
            "Free Gym",
            "From£401/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/938238/f11ed759.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175302/2827bd0b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/938238/f11ed759.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/938238/f11ed759.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-vega-london-2101138507106?ref=search&regionId=14",
        "city": "London",
        "room_id": "706b0b96d2ef4a4c8cd3324cc469cce0"
    },
    {
        "room_name": "Flora Building – Hoxton, London",
        "location": "Long St, London, E2 8HJ",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Games Room",
            "Free On-Site Gym",
            "Bills Included",
            "Games Room",
            "From£304/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149589/900463fa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149580/babc16c1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149589/900463fa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149589/900463fa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/flora-building-london-2003182949070?ref=search&regionId=14",
        "city": "London",
        "room_id": "87650b521e204542b8e7dca1e674a455"
    },
    {
        "room_name": "GoBritanya Kings Cross Residence, London",
        "location": "Pentonville Rd, London, N1",
        "details": [
            "12 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "On Site GYM",
            "Bills Included",
            "Laundry Facility",
            "Cinema",
            "Courtyard",
            "From£580/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/159546/4d4357d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/159546/2bb848c3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/159546/4d4357d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/159546/4d4357d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kings-cross-residence-go-britanya-london-2009022949327?ref=search&regionId=14",
        "city": "London",
        "room_id": "ca8a34a3d2754c7cbf230caf6f0c670c"
    },
    {
        "room_name": "GoBritanya Paddington Citi View, London",
        "location": "Talbot Square, London, W2 1TR",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "On Site Gym",
            "From£490/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1222499/457b061f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1222314/05f2bdef.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1222499/457b061f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1222499/457b061f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/gobritanya-paddington-citi-view-london-2408135940290?ref=search&regionId=14",
        "city": "London",
        "room_id": "3e63d05f7ea843b08005e09075717dc6"
    },
    {
        "room_name": "iQ The Arcade, London",
        "location": "Holloway Rd, London, N7 0RY",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "24/7 Security",
            "TV",
            "Seating Area",
            "From£259/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147578/1792c2c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143139/6571b6f8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147578/1792c2c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147578/1792c2c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-the-arcade-london-1811161245652?ref=search&regionId=14",
        "city": "London",
        "room_id": "b87c4a13bbb04b43a6d0082304558e44"
    },
    {
        "room_name": "GoBritanya Old Street Residence, London",
        "location": "Paul St, London, EC2A 4JH",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Bars",
            "Clubs",
            "From£535/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/218967/83db2a85.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218967/3fc8c9f7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218967/83db2a85.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/218967/83db2a85.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/old-street-residence-london-2205287379970?ref=search&regionId=14",
        "city": "London",
        "room_id": "0da4d558cc16468fbd90aece548266c1"
    },
    {
        "room_name": "Londonist Paddington Citi View, London",
        "location": "Talbot Square, London, W2 1TR",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "CCTV",
            "Gym",
            "Laptop Bar",
            "From£560/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1208732/9faefa1f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1208223/75f60d90.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1208732/9faefa1f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1208732/9faefa1f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-paddington-citi-view-london-2408066065874?ref=search&regionId=14",
        "city": "London",
        "room_id": "0046d1038fe846f18ee97cf07a9a738a"
    },
    {
        "room_name": "iQ Magenta House, London",
        "location": "Tyrian Place, London, E1 1DQ",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Lounge",
            "From£394/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133244/2ba507bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133241/fb73fbc1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133244/2ba507bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133244/2ba507bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-magenta-house-london-1608304026309?ref=search&regionId=14",
        "city": "London",
        "room_id": "ae27caa34cc6445383f86004b8a5126e"
    },
    {
        "room_name": "GoBritanya South Bank Residence, London",
        "location": "Great Suffolk St, London, SE1 0NS",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "On Site Laundry",
            "On Site Gym",
            "Bike Storage",
            "On Site Gym",
            "From£570/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1106237/45c731b1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102298/54f04201.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1106237/45c731b1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1106237/45c731b1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/go-britanya-southbank-residence-london-2406074080062?ref=search&regionId=14",
        "city": "London",
        "room_id": "4ba8355b422044ed8c458551b9042afb"
    },
    {
        "room_name": "London Nest Stapleton House, London",
        "location": "Holloway Rd, London, N7 8FB",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry",
            "Communal Area",
            "CCTV",
            "TV",
            "Sofas",
            "Laundry",
            "From£360/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137839/dc19a700.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137839/e166624b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137839/dc19a700.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137839/dc19a700.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/stapleton-house-london-1710245613143?ref=search&regionId=14",
        "city": "London",
        "room_id": "970c6e2abb474cc8ab96209dce6714b0"
    },
    {
        "room_name": "GoBritanya IQ Aldgate Residence, London",
        "location": "Commercial Rd, London, E1 1LA",
        "details": [
            "2 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Fully Furnished",
            "On Site Team",
            "Secure Entry",
            "Cinema",
            "City Center",
            "From£460/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1103219/e1aa6572.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102297/87684c7e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1103219/e1aa6572.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1103219/e1aa6572.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/go-britanya-aldgate-iq-london-2406079922107?ref=search&regionId=14",
        "city": "London",
        "room_id": "e891f68028694043ab853f899fcb95a0"
    },
    {
        "room_name": "iQ Bloomsbury, London",
        "location": "Euston Rd., London, NW1 2FA",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Wi-Fi",
            "From£804/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143244/5d527cfa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143243/1e726ba3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143244/5d527cfa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143244/5d527cfa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-bloomsbury-london-1811197293573?ref=search&regionId=14",
        "city": "London",
        "room_id": "498e239c23e9455482897dc24903e104"
    },
    {
        "room_name": "GoBritanya Islington Residence, London",
        "location": "Market Rd, London, N7 9AW",
        "details": [
            "14 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry",
            "Onsite GYM",
            "Bills Included",
            "On Site Gym",
            "From£490/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/159545/9e051347.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/159545/8bb986b8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/159545/9e051347.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/159545/9e051347.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/islington-residence-go-britanya-2009027357830?ref=search&regionId=14",
        "city": "London",
        "room_id": "43730760a0324106be0d876a26e70424"
    },
    {
        "room_name": "Chapter Highbury, London",
        "location": "Holloway Rd, London, N7 9DS",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Cinema",
            "Courtyard",
            "TV Snug",
            "Courtyard",
            "Games Area",
            "From£400/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/212674/35d00b40.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136708/24a0200b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/212674/35d00b40.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/212674/35d00b40.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-highbury-london-1707319989447?ref=search&regionId=14",
        "city": "London",
        "room_id": "95dd56def31f4542ab7caa2af4377bb3"
    },
    {
        "room_name": "GoBritanya Aldgate Residence, London",
        "location": "Commercial Rd, London, E1 1NU",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry",
            "On Site GYM",
            "Bills Included",
            "Cinema",
            "Lounges",
            "From£580/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/158605/0e45382c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/158605/c8540d50.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/158605/0e45382c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/158605/0e45382c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/aldgate-residence-go-britanya-2008218676075?ref=search&regionId=14",
        "city": "London",
        "room_id": "8c3478d41ccf46238d5e6d5861563d12"
    },
    {
        "room_name": "Chapter Highbury II, London",
        "location": "Holloway Rd, London, N7 8HS",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Resident Events",
            "Social Spaces",
            "Games Area",
            "Cinema",
            "Games Area",
            "On-Site Gym",
            "From£439/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/213541/34ec77c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/213494/a3e0a39c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/213541/34ec77c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/213541/34ec77c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-highbury-ii-london-2203220094911?ref=search&regionId=14",
        "city": "London",
        "room_id": "673991bc946348829a6872c7a4322a5d"
    },
    {
        "room_name": "GoBritanya Highbury Residence, London",
        "location": "Holloway Rd, London, N7 9DS",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Cinema",
            "Laundry Facility",
            "Reception",
            "On Site Gym",
            "From£440/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/243928/81abd26e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/243924/9766de02.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/243928/81abd26e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/243928/81abd26e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/highbury-residence-go-britanya-london-2211299028931?ref=search&regionId=14",
        "city": "London",
        "room_id": "a707d310fa2644d785dd53f91aabf16e"
    },
    {
        "room_name": "Londonist Highbury Residence, London",
        "location": "Holloway Rd, London, N7 9DS",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry",
            "Bills Included",
            "WiFi",
            "Laundry",
            "Entertainment",
            "From£440/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149372/99325c9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149372/33b3f29a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149372/99325c9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149372/99325c9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-highbury-residence-london-2003137827393?ref=search&regionId=14",
        "city": "London",
        "room_id": "7f8fd7583f264995b43e77b98ab7c2f5"
    },
    {
        "room_name": "iQ Haywood House, London",
        "location": "Blackburn Rd, London, NW6 1RZ",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Free Gym",
            "Large TV",
            "From£376/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/187555/bf46843e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134919/20137a7b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/187555/bf46843e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/187555/bf46843e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-haywood-house-london-1706037699469?ref=search&regionId=14",
        "city": "London",
        "room_id": "2f160ea876594fc68d37e754e333e1e3"
    },
    {
        "room_name": "GoBritanya Highbury Residence II, London",
        "location": "Holloway Rd, London, N7 8HS",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "On Site Gym",
            "On Site Laundry",
            "Games Area",
            "Cinema",
            "On Site Gym",
            "From£510/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/282304/7ed96575.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/282304/2926703e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/282304/7ed96575.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/282304/7ed96575.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/go-britanya-highbury-residence-ii-london-2307073638047?ref=search&regionId=14",
        "city": "London",
        "room_id": "fb993847d4424dd09ee07b30b460f977"
    },
    {
        "room_name": "Britannia Students Charles Morton Court, London",
        "location": "Green Lanes, London, N16 9BS",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "Bike Storage",
            "TV",
            "Lounge",
            "Reception",
            "From£440/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1200203/0a9d4262.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200170/7f2b88dd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200203/0a9d4262.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1200203/0a9d4262.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/britannia-students-charles-morton-court-london-2408015450957?ref=search&regionId=14",
        "city": "London",
        "room_id": "15ffeffb341d4c86b3580c91c08ab683"
    },
   
    {
        "room_name": "iQ Hammersmith, London",
        "location": "Shepherds Bush Rd, London, W6 7NL",
        "details": [
            "20 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Game Room",
            "TV",
            "Lounge",
            "From£389/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143173/ff106bac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143170/a5d51baf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143173/ff106bac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143173/ff106bac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-hammersmith-london-1811160979976?ref=search&regionId=14",
        "city": "London",
        "room_id": "00293841c06a43298a88fdd9a107e58c"
    },
    {
        "room_name": "AXO Oxford Circus, London",
        "location": "Devonshire St, London, W1W 5DB",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "On Site Maintenance",
            "Laundry Facility",
            "Bills Included",
            "From£555/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137449/41e733d2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137449/334af21c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137449/41e733d2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137449/41e733d2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/axo-oxford-circus-london-1709156600823?ref=search&regionId=14",
        "city": "London",
        "room_id": "4b79fd72e87d4326970f73020eda4a93"
    },
    {
        "room_name": "Londonist Portobello Residence, London",
        "location": "Alderson St, London, W10 5JY",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry",
            "Bills Included",
            "Wifi",
            "Laundry",
            "Entertainment",
            "From£505/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149407/27d57946.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149397/b7e6166b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149407/27d57946.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149407/27d57946.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-portobello-residence-london-2003135314045?ref=search&regionId=14",
        "city": "London",
        "room_id": "663a0ae40125450dac3ae4ad34829305"
    },
    {
        "room_name": "Chapter Lewisham, London",
        "location": "Thurston Rd, London",
        "details": [
            "20 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Laundry Facility",
            "Bike Storage",
            "TV Snug",
            "Terrace",
            "From£289/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/136660/596ca3f8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136660/c5bdf709.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136660/596ca3f8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/136660/596ca3f8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-lewisham-london-1707296011998?ref=search&regionId=14",
        "city": "London",
        "room_id": "3f18ea8399d64cf9bf29b56d00901f34"
    },
    {
        "room_name": "Wick Park, London",
        "location": "Stour Rd, London, E3 2NT",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Library",
            "TV Lounge",
            "Amphitheatre",
            "Gym",
            "Lounge",
            "From£390/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/258010/c7604e23.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/257985/55edd7a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/258010/c7604e23.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/258010/c7604e23.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/wick-park-london-2303011285133?ref=search&regionId=14",
        "city": "London",
        "room_id": "38ceaeb762714f7ca77ba4c3912b5aab"
    },
    {
        "room_name": "GoBritanya Hammersmith, London",
        "location": "Shepherds Bush Rd, London, W6 7NL",
        "details": [
            "1 Room Option",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Secure Entry",
            "CCTV",
            "On Site Gym",
            "Cinema",
            "Courtyard",
            "From£505/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1103211/5bc272d9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102296/ffb0611d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1103211/5bc272d9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1103211/5bc272d9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/go-britanyan-hammersmith-london-2406074551482?ref=search&regionId=14",
        "city": "London",
        "room_id": "21bd99d00e4a4265b281a702f5c17dde"
    },
    {
        "room_name": "Londonist New Cross, London",
        "location": "New Cross Rd, London, SE14 6AG",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "Wifi",
            "Laundry",
            "From£450/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/270717/23db501c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/270713/659969b1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/270717/23db501c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/270717/23db501c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-axo-new-cross-london-2305277448450?ref=search&regionId=14",
        "city": "London",
        "room_id": "dd7d094951a74c2fa7126f8d9c37935e"
    },
    {
        "room_name": "iQ Bankside, London",
        "location": "Ewer St, London, SE1 0NR",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "24/7 Staff Presence",
            "24/7 Security",
            "Bills Included",
            "Lounge",
            "Courtyard",
            "From£528/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/196555/9a1bcda1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143219/7b3a58ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/196555/9a1bcda1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/196555/9a1bcda1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-bankside-london-1811176923641?ref=search&regionId=14",
        "city": "London",
        "room_id": "60ebaf59db3c4accacbdbd854283c765"
    },
    {
        "room_name": "Vita Student Lewisham Exchange, London",
        "location": "Loampit Vale, London, SE13 7NX",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry",
            "Study Area",
            "Gym",
            "Bike Store",
            "Pool Table",
            "Free Events",
            "From£337/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/166414/b0c81bc2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/166403/6dc939c3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/166414/b0c81bc2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/166414/b0c81bc2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-lewisham-exchange-2011093481483?ref=search&regionId=14",
        "city": "London",
        "room_id": "0ae2b91200fa4db480f20d310cecd643"
    },
    {
        "room_name": "GoBritanya Lewisham Residence, London",
        "location": "Thurston Rd, London, SE13 7SD",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Games Room",
            "From£365/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/219517/240e7662.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219517/c1c75488.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219517/240e7662.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/219517/240e7662.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lewisham-residence-london-2206069854956?ref=search&regionId=14",
        "city": "London",
        "room_id": "2d6feee3bcaf441686a9b0daee4d343c"
    },
    {
        "room_name": "GoBritanya White City Residence, London",
        "location": "Westway, London, W3 7EL",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "Study Area",
            "Lounges",
            "Reception",
            "From£425/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/170786/b6518e30.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170786/89b51222.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170786/b6518e30.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/170786/b6518e30.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/white-city-residence-go-britanya-london-2012093215616?ref=search&regionId=14",
        "city": "London",
        "room_id": "2285aae7cdf944fdbe9c6337caabde8f"
    },
    {
        "room_name": "GoBritanya Ealing Residence, London",
        "location": "Victoria Rd, London, W3 6UN",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Fully Furnished",
            "On Site Laundry",
            "Reception",
            "Cinema",
            "Reception",
            "From£385/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1102299/9322f6e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102299/a26d7fc3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102299/9322f6e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1102299/9322f6e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/go-britanya-ealing-residence-london-2406071331750?ref=search&regionId=14",
        "city": "London",
        "room_id": "b477395242624b7fb32c48c3c5359dd0"
    },
    {
        "room_name": "Chapter White City, London",
        "location": "Westway, London, W12 0PT",
        "details": [
            "31 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "On-Site Gym",
            "From£449/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/933649/329ce856.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211687/25d6a110.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/933649/329ce856.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/933649/329ce856.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chapter-white-city-2203154510815?ref=search&regionId=14",
        "city": "London",
        "room_id": "0f01bc447fcf4f428258299b5312da47"
    },
    {
        "room_name": "Londonist White City Residence, London",
        "location": "Westway, London, W12 0DD",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry",
            "Bills Included",
            "Wifi",
            "Laundry",
            "Entertainment",
            "From£450/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149393/c5beb28c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149392/b342b4bf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149393/c5beb28c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149393/c5beb28c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-white-city-residence-london-2003137514390?ref=search&regionId=14",
        "city": "London",
        "room_id": "9d2c73c624104e75a2fdcafed2f922b5"
    },
    {
        "room_name": "Londonist Hackney Wick Park, London",
        "location": "Stour Rd, London, E3 2NT",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Study Area",
            "WIFI",
            "Laundry",
            "From£510/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/289394/10f54f26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/289386/d6bbc431.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/289394/10f54f26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/289394/10f54f26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-hackney-wick-park-london-2308104164362?ref=search&regionId=14",
        "city": "London",
        "room_id": "e89398da755a40f697365de175c6f008"
    },
    {
        "room_name": "iQ Sterling Court, London",
        "location": "Lakeside Way, London, HA9 0BU",
        "details": [
            "17 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Study Area",
            "Bills Included",
            "From£286/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/241891/ab026e2d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143147/4464fb56.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/241891/ab026e2d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/241891/ab026e2d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-sterling-court-wembley-1811167912400?ref=search&regionId=14",
        "city": "London",
        "room_id": "12ff39cadf87434b876fb62c225bf756"
    },
    {
        "room_name": "The Cube Greenwich, London",
        "location": "Denham St, London, SE10 0XU",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Gymnasium",
            "From£375/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1098710/6f2c02ed.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136784/55cce9da.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1098710/6f2c02ed.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1098710/6f2c02ed.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-cube-greenwich-london-1708023708709?ref=search&regionId=14",
        "city": "London",
        "room_id": "415ddd33c44743baa3fa33c20e0313af"
    },
    {
        "room_name": "London Nest Mannequin House, London",
        "location": "Vanguard Wy, London, E17 6ZN",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Laundry Facility",
            "Bills Included",
            "Lounge",
            "From£350/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/278746/4fb87672.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/225346/ef32ef28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/278746/4fb87672.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/278746/4fb87672.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonest-mannequin-house-2208057845027?ref=search&regionId=14",
        "city": "London",
        "room_id": "375f356691fb475e82b2e95287983532"
    },
    {
        "room_name": "iQ Flinders House, London",
        "location": "E Parkside, London, SE10 0FQ",
        "details": [
            "26 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Study Area",
            "Game Room",
            "From£399/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/241509/613eb940.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/162796/be45307f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/241509/613eb940.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/241509/613eb940.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-flinders-house-greenwich-2010058192275?ref=search&regionId=14",
        "city": "London",
        "room_id": "6428bbed899f4d2fbfb94261129cb075"
    },
    {
        "room_name": "Londonist Seven Sisters, London",
        "location": "Chesnut Rd, London, N17 9EN",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "Post Box",
            "From£390/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1207893/ed9b56ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1207797/c91fe82e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1207893/ed9b56ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1207893/ed9b56ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-seven-sisters-london-2408058230871?ref=search&regionId=14",
        "city": "London",
        "room_id": "d44bdd407f2446d9be6dc882db56fd63"
    },
    {
        "room_name": "New Malden Hall, London",
        "location": "Burlington Rd, New Malden, KT3 4NN",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bills Included",
            "Laundry Facility",
            "24-Hour Gym",
            "From£255/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/258663/c4e6289c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138593/123303c8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/258663/c4e6289c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/258663/c4e6289c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/malden-hall-london-1712227599031?ref=search&regionId=14",
        "city": "London",
        "room_id": "9fac04d720e24c878e1db82df2859258"
    },
    {
        "room_name": "Ark Wembley, Wembley",
        "location": "First Way, Wembley, HA9 0PE",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cinema Room",
            "Meeting Rooms",
            "Bills Included",
            "Gym",
            "Cinema Room",
            "From£346/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/572751/5c8aeab9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/288047/810f3643.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/572751/5c8aeab9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/572751/5c8aeab9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/wembley-ark-wembley-2308071691695?ref=search&regionId=14",
        "city": "London",
        "room_id": "d7372fc3fc194a408851a5d8d2359962"
    },
    {
        "room_name": "London Nest The Helix Wembley, London",
        "location": "N End Rd, Wembley, HA9 0UU",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Game Room",
            "Laundry Facility",
            "Games Room",
            "From£355/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/284754/ab0655c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/284754/3126b0d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/284754/ab0655c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/284754/ab0655c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/london-nest-the-helix-wembley-london-2307204100911?ref=search&regionId=14",
        "city": "London",
        "room_id": "b474c7cf8b7e416a806b50b34fb79f2b"
    },
    {
        "room_name": "Londonist South Bermondsey, London",
        "location": "Ilderton Rd, London, SE15 1NW",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "CCTV",
            "Free Wifi",
            "Games Room",
            "Social Spaces",
            "From£420/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1208808/ffad9fd1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1208332/e46183d9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1208808/ffad9fd1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1208808/ffad9fd1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonist-south-bermondsey-london-2408067929493?ref=search&regionId=14",
        "city": "London",
        "room_id": "dc18be9eccb8480faae0c3a1859da271"
    },
    {
        "room_name": "Britannia South Bank, London",
        "location": "Rushworth St, London, SE1 0RB",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Communal Areas",
            "Laundry Facilities",
            "Wifi Internet",
            "Garden",
            "From£309/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/286860/20457b3c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/286860/63f96d66.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/286860/20457b3c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/286860/20457b3c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/britannia-south-bank-london-2308028175513?ref=search&regionId=14",
        "city": "London",
        "room_id": "81338711f82847f99b4db3ff65491bdf"
    },
    {
        "room_name": "Rathbone Place, London",
        "location": "Tottenham Ct Rd, London, W1T 1BA",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "CCTV",
            "WiFi Internet",
            "Weekly Cleaning",
            "From£315/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1202588/bd3374e6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1202522/3da3482c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1202588/bd3374e6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1202588/bd3374e6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/rathbone-place-london-2408027797079?ref=search&regionId=14",
        "city": "London",
        "room_id": "93e04b55b80442cba5302dd629ea962d"
    },
    {
        "room_name": "Britannia Student Scape Wembley, London",
        "location": "Fulton Rd, Wembley, HA9 0LR",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "All Utility Bills Included",
            "Fully Equipped Kitchens",
            "Free On-Site Gym",
            "Games Area",
            "From£456/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1322406/fba51ebf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1322373/bb9a1278.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1322406/fba51ebf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1322406/fba51ebf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/britannia-student-scape-wembley-london-2410055854062?ref=search&regionId=14",
        "city": "London",
        "room_id": "956e2bff9b434f0ba6f5794dc1b138d8"
    },
    {
        "room_name": "The Valentine, London",
        "location": "Perth Rd, Ilford, IG2 6BX",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "From£350/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/179443/015b1cd6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149472/4f0c914e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/179443/015b1cd6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/179443/015b1cd6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-valentine-london-2003160232475?ref=search&regionId=14",
        "city": "London",
        "room_id": "37b62fac1e0e49d08367d67b47ac9c98"
    },
    {
        "room_name": "iQ Paris Gardens, London",
        "location": "Paris Garden, London, SE1 8ND",
        "details": [
            "21 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "24/7 Security",
            "Cinema Room",
            "Breakfast Bar",
            "From£439/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143234/55ce0ee4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133440/58bacd01.png?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143234/55ce0ee4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143234/55ce0ee4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-paris-gardens-london-1608304485980?ref=search&regionId=14",
        "city": "London",
        "room_id": "f0b8fd40f82d4b81b93085a4f105d29e"
    },
    {
        "room_name": "GoBritanya Vega Residence, London",
        "location": "Miles St, London, SW8 1RZ",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "On Site Laundry",
            "On Site Gym",
            "Cinema",
            "Cinema",
            "On Site Gym",
            "From£440/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1103276/1230cc8a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102300/39be56fb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1103276/1230cc8a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1103276/1230cc8a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/go-britanya-vega-residence-london-2406077884304?ref=search&regionId=14",
        "city": "London",
        "room_id": "5db40db43d8d408fa9a212293424d40c"
    },
    {
        "room_name": "The Grange, Leicester",
        "location": "Grange Ln, Leicester, LE2 7EE",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Bills Included",
            "Laundry Facility",
            "Lounge Area",
            "From£126/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1084933/d56af95f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175409/16c265e7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1084933/d56af95f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1084933/d56af95f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-grange-leicester-2101153398144?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7561eb4d11514c468e1d15ba187742c1"
    },
    {
        "room_name": "Regents Court, Leicester",
        "location": "Rawson St, Leicester, LE1 6UP",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "BBQ",
            "Fan",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146528/67bdd492.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146528/df1e84f3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146528/67bdd492.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146528/67bdd492.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/regents-court-leicester-1907271681424?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "8c1bea249848474f92f6cea7da6a19ed"
    },
    {
        "room_name": "Highgate, Leicester",
        "location": "Prebend St, Leicester, LE2 0LA",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wifi",
            "Parking",
            "Laundry",
            "Cleaner",
            "Laundry Room",
            "Self Catered",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/223040/643e86c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/223017/a6c86237.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/223040/643e86c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/223040/643e86c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/highgate-leicester-2207204682036?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "b4e0467a7e744714bb12fd08a7304d1c"
    },
    {
        "room_name": "Tudor Studios, Leicester",
        "location": "Tudor Rd, Leicester, LE3 5HU",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "Garden",
            "Cleaner",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/155003/e04e7b07.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/154990/5309eff6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/155003/e04e7b07.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/155003/e04e7b07.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/tudor-studios-2007016883849?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "8e04c43e53754a69bc09be07538c36e9"
    },
    {
        "room_name": "Eastern Boulevard, Leicester",
        "location": "Eastern Blvd, Leicester, LE2",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Elevators",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156332/502274da.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156332/86940965.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156332/502274da.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156332/502274da.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/eastern-boulevard-2007171730395?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "1f039446b9f3464483682c812b25e0a1"
    },
    {
        "room_name": "Salisbury Road, Leicester",
        "location": "Regent Rd, Leicester, LE1 7PA",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Onsite Gym",
            "Onsite Parking",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/221962/977e8e4c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221962/a58e3887.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221962/977e8e4c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/221962/977e8e4c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/salisbury-road-leicester-2207046857986?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "2d036fffd8624da1b10709ded3e5733e"
    },
    {
        "room_name": "Upperton Road, Leicester",
        "location": "Upperton Rd, Leicester, LE3 0GF",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Cinema",
            "Fan",
            "Elevators",
            "From£167/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/924565/1f9d3c54.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141232/e86f6665.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924565/1f9d3c54.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/924565/1f9d3c54.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/upperton-road-1805176443273?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "05ec55828fd84c28b20574f4ba4e63bf"
    },
    {
        "room_name": "Renaissance House, Leicester",
        "location": "Princess Rd W, Leicester, LE1 6TP",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Cinema Room",
            "Onsite Gym",
            "Onsite Gym",
            "Onsite Parking",
            "Bike Storage Facilities",
            "From£171/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144873/f9d8174c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144873/10bdc47f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144873/f9d8174c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144873/f9d8174c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/renaissance-house-leicester-1905222645903?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "960cb9eadd9f486b936abb8e513a94fe"
    },
    {
        "room_name": "Regent Road, Leicester",
        "location": "Regent Rd, Leicester, LE1 7LT",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Onsite Gym",
            "Cinema Room",
            "From£179/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144869/0ed42c4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144869/7c615510.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144869/0ed42c4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144869/0ed42c4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/regent-road-1905222821911?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "9ae3a1b1dcaa4efdbb2aeefb597ee572"
    },
    {
        "room_name": "The Summit, Leicester",
        "location": "Eastern Blvd, Leicester, LE2 7JD",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bike Storage",
            "BBQ",
            "Gym",
            "From£182/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/926819/7b08d543.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156331/e382f3bf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/926819/7b08d543.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/926819/7b08d543.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-summit-2007179207649?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7dc169770afe4c85b89abde52bf969a5"
    },
    {
        "room_name": "The Bank, Leicester",
        "location": "Evington Rd, Leicester, LE2 1HT",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "On-Site Bike Storage",
            "Laundry Facility",
            "Gym Nearby",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/198444/b75b874b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/198444/15ba64a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/198444/b75b874b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/198444/b75b874b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-bank-leicester-2111130449834?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "25b13ad9781b4e95929542683f37d6f1"
    },
    {
        "room_name": "Edge Apartments, Leicester",
        "location": "Atkins St, Leicester, LE2 7DN",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Study Space",
            "Cinema",
            "Wifi",
            "From£187/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1091346/f32a8c41.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139341/454735e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1091346/f32a8c41.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1091346/f32a8c41.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/edge-apartments-leicester-1803074843949?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "3fdf9e6a8ad949a1a5e16657d50661ac"
    },
    {
        "room_name": "Study Inn Reynard House, Leicester",
        "location": "Welford Rd, Leicester, LE2 7AD",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Wellness And Spa Facilities",
            "Study Rooms",
            "Concierge",
            "From£194/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200441/85acf818.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200441/d4dbfd45.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200441/85acf818.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200441/85acf818.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/study-inn-reynard-house-leicester-2111249967208?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "49f15460fe584fa1bf08eda596a8a686"
    },
    {
        "room_name": "The Aspen, Leicester",
        "location": "Conduit St, Leicester, LE2 0JN",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "High-Speed WiFi",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200965/430750ad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200965/795d68f3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200965/430750ad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200965/430750ad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-aspen-leicester-2111265753419?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7d32203ff12b4bf1b9537a23621e0ed4"
    },
    {
        "room_name": "True Leicester, Leicester",
        "location": "Humberstone Park Cl, Leicester, LE5 4DW",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "Smart TV",
            "App",
            "Cinema",
            "Smart TV",
            "From£179/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/918860/76d9eaaf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/216630/c78f5197.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/918860/76d9eaaf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/918860/76d9eaaf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-leicester-leicester-2204185743956?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "2236c93b03c140ad90b3442e9763ee5e"
    },
    {
        "room_name": "The Tannery, Leicester",
        "location": "Bath Ln, Leicester, LE3 5EU",
        "details": [
            "23 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "Lounge",
            "Laundry",
            "From£145/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/245187/295a8f5c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/177934/da91c58a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/245187/295a8f5c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/245187/295a8f5c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-tannery-leicester-2102250720774?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "62703b8c2ff64070a0c42346733330b1"
    },
    {
        "room_name": "Castle Court, Leicester",
        "location": "Castle St, Leicester, LE1 5WL",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema Room",
            "Vending Machine",
            "Laundry Facility",
            "Laundry",
            "From£151/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148873/b406f5d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148873/0abc74c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148873/b406f5d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148873/b406f5d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/castle-court-leicester-2002132620939?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "0d37a287464f46e993abcf41895ab375"
    },
    {
        "room_name": "Kingfisher Halls, Loughborough",
        "location": "Kingfisher Way, Loughborough, LE11 3NY",
        "details": [
            "25 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Hot Tub",
            "BBQ Area",
            "Onsite Parking",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138928/6d7d0655.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138928/60e13984.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138928/6d7d0655.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138928/6d7d0655.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kingfisher-halls-loughborough-1801316114630?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "63ccf9ca92284f4e8b5c1e4eb9e6f2c6"
    },
    {
        "room_name": "Forest Court, Loughborough",
        "location": "Forest Rd, Loughborough, LE11 3NT",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Washing Facilities",
            "Pool Table",
            "BBQ Area",
            "Garden Area",
            "Meeting Room",
            "From£147/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138954/4a34f059.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138954/e4886d90.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138954/4a34f059.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138954/4a34f059.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/forest-court-loughborough-1802010996608?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "6d624a0d6c8542fbabe6365afa9ac743"
    },
    {
        "room_name": "Radmoor House, Loughborough",
        "location": "Radmoor Rd, Loughborough, LE11 3BS",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "Laundry Facility",
            "Study Area",
            "Social Area",
            "Living Area",
            "Onsite Parking",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138945/cef0c23d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138945/9014ee2a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138945/cef0c23d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138945/cef0c23d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/radmoor-house-loughborough-1802012682793?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "b51510c987e0496ea0ab0c5972fb65d9"
    },
    {
        "room_name": "The Student Block, Loughborough",
        "location": "Ashby Square, Loughborough, LE11 5AA",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facilities",
            "24 Hour Warden",
            "Bills Included",
            "Onsite Gym",
            "Garden Area",
            "From£156/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138942/be771c96.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138942/80f5cf2b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138942/be771c96.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138942/be771c96.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-student-block-loughborough-1802013437526?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "a82a099055e74a15b3db696b4771f05d"
    },
    {
        "room_name": "Forest Rise, Loughborough",
        "location": "Forest Rd, Loughborough, LE11 3NW",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facility",
            "Cinema",
            "Game Room",
            "Onsite Gym",
            "Living Room",
            "From£176/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138956/018ed8ec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138956/8a09b98d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138956/018ed8ec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138956/018ed8ec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/forest-rise-loughborough-1802016794812?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "101c8c9fdb2143a0b4693f0c7a472576"
    },
    {
        "room_name": "Lemyngton Street, Loughborough",
        "location": "Lemyngton St, Loughborough, LE11 1UJ",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Games Room",
            "Cinema Room",
            "Laundry Facilities",
            "Cinema",
            "From£195/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143823/10e42e64.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143823/e2eb9e0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143823/10e42e64.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143823/10e42e64.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/study-inn-lemyngton-street-loughborough-1812215350740?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "d94b25373e8240cbb88b8442665215cb"
    },
    {
        "room_name": "Optima, Loughborough",
        "location": "Greenclose Ln, Loughborough, LE11 5AS",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Cycle Store",
            "Laundry",
            "From£183/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/243754/02859a30.png?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133463/36684a3f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/243754/02859a30.png?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/243754/02859a30.png?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/optima-loughborough-1608301136003?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "f47f2dee896a41c0829d76e4f4852106"
    },
    {
        "room_name": "10 Albion Street, Leicester",
        "location": "Albion St, Leicester, LE1 6GB",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Super Fast Broadband",
            "Living Room",
            "Laundry Facility",
            "Stunning Flats",
            "10-Minute Walk To DMU",
            "From£108/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1129619/9d51f91f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1129619/8c8ff3cd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1129619/9d51f91f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1129619/9d51f91f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/10-albion-street-leicester-2406247609297?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "60a41ddbdc31498fb6a3ae849fd2a326"
    },
    {
        "room_name": "Albion Court, Leicester",
        "location": "Western Rd, Leicester, LE3 0GF",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Internet",
            "CCTV",
            "Laundry Facility",
            "WiFi",
            "Wifi",
            "Garden",
            "Cleaner",
            "Recycling",
            "Common Room",
            "From£110/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/588553/3b24a73a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/588541/7dac08a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/588553/3b24a73a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/588553/3b24a73a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/albion-court-leicester-2309084921919?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "9de50597d64b417ab179df8fd02d932d"
    },
    {
        "room_name": "Brookland Road, Leicester",
        "location": "Brookland Rd, Leicester, LE2 6BH",
        "details": [
            "26 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wifi",
            "Laundry Facility",
            "Bills Included",
            "Dryer",
            "Washer",
            "Elevator",
            "From£111/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/924610/304d41dd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/154137/5a4a61a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924610/304d41dd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/924610/304d41dd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/brookland-road-2006198550543?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "4d53b7f7ebe04e6994f0d3ceea95b8ce"
    },
    {
        "room_name": "Deacon House, Leicester",
        "location": "Deacon St, Leicester, LE2 7EF",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Game Room",
            "Louge",
            "Laundry Facility",
            "Wifi",
            "Cafes",
            "Fridge",
            "Freezer",
            "Microwave",
            "From£122/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1032430/b0711c4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1032430/eb411f63.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1032430/b0711c4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1032430/b0711c4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/deacon-house-leicester-2404190692195?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "30ed10b27b52457aa08539953d7068ed"
    },
    {
        "room_name": "The 100 Apartments, Leicester",
        "location": "Vaughan Wy, Leicester, LE1 4SH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Game Room",
            "Laundry Facility",
            "Wifi",
            "Cafes",
            "Fridge",
            "Freezer",
            "Microwave",
            "Fancy Gyms",
            "From£122/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1032428/49f3d390.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1032428/dfe24e03.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1032428/49f3d390.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1032428/49f3d390.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-100-apartments-leicester-2404195565323?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7f489442ee6f41b2b64464cfbfaebdd3"
    },
    {
        "room_name": "Newarke Street, Leicester",
        "location": "Oxford St, Leicester, LE1 5XU",
        "details": [
            "22 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bike Storage",
            "Study Area",
            "Elevators",
            "Bike Storage",
            "Outdoor Area",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/924551/7fc6a117.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/151718/7ce59554.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924551/7fc6a117.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/924551/7fc6a117.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/newarke-street-2005043932380?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "44cb288dbaf9479f934aa352ed2339be"
    },
    {
        "room_name": "iQ Grosvenor House, Leicester",
        "location": "Newarke St, Leicester, LE1 5SP",
        "details": [
            "14 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Pool Table",
            "Study Booths",
            "Courtyard",
            "Seating Area",
            "From£132/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143438/af13aa55.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143434/d3b28cf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143438/af13aa55.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143438/af13aa55.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-grosvenor-house-leicester-1811260522658?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "de3361dec4dc422b9d838e30ad2367a1"
    },
    {
        "room_name": "Hops House, Leicester",
        "location": "Burns St, Leicester, LE2 6DJ",
        "details": [
            "2 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Parking Available",
            "Bills Included",
            "Bike Storage",
            "Central Heating",
            "From£137/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/183309/6a6c5a3e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183309/de0208d7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183309/6a6c5a3e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/183309/6a6c5a3e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/hops-house-2105033215125?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "99e2393e50d747ebaa46d79afa786bbf"
    },
    {
        "room_name": "Collegiate Merlin Heights, Leicester",
        "location": "Bath Ln, Leicester, LE3 5AU",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gaming Area",
            "Laundry Facility",
            "Secure Bike Storage",
            "Sky Lounge",
            "Games Area",
            "From£137/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/183481/93b254cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132729/6a135cc4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183481/93b254cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/183481/93b254cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/merlin-heights-leicester-1608300235054?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "4688bc48945d450fa7ed4a44c9341040"
    },
    {
        "room_name": "Queens Court, Leicester",
        "location": "Jarrom St, Leicester, LE2 7DJ",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "BBQ",
            "Dining Room",
            "Bike Storage",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/180382/4afafd5e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/180382/760f8fed.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/180382/4afafd5e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/180382/4afafd5e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/queens-court-leicester-2103176973356?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "ef939192c03a41f9ba32f0524b9b4040"
    },
    {
        "room_name": "The Nest, Leicester",
        "location": "Marble St, Leicester, LE1 5XB",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Laundry Facility",
            "Cinema",
            "Bills Included",
            "Study Space",
            "Cinema Room",
            "On-Site Laundry",
            "From£145/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/206449/72d19225.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/206441/65830295.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/206449/72d19225.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/206449/72d19225.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-nest-leicester-2201170907701?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "914dee8c306548d2bf8b808a30e06e6a"
    },
    {
        "room_name": "Code, Leicester",
        "location": "Western Rd, Leicester, LE3 0GD",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "Lounge",
            "Games Area",
            "Bike Storage",
            "Parcel Service",
            "Group Study Rooms",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/155687/925a7d4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/155678/da1c457e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/155687/925a7d4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/155687/925a7d4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/code-leicester-2007099341027?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7b762bd9ab964b51ab7ab7c24ffa1e20"
    },
    {
        "room_name": "iQ Opal Court, Leicester",
        "location": "Lancaster Rd, Leicester, LE1 7HA",
        "details": [
            "10 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "CCTV",
            "Bills Included",
            "Lounge",
            "Games Room",
            "Study Area",
            "From£152/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/211351/d343a6c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183250/a170f67a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211351/d343a6c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/211351/d343a6c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-opal-court-2104297659930?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "ff576372eb904facb8e894efdeedf44a"
    },
    {
        "room_name": "41 Castle Street, Leicester",
        "location": "Castle St, Leicester, LE1 5WN",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "Games Area",
            "Caretakers",
            "From£154/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147383/25236773.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147205/c98ac716.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147383/25236773.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147383/25236773.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/41-castle-street-1911179430693?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "a0bb0e6d053e4bd7a0722498005266c7"
    },
    {
        "room_name": "Pannell House, Leicester",
        "location": "Charles St, Leicester, LE1 1LE",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Cinema Room",
            "Bills Included",
            "WiFi",
            "Bike Store",
            "On-Site Gym",
            "Cinema Room",
            "City Centre Location",
            "From£169/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1124805/23ea2251.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1124805/5a1185ae.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1124805/23ea2251.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1124805/23ea2251.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/pannell-house-leicester-2406205012261?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "d7377c09efd44c388d14294a619efd28"
    },
    {
        "room_name": "22 Upperton Road, Leicester",
        "location": "Upperton Rd, Leicester, LE3 0BG",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Gym",
            "Laundry",
            "WiFi",
            "Bike Store",
            "On-Site Gym",
            "On-Site Laundry Facilities",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1124164/2a7b07dc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1124164/d668b37d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1124164/2a7b07dc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1124164/2a7b07dc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/22-upperton-road-leicester-2406200732207?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "531e20efcc3e4f2f97a208f06a3b36f6"
    },
    {
        "room_name": "9 De Montfort Mews, Leicester",
        "location": "De Montfort Mews, Leicester, LE1 7FW",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "Bills Included",
            "Study Pods",
            "Cinema Room",
            "From£179/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1313917/342962b3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1126366/d5e2161d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1313917/342962b3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1313917/342962b3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/9-de-montfort-mews-leicester-2406219230813?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7db3b2d036c440cca3317f5cf04bc543"
    },
    {
        "room_name": "The Orchard, Leicester",
        "location": "Friar Ln, Leicester, LE1 5RB",
        "details": [
            "4 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "City Centre",
            "Wifi",
            "Communal Area",
            "50\" Smart TVs",
            "Outdoor Garden",
            "From£180/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/211074/69943a24.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211074/861a8cb6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211074/69943a24.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/211074/69943a24.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-orchard-2203105671983?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "0e5dcd3cc502490a84f7bd6c31e297c4"
    },
    {
        "room_name": "Primus Place, Leicester",
        "location": "Gateway St, Leicester, LE2 7DP",
        "details": [
            "6 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "All Bills Included",
            "Wifi",
            "Post Room",
            "Laundry Room",
            "Self Catered",
            "From£180/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1060508/1a906a90.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139355/43b395aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1060508/1a906a90.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1060508/1a906a90.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/primus-place-leicester-1803074737903?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "9dea135f230e4548854fbd2bd822455a"
    },
    {
        "room_name": "17 De Montfort Street, Leicester",
        "location": "De Montfort St, Leicester, LE1",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Cinema Room",
            "Bike Store",
            "On Site Laundry Facilities",
            "Close To The University Of Leicester",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1128443/d98e5f31.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1128443/1426979f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1128443/d98e5f31.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1128443/d98e5f31.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/17-de-montfort-street-leicester-2406229352540?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "4147dcdebf514506bdd873ffc13b5b7e"
    },
    {
        "room_name": "21 De Montfort Street (Chetwyn House), Leicester",
        "location": "De Montfort St, Leicester, LE1 7GL",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "On Site Laundry",
            "Bills Included",
            "Gym",
            "Free Wifi",
            "Bike Store",
            "Study Pods",
            "Cinema Room",
            "From£189/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1128510/c6f7f696.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1128510/ad8ba00a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1128510/c6f7f696.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1128510/c6f7f696.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/21-de-montfort-street-chetwyn-house-leicester-2406224632136?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "7e588ec672d54d7888d9040cb30a3ee3"
    },
    {
        "room_name": "Button Factory, Leicester",
        "location": "Millstone Ln, Leicester, LE1 9DQ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bike Storage",
            "Tv",
            "Garden",
            "Fridge",
            "Lounge",
            "Freezer",
            "Study Room",
            "From£195/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/266415/52059c97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266415/6f719c8c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266415/52059c97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/266415/52059c97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/button-factory-leicester-2305045543485?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "f95f43a5b0c246e9af9e9c2ff5c21a2d"
    },
    {
        "room_name": "Leicester One, Leicester",
        "location": "Granby St, Leicester, LE1 1DJ",
        "details": [
            "1 Room Option",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Cinema Area",
            "Gaming Nook",
            "Housekeeper",
            "From£223/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/255104/19c51df6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/255104/ed2918b5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/255104/19c51df6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/255104/19c51df6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/leicester-one-leicester-2302135358054?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "9d6d0ecaf34a497e9f3909056fd523ee"
    },
    {
        "room_name": "Foundry 1, Loughborough",
        "location": "Woodgate, Loughborough, LE11 2TZ",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Bike Storage",
            "Post Room",
            "Recycling",
            "Study Area",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/223192/ac7f2bdb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/155053/444bc26c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/223192/ac7f2bdb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/223192/ac7f2bdb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/foundry-1-2007027388605?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "285219fc21064917924cae9aff577da8"
    },
    {
        "room_name": "Asha House, Loughborough",
        "location": "Woodgate, Loughborough, LE11 2TZ",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Secure Bike Storage",
            "On-Site Laundry Facilities",
            "Central Location",
            "Microwave",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138897/45aadeb3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138897/81dc8718.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138897/45aadeb3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138897/45aadeb3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/asha-house-loughborough-1801303591523?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "9dc42b2714194fb7af81efc27589ea41"
    },
    {
        "room_name": "Filbert Village, Leicester",
        "location": "Lineker Rd, Leicester, LE2 7FZ",
        "details": [
            "7 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Communal Lounge",
            "Communal TV",
            "Study Room",
            "Wifi",
            "Recycling",
            "Courtyard",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/178233/9aae7592.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175395/789c9de9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/178233/9aae7592.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/178233/9aae7592.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/filbert-village-leicester-2101150611367?ref=search&regionId=75",
        "city": "Leicester",
        "room_id": "4e46fd5b33b744a081ca201369261604"
    },
    {
        "room_name": "Collegiate Bagot Street, Birmingham",
        "location": "Birmingham, B4 7AY",
        "details": [
            "2 Room Options",
            "4 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Common Room",
            "Onsite Cinema",
            "Laundry Room",
            "Common Room",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140839/c866cd1a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140839/63b98b12.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140839/c866cd1a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140839/c866cd1a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bagot-street-birmingham-1805047920452?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "f9c486b9a490466baa2038a7aed6630c"
    },
    {
        "room_name": "Yugo Globe Works, Birmingham",
        "location": "Cliveland St, Birmingham, B19 3SH",
        "details": [
            "10 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "TV Area",
            "Bin Chutes",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146713/54d2b5d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146713/224352a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146713/54d2b5d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146713/54d2b5d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/globe-works-1909058664046?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "71b4e808bd724b959f3f30158cc0682b"
    },
    {
        "room_name": "Jennens Court, Birmingham",
        "location": "Jennens Rd, Birmingham, B5 5JR",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Luxury Living",
            "Dedicated Workspace",
            "Bills Included",
            "Pool",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139301/8bafa599.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139300/109e857d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139301/8bafa599.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139301/8bafa599.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/jennens-court-birmingham-1802195336015?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "0e8298c9f01e418b8710c8cd36139745"
    },
    {
        "room_name": "The Heights, Birmingham",
        "location": "Staniforth St, Birmingham, B4 7DR",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Courtyard",
            "Furnished",
            "From£169/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141277/7788eb6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141262/27038612.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141277/7788eb6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141277/7788eb6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-heights-birmingham-1805184213549?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "716e149a88e14c358e7a24b5b798b3f4"
    },
    {
        "room_name": "iQ Penworks House, Birmingham",
        "location": "Moland St, Birmingham, B4 7AS",
        "details": [
            "15 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "TV",
            "Lounge",
            "From£179/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/176236/d6de76d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143331/1cbd353a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/176236/d6de76d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/176236/d6de76d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-penworks-house-birmingham-1811234096274?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "69cc822e474b41d68fb54b5191c6ca56"
    },
    {
        "room_name": "Altura, Birmingham",
        "location": "Bath Row, Birmingham, B15 1NR",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "Game Room",
            "Lounge",
            "TV Nook",
            "Reception",
            "From£182/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/218309/63be6d81.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218303/e479a7c0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218309/63be6d81.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/218309/63be6d81.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/altura-birmingham-2205167308519?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "106a86bf5d2248939f429b9f51cf68c1"
    },
    {
        "room_name": "The Old Fire Station, Birmingham",
        "location": "Aston St, Birmingham, B4 7DA",
        "details": [
            "25 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "On-Site Laundry",
            "Bills Included",
            "Gym",
            "Furnished",
            "From£184/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132061/8b05dfa6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132061/d2f032a9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132061/8b05dfa6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132061/8b05dfa6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-old-fire-station-birmingham-1608306929727?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "0ff29577a46e42da9eb7575853a417a2"
    },
    {
        "room_name": "iQ Studios 51, Birmingham",
        "location": "Bagot St, Birmingham, B4 7AH",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Game Room",
            "Laundry Facility",
            "Courtyard",
            "Bicycle Storage",
            "From£195/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143322/1666fc93.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143321/5f0a2454.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143322/1666fc93.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143322/1666fc93.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-studios-51-birmingham-1811224475308?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "f8b13bbf0cc0453582dc540378b8aa92"
    },
    {
        "room_name": "iQ Broderick House, Birmingham",
        "location": "Bath Row, Birmingham, B15 1NQ",
        "details": [
            "13 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Study Area",
            "Laundry Facility",
            "Game Room",
            "From£205/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143308/6901fabc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143304/575bad63.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143308/6901fabc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143308/6901fabc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-broderick-house-birmingham-1811228733406?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "c1a1f35363ae4e14b8d45e9642924322"
    },
    {
        "room_name": "Compass, Birmingham",
        "location": "Vauxhall Rd, Birmingham, B7",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "Gym",
            "Coffee Bar",
            "Games Zone",
            "Maintenance",
            "From£218/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200952/87278999.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200952/21e2cfc0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200952/87278999.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200952/87278999.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/compass-birmingham-2111262756724?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "385d0b9d37aa4a0f85ee9e12b95ca95d"
    },
    {
        "room_name": "Windsor Court, Birmingham",
        "location": "Windsor St, Birmingham, B7 4NG",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Modern Games Room",
            "Foosball Table",
            "Snooker Table",
            "Bike Store",
            "Laundry Room",
            "From£220/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/218927/83ee4f69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218926/09ae1c26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218927/83ee4f69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/218927/83ee4f69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/249-windsor-court-birmingham-2205264710125?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "3c6e1513f8d7420da8cc9a97a853e198"
    },
    {
        "room_name": "True Birmingham, Birmingham",
        "location": "Upper Dean St, Birmingham, B5 4SJ",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Zones",
            "Laundry Facilities",
            "Cycle Storage",
            "Garden",
            "Cinema",
            "From£229/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/158298/330732bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147722/4e2dcad7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/158298/330732bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/158298/330732bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-birmingham-1912079457022?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "3a5a52708419496aa8ed33fac52fb1cf"
    },
    {
        "room_name": "Kensington House, Birmingham",
        "location": "Suffolk Street Queensway, Birmingham, B1 1LN",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cinema Room",
            "Free On-Site Gym",
            "Secure Cycle Store",
            "From£234/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142037/1e715238.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142037/21361e3b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142037/1e715238.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142037/1e715238.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kensington-house-birmingham-1806162081682?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "77ae7d05c97f40a1816503eaa976125b"
    },
    {
        "room_name": "Vita Student Pebble Mill, Birmingham",
        "location": "Pershore Rd, Birmingham, B29 7ES",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Gardens",
            "Courtyard",
            "Movie Room",
            "Free Events",
            "From£306/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147591/170f262d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143905/274d4d50.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147591/170f262d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147591/170f262d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-pebble-mill-birmingham-1901159718225?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "aee0d0108fe94327a9dce58a3ffe96d4"
    },
    {
        "room_name": "Onyx, Birmingham",
        "location": "Lancaster St, Birmingham, B4 7BZ",
        "details": [
            "11 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "Gym",
            "Courtyard",
            "Ping Pong",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147972/f456f97d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147971/e7daeb9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147972/f456f97d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147972/f456f97d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/onyx-birmingham-1912143014755?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "c9896132f4bd421380ac4ec72f0411d8"
    },
    {
        "room_name": "James Watt, Birmingham",
        "location": "Coleshill St, Birmingham, B4 7EH",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wi-Fi",
            "Laundry Facility",
            "Bills Included",
            "Laundry",
            "Recycling",
            "From£164/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/280102/8b6a038e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170271/d40b230d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/280102/8b6a038e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/280102/8b6a038e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/james-watt-birmingham-2012043940234?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "1a6c671c2c384d7ba7ac0c18bd1731cf"
    },
    {
        "room_name": "Lakeside, Birmingham",
        "location": "Coleshill St, Birmingham, B4 7UP",
        "details": [
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "Common Area",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139298/da680a65.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139298/5a4b2f5b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139298/da680a65.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139298/da680a65.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lakeside-birmingham-1802181427995?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "d4b4a77b592d4a7a9d9660077fcb5e1d"
    },
    {
        "room_name": "Mary Sturge, Birmingham",
        "location": "Aston St, Birmingham, B4 7UJ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machines",
            "Laundry Facility",
            "Bike Storage",
            "Wi-Fi",
            "Laundry",
            "From£178/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146630/3a45f851.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142733/7f2354f2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146630/3a45f851.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146630/3a45f851.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mary-sturge-birmingham-1809115189325?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "d99ace21871b468f8d3d654fdb73954a"
    },
    {
        "room_name": "Calthorpe Court, Birmingham",
        "location": "Pershore Rd, Birmingham, B5 7QF",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Laundry Facility",
            "Games Area",
            "Recycling",
            "Courtyard",
            "Pool Table",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931692/090cc5be.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148784/88274e70.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931692/090cc5be.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931692/090cc5be.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/calthorpe-court-birmingham-2002113688729?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "079fe92e3d4c453490f88e85c77e90a7"
    },
    {
        "room_name": "Staniforth House, Birmingham",
        "location": "Staniforth St, Birmingham, B4 7DN",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Study Area",
            "Cinema",
            "Laundry",
            "Recycling",
            "From£186/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144897/55530eee.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143879/d2986be7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144897/55530eee.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144897/55530eee.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/staniforth-house-birmingham-1901117893660?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "4339bb2f7eaf473ea16fd63a704e2132"
    },
    {
        "room_name": "88 Bromsgrove House, Birmingham",
        "location": "Bromsgrove St, Birmingham, B5 6QB",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Karaoke Room",
            "Pool Table",
            "Gym",
            "Recycling",
            "Study Room",
            "Games Room",
            "Pool Table",
            "From£245/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138633/2ce26710.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138630/ff125d00.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138633/2ce26710.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138633/2ce26710.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/88-bromsgrove-house-birmingham-1712279034563?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "e7b2ef0e722d4ff480a0ca52710ef13e"
    },
    {
        "room_name": "Lyttleton House, Birmingham",
        "location": "Bridge Rd, Birmingham, B8 3TE",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "High Speed Internet",
            "Smart TV",
            "Bills Included",
            "Living Area",
            "Communal Kitchen",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/218919/a145cc87.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218918/e4036631.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218919/a145cc87.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/218919/a145cc87.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lyttleton-house-birmingham-2205267290703?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "ddf346fa1e164f7580bc5d0c3643ee95"
    },
    {
        "room_name": "Beech Gardens, Birmingham",
        "location": "Elvetham Rd, Birmingham, B15 2NL",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "CCTV",
            "Recycling",
            "Study Room",
            "Games Room",
            "Bike Storage",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/199731/e83f0e9c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148781/3d6f4ebe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199731/e83f0e9c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/199731/e83f0e9c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/beech-gardens-birmingham-2002113613740?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "77a9ec5f933947529d3b0b6ce1739766"
    },
    {
        "room_name": "The Pavilion, Birmingham",
        "location": "William St, Birmingham, B15 1LJ",
        "details": [
            "9 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Fully-Furnished",
            "All Bills Included",
            "WI-FI",
            "Broadband",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140635/c52f0c2f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132092/0a4ba240.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140635/c52f0c2f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140635/c52f0c2f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-pavilion-birmingham-1608308253731?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "1c739c0d7ec045ff817fb8840b7f0ba8"
    },
    {
        "room_name": "Cornwall Street, Birmingham",
        "location": "Cornwall St, Birmingham, B3 3BY",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Laundry Facilities",
            "Bike Stores",
            "LCD TV",
            "Bike Stores",
            "Laundry Facilities",
            "High Speed Unlimited Wireless Broadband",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/218922/a1f015d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218921/af8f2b73.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218922/a1f015d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/218922/a1f015d5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cornwall-street-studios-birmingham-2205264936950?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "548d15cccb594056abf072bd620a5dec"
    },
    {
        "room_name": "Duncan Smith House, Birmingham",
        "location": "Ferncliffe Rd, Birmingham, B17 0QH",
        "details": [
            "9 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Fully Fitted Kitchen",
            "Off-Road Parking",
            "CCTV",
            "Off-Street Parking",
            "Ample Outdoor Garden Space",
            "From£148/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/222411/2b73dbfe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/222411/51f7717b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/222411/2b73dbfe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/222411/2b73dbfe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/duncan-smith-house-birmingham-2207144198467?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "55c1ba131ba94c3c95d6b5b911fa11ed"
    },
    {
        "room_name": "Metchley Hall, Birmingham",
        "location": "Harborne Park Rd, Birmingham, B17 0DA",
        "details": [
            "10 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wifi",
            "Flat Screen TV",
            "Wardrobe With Drawers",
            "Gym",
            "Communal Events",
            "From£190/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144184/0eece486.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144184/d1fe06a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144184/0eece486.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144184/0eece486.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/metchley-hall-birmingham-1903018859481?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "9347413fbbf24216a97ce5721a7e01c6"
    },
    {
        "room_name": "Cadnam Hall, Birmingham",
        "location": "Cadnam Cl, Birmingham, B17 0PX",
        "details": [
            "8 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Onsite Laundry",
            "Communal TV",
            "CCTV",
            "Gym",
            "Sofa",
            "Wifi",
            "Communal Bin",
            "From£173/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/222410/d3968858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/222410/0f960e04.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/222410/d3968858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/222410/d3968858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cadnam-hall-birmingham-2207148032788?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "64774c8196054940b8ef17319063267c"
    },
    {
        "room_name": "Riverside House, Birmingham",
        "location": "Cadnam Cl, Birmingham, B17 0PX",
        "details": [
            "1 Room Option",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Parking",
            "Laundry",
            "Bills Included",
            "Allocated Parking",
            "Mattress Protector",
            "From£230/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/935721/4fc4bca9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/935716/4b582f0d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/935721/4fc4bca9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/935721/4fc4bca9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/riverside-house-birmingham-2312202949571?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "c2cd9833541d4d75989648b776479ec9"
    },
    {
        "room_name": "Harriet Martineau, Birmingham",
        "location": "Aston St, Birmingham, B4 7UP",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry",
            "Bike Storage",
            "Vending Machine",
            "Laundry",
            "Recycling",
            "From£164/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144095/6e101904.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144094/90673b46.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144095/6e101904.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144095/6e101904.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/harriet-martineau-birmingham-1902151550180?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "21dcf019cd0a4bd49ea03e4e8345c043"
    },
    {
        "room_name": "Athena Studios, Birmingham",
        "location": "Bristol Rd, Birmingham, B5 7SS",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Common Area",
            "Bike Storage",
            "From£239/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139306/712b310a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139305/75b098a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139306/712b310a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139306/712b310a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/athena-studios-birmingham-1802194324140?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "e3ebf9519de54cc3ae982dff274bedf7"
    },
    {
        "room_name": "Battery Park, Birmingham",
        "location": "Bristol Rd, Birmingham, B29 6SR",
        "details": [
            "1 Room Option",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wi-Fi",
            "Laundry Facility",
            "Bills Included",
            "Laundry",
            "Common Area",
            "From£289/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144122/e12da2b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144121/a45759bb.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144122/e12da2b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144122/e12da2b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/battery-park-birmingham-1902168315891?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "aa7de9e0c1014cb19456a6c61ea87b4f"
    },
    {
        "room_name": "Heantun Point, Wolverhampton",
        "location": "Culwell St, Wolverhampton, WV10 0JT",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Bills Included",
            "Laundry Facility",
            "Games Area",
            "Cinema Room",
            "From£96/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/182131/b23a0289.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149057/b6e54dde.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/182131/b23a0289.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/182131/b23a0289.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/heantun-point-2002159366971?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "d43f84e2ab9f4839a77c02f24ac544c3"
    },
    {
        "room_name": "The Toybox, Birmingham",
        "location": "Bishopsgate St, Birmingham, B15 1ET",
        "details": [
            "20 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Study Space",
            "Games Room",
            "Bike Store",
            "From£162/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143889/68e8c575.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143889/d90fab37.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143889/68e8c575.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143889/68e8c575.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-toybox-birmingham-1901156703049?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "768892b4a9314a47b3e475e4b95d0484"
    },
    {
        "room_name": "Harold Road, Birmingham",
        "location": "Harold Rd, Birmingham, B16 9DA",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Bills Excluded",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1096560/9c43dfa1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1096550/69219e22.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1096560/9c43dfa1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1096560/9c43dfa1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/harold-road-birmingham-2405318515965?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "3c33d93bf1e545d4b8d54e40f0006a86"
    },
    {
        "room_name": "Mostyn Road, Birmingham",
        "location": "Mostyn Rd, Birmingham, B16 9DU",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Bills Excluded",
            "Chic Living Space",
            "Private Garden Oasis",
            "Private Garden",
            "Modern Interiors",
            "Chic Living Space",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1096770/35c8f750.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1096761/0747f1b1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1096770/35c8f750.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1096770/35c8f750.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mostyn-road-birmingham-2405319979003?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "259a5a7480574846ad8e46fe7d76a0e9"
    },
    {
        "room_name": "Bywater House, Birmingham",
        "location": "Harold Rd, Birmingham, B16 9AU",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Bike Storage",
            "Laundry Facility",
            "Bills Excluded",
            "Outdoor Area",
            "Bike Storage",
            "Public Transport Links",
            "From£148/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142767/356ac004.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142765/a37f966a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142767/356ac004.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142767/356ac004.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bywater-house-birmingham-1809254352601?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "eb33208d215047db94ad38e152bdd3c6"
    },
    {
        "room_name": "Carlyle Road, Birmingham",
        "location": "Carlyle Rd, Birmingham, B16 9BH",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Entertainment Hub",
            "Shared Garden",
            "Bills Excluded",
            "Shared Garden",
            "Entertainment Hub",
            "From£145/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1097742/607f643f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1097726/76569f0a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1097742/607f643f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1097742/607f643f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/carlyle-road-birmingham-2406016062952?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "9d8acfd60a2044e4a1d22cf0c5e90659"
    },
    {
        "room_name": "Dawlish Road, Birmingham",
        "location": "Dawlish Rd, Birmingham, B29 7AF",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Bills Excluded",
            "Recently Refurbished",
            "Spacious Living Area",
            "Recently Refurbished",
            "Generous-Sized Garden",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1096651/3d35ba22.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1096603/6c919b57.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1096651/3d35ba22.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1096651/3d35ba22.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/dawlish-road-birmingham-2405318216580?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "65bdcb8233404a5f91cc1cfddbe1433e"
    },
    {
        "room_name": "6 Bedroom Apartment,18 Katie Road, Selly Oak, Birmingham B29 6JG",
        "location": "Katie Rd, Birmingham, B29 6JG",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Fully Furnished",
            "On Road Parking",
            "Garden",
            "Double Glazing",
            "Central Heating",
            "Newly Refurbished",
            "From£121/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/927725/02b3ac7f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/927724/b027cd1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/927725/02b3ac7f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/927725/02b3ac7f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-br-on-katie-road-selly-oak-2311230027985?ref=search&regionId=67",
        "city": "Birmingham",
        "room_id": "2e18282c6ade43668fc92ee83ef9247d"
    },
    {
        "room_name": "The Vantage, Nottingham",
        "location": "Traffic St, Nottingham, NG2 1NE",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema Room",
            "Game Room",
            "Laundry Facility",
            "Gym",
            "Garden",
            "Courtyard",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/926642/f2d4ee27.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/172357/49ef5974.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/926642/f2d4ee27.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/926642/f2d4ee27.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-vantage-nottingham-2012183753565?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "d83cdeee02444fab8740b6fbcf248293"
    },
    {
        "room_name": "Collegiate Nova, Nottingham",
        "location": "Talbot St, Nottingham, NG1 5HD",
        "details": [
            "1 Room Option",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Party Room",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/189525/26cb450a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/157480/17d076b8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/189525/26cb450a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/189525/26cb450a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/collegiate-nova-nottingham-2008078772986?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "668b56296601454889b1b3d05c6d0900"
    },
    {
        "room_name": "Collegiate Clarendon Street, Nottingham",
        "location": "Clarendon St, Nottingham, NG1 5NT",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Party Room",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/173017/19641ddd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139994/8868a63a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/173017/19641ddd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/173017/19641ddd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/clarendon-street-1804033239279?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "e2b66c96c9e24f4b930a89456d6fa0d6"
    },
    {
        "room_name": "iQ Newland House, Nottingham",
        "location": "Mount St, Nottingham, NG1 6HE",
        "details": [
            "13 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Lounge",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/239824/74a84be0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143511/175a8fca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/239824/74a84be0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/239824/74a84be0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-newland-house-nottingham-1811283514728?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "fada3c2985dc443ca8fa546748db0993"
    },
    {
        "room_name": "Trinity Square, Nottingham",
        "location": "N Church St, Nottingham, NG1",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Best Payment Plan",
            "Onsite Laundry Facility",
            "Bills Included",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141233/6ea082c2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141233/df827504.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141233/6ea082c2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141233/6ea082c2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/trinity-square-nottingham-1805170173946?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "4a4c16d81f93462dbb2c1ca0b9e5f351"
    },
    {
        "room_name": "iQ Newtown House, Nottingham",
        "location": "Maid Marian Way, Nottingham, NG1 6GQ",
        "details": [
            "13 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Cinema",
            "Bills Included",
            "Lounge",
            "From£222/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/228032/f6b13b9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143503/fd3bdefe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/228032/f6b13b9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/228032/f6b13b9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-newtown-house-nottingham-1811283159540?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "26d0d36872e34f478eef1d1b368d2dbc"
    },
    {
        "room_name": "iQ Signal Place, Nottingham",
        "location": "Traffic St, Nottingham, NG2 1NE",
        "details": [
            "15 Room Options",
            "5 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "Fitness Studio",
            "Lounge",
            "Courtyard",
            "From£164/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/923891/607b0d08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201783/05ce796a.png?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/923891/607b0d08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/923891/607b0d08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-signal-place-nottingham-2112034007052?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "d553b27742454fb791406044d806f308"
    },
    {
        "room_name": "The Laceworks, Nottingham",
        "location": "Queen's Rd, Nottingham, NG2",
        "details": [
            "11 Room Options",
            "5 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "All Bills Included",
            "Gym",
            "Wifi",
            "Garden",
            "From£197/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148407/88b3c91e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148407/1e215d31.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148407/88b3c91e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148407/88b3c91e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-laceworks-2001236483067?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "0b3f4bffe8614f6793b94cc07a8a85e0"
    },
    {
        "room_name": "Castle Gate Haus, Nottingham",
        "location": "Castle Gate, Nottingham, NG1 7AT",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Safety And Security",
            "Social Events Calendar",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146313/f969a297.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146307/93c4bedf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146313/f969a297.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146313/f969a297.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/castle-gate-haus-nottingham-1907109411153?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "095f6d3f9cdf472ebf3f165c65558495"
    },
    {
        "room_name": "Bertrand Russell House, Nottingham",
        "location": "Gamble St, Nottingham, NG7 4EB",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bills Included",
            "TV",
            "Free Monthly Cleaning",
            "Free Monthly Cleaning",
            "From£119/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/175590/ac16787b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175590/e39f7f45.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175590/ac16787b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/175590/ac16787b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bertrand-russell-house-2101184176437?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "02b7cdb11368432fb07bebaabf88f9ee"
    },
    {
        "room_name": "Six Degrees, Nottingham",
        "location": "St Marks St, Nottingham, NG3 1DE",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bills Included",
            "Furnished",
            "Close To The Victoria Centre",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1004443/5e68d2eb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1004437/91090b0c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1004443/5e68d2eb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1004443/5e68d2eb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/six-degrees-nottingham-2404013030475?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "5ac9d4776b134054bb1ace31ae0836ad"
    },
    {
        "room_name": "St Peters Court, Nottingham",
        "location": "Midland Way, Nottingham, NG7 3EU",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Outdoor Space",
            "Bills Included",
            "Lounge",
            "Study Area",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/230567/548ea11c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/230356/76480962.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/230567/548ea11c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/230567/548ea11c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-peters-court-nottingham-2208312472040?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "96b2b23ed6c048ef92b772abfc96817c"
    },
    {
        "room_name": "Varsity City, Nottingham",
        "location": "Malin Hill, Nottingham, NG1",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "250mbs WI-FI",
            "Free Gym Membership",
            "From£140/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/961764/8da53f9a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958919/28e76404.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/961764/8da53f9a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/961764/8da53f9a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/varsity-city-nottingham-2402161146249?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "2a390f0e042146488775eab8c723a548"
    },
    {
        "room_name": "Graystacks, Nottingham",
        "location": "Castle Blvd, Nottingham, NG7 1GY",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Lounge",
            "Bills Included",
            "Lounge",
            "Cycle Storage",
            "On-Site Laundry",
            "From£140/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/952469/80c295dd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/952469/b47ea334.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/952469/80c295dd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/952469/80c295dd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/graystacks-nottingham-2402071550490?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "c9b9e96431734cc5b81a7eab6b2bd87d"
    },
    {
        "room_name": "Medici, Nottingham",
        "location": "Hockley, Nottingham, NG1 1FH",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "All Utility Bills Included",
            "High Speed Internet",
            "Gym",
            "Study Area",
            "Pool Table",
            "From£145/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/186085/0dbf3a13.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/186085/8871d553.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/186085/0dbf3a13.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/186085/0dbf3a13.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/medici-nottingham-2106285386344?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "9228ed4a4b7d411dadb49cb40a9e24d5"
    },
    {
        "room_name": "The Nest, Nottingham",
        "location": "Derby Rd, Nottingham, NG1 5FB",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Games Lounge",
            "On-Site Laundry",
            "Study Space",
            "Study Hubs",
            "From£146/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/151989/486988af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/151989/ccc2ae3b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/151989/486988af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/151989/486988af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-nest-2005061492867?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "39932e56e79249e082f3b886c1c62a60"
    },
    {
        "room_name": "The Place, Nottingham",
        "location": "Queen's Rd, Nottingham, NG2 3BE",
        "details": [
            "6 Room Options",
            "4 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bike Storage",
            "Bills Included",
            "Gym",
            "Study Rooms",
            "Meeting Room",
            "Bike Storage",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/938656/59384e98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/938651/90f7f167.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/938656/59384e98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/938656/59384e98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-place-nottingham-2401084144697?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "f5219411923449159785bf5fa8d3daa6"
    },
    {
        "room_name": "New Brook House, Nottingham",
        "location": "Alfreton Rd, Nottingham, NG7 5LR",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "High Speed Internet",
            "24/7 Gym",
            "Cinema Room",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1288643/d600426f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1287510/4ae1c169.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1288643/d600426f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1288643/d600426f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/new-brook-house-nottingham-2409180168326?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "f93f362dd4fc4cfe9266323e54973a49"
    },
    {
        "room_name": "Radford House, Nottingham",
        "location": "Radford Blvd, Nottingham, NG7 5BA",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Cinema",
            "24/7 Gym",
            "Cinema Room",
            "Common Room",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/954497/cc45ea1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/954497/cc45ea1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/954497/cc45ea1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/radford-house-nottingham-2402135146570?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "32f18e639a6d4d33ae0a3cb8ca88b30d"
    },
    {
        "room_name": "The Rise, Nottingham",
        "location": "Russell St, Nottingham, NG7 4FN",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Game Room",
            "Laundry Facility",
            "Games Area",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/186544/12d37982.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138804/709cb8dd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/186544/12d37982.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/186544/12d37982.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-rise-1801121155547?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "00ade9be74fc43b7bdf69fdea9c089e6"
    },
    {
        "room_name": "Icon Student Living, Nottingham",
        "location": "St James's St, Nottingham, NG1 6FH",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Hi-Speed WiFi",
            "All Bills Inclusive",
            "On Site Laundry",
            "Twin Oven",
            "Smart TVs",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1241260/5dd5b0f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1238199/ce8fa5e9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1241260/5dd5b0f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1241260/5dd5b0f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/icon-student-living-nottingham-2408217330097?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "e422ad4592074334aee34548ef71b552"
    },
    {
        "room_name": "Arcadian, Nottingham",
        "location": "St Marks St, Nottingham, NG3 1DE",
        "details": [
            "8 Room Options",
            "4 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Cinema",
            "Gym",
            "Courtyard",
            "Games Room",
            "Coffee Bar",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/949765/c26d1fe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/949765/c26d1fe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/949765/c26d1fe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/arcadian-nottingham-2402025012709?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "faf22f98147d49afa68fdc21a532a72e"
    },
    {
        "room_name": "iQ Exchange, Nottingham",
        "location": "Lower Parliament St, Nottingham, NG1 1EE",
        "details": [
            "15 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "From£154/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143497/0202f6e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141296/86de6c1e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143497/0202f6e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143497/0202f6e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-exchange-nottingham-1805187954762?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "758d956bfdbb412f97e2de17be2531c5"
    },
    {
        "room_name": "Archer House, Nottingham",
        "location": "Castle Gate, Nottingham, NG1 7AW",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Laundry Facility",
            "On-Site Management Team",
            "Pool Table",
            "From£157/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149621/70004b07.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149619/6483252e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149621/70004b07.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149621/70004b07.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/archer-house-2003243382185?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "a48ee745b61e4f58be54404de76e1d13"
    },
    {
        "room_name": "Minerva House, Nottingham",
        "location": "Spaniel Row, Nottingham, NG1 6EP",
        "details": [
            "5 Room Options",
            "4 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Social Area",
            "Free WiFi",
            "CCTV",
            "WiFi",
            "Study Room",
            "From£168/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1303030/a900f22e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1089224/4d9ce92d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1303030/a900f22e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1303030/a900f22e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/minerva-house-nottingham-2405279263451?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "2516c7ce5a534a83b5c9347cd3ac4450"
    },
    {
        "room_name": "Redgate, Nottingham",
        "location": "Traffic St, Nottingham, NG2 1NE",
        "details": [
            "9 Room Options",
            "5 Offers",
            "Pay In Instalment",
            "Cinema",
            "Laundry Facility",
            "Bills Included",
            "Laundry",
            "Games Room",
            "Study Rooms",
            "On-Site Gym",
            "From£168/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/921091/866c9ed5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/921091/a154a794.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/921091/866c9ed5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/921091/866c9ed5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/redgate-nottingham-2311033309475?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "a056d6076dac46cfa441d82824ab71ad"
    },
    {
        "room_name": "Lace Market Studios, Nottingham",
        "location": "Belward St, Nottingham, NG1 1LB",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Lifts To All Floors",
            "Communal Lounge",
            "Study Area",
            "Study Area",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/953018/6f0b5fa4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139660/39ae7d4c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/953018/6f0b5fa4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/953018/6f0b5fa4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lace-market-studios-nottingham-1803232635734?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "b9d32d77e5864c92b2afd1e514e898e0"
    },
    {
        "room_name": "Hydrogen, Nottingham",
        "location": "Goldsmith St, Nottingham, NG1 5ND",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Bills Included",
            "Bike Storage",
            "TV",
            "From£179/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141225/42d75733.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141225/097c8c57.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141225/42d75733.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141225/42d75733.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/hydrogen-nottingham-1805172756326?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "24df0d99b30744f78760f4e77c6cf9aa"
    },
    {
        "room_name": "Kaplan Living Nottingham, Nottingham",
        "location": "Bromley Pl, Nottingham, NG1 6JG",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "Pool",
            "Ping-Pong",
            "Cinema Room",
            "From£180/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1236142/38d567c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1236037/a3cf19f4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1236142/38d567c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1236142/38d567c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kaplan-living-nottingham-nottingham-2408209560875?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "16bf58cd32e34eb2b18de8530c904fb4"
    },
    {
        "room_name": "iQ Nelson Court, Nottingham",
        "location": "Nelson St, Nottingham, NG1 1DR",
        "details": [
            "12 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Game Room",
            "Free Gym",
            "Stage Room",
            "From£183/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/252232/88c7c052.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/252198/4f9c40a2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/252232/88c7c052.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/252232/88c7c052.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-nelson-court-nottingham-2301255964224?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "394b7df78e0c403f99ed0cf8a863cac9"
    },
    {
        "room_name": "Nottingham Two, Nottingham",
        "location": "Midland Way, Nottingham, NG7 3LP",
        "details": [
            "31 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "BBQ",
            "From£186/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/924928/f44fc6cb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141243/5a394179.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924928/f44fc6cb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/924928/f44fc6cb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/nottingham-two-nottingham-1805177762751?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "d11fad8be5614021bb9232b2ff20bf5c"
    },
    {
        "room_name": "Bard House, Nottingham",
        "location": "Shakespeare St, Nottingham, NG1 4FQ",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "CCTV",
            "Courtyard",
            "Laundry Room",
            "Free WiFi",
            "Courtyard",
            "From£188/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1086034/fa610125.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1086016/87c53f91.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1086034/fa610125.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1086034/fa610125.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bard-house-nottingham-2405245329783?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "b2c51e1cc4184249a39ee5076e7df801"
    },
    {
        "room_name": "Yugo Goldsmith Court, Nottingham",
        "location": "Chaucer St, Nottingham, NG1 5LG",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "High Speed Wifi",
            "Post And Parcel Service",
            "Terrace",
            "Onsite Laundry",
            "From£189/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/930509/a2a2d42e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/155689/1912322a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/930509/a2a2d42e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/930509/a2a2d42e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/goldsmith-court-2007090163993?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "147bf0de9ea447c49d34f6ac813961d8"
    },
    {
        "room_name": "York House, Nottingham",
        "location": "Mansfield Rd, Nottingham, NG1",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Include",
            "Elevators",
            "Study Area",
            "From£191/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/169164/3ede030e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/169162/ff05a475.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/169164/3ede030e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/169164/3ede030e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/york-house-2011263399532?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "04f24397720d4c6a846f3f4d96cdeed9"
    },
    {
        "room_name": "Dojo House, Nottingham",
        "location": "Ilkeston Rd, Nottingham, NG7 3FX",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Game Room",
            "Laundry Facility",
            "Sofa",
            "Furnished",
            "From£195/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/219948/7984f28a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219945/0a51dbe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219948/7984f28a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/219948/7984f28a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/dojo-house-2206089612636?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "ee9c8d56f78941d7a947d850817dba4d"
    },
    {
        "room_name": "St Marks, Nottingham",
        "location": "St Marks St, Nottingham",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "New",
            "TV",
            "Gym Membership",
            "New Accommodation",
            "From£197/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/160019/a07ee7a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/160019/79212e0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/160019/a07ee7a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/160019/a07ee7a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-marks-nottingham-2009083881908?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "8dbfdd07edfc41c1869aca64f1289cce"
    },
    {
        "room_name": "Deakins Place, Nottingham",
        "location": "Deakins Pl, Nottingham, NG7 3FT",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Safety And Security",
            "Gym",
            "Elevator",
            "From£200/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/201381/8967533d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201381/735b6a05.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201381/8967533d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/201381/8967533d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/deakins-place-2111300009396?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "3775eecf31e44331805aef2f2e309814"
    },
    {
        "room_name": "Stanley House, Nottingham",
        "location": "Talbot St, Nottingham, NG1 5GL",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "New Gym",
            "Party Lounge",
            "From£204/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156454/1a6f1362.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156454/cb24807d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156454/1a6f1362.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156454/1a6f1362.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/stanley-house-2007187276666?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "6bd5459a29ae40f18cfefc06ca2f8d16"
    },
    {
        "room_name": "Talbot Street, Nottingham",
        "location": "Talbot St, Nottingham, NG1",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Games Room",
            "Vending Machine",
            "Laundry Facility",
            "From£210/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147017/07363cdf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147017/457a2c0d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147017/07363cdf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147017/07363cdf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/study-inn-talbot-street-nottingham-1911071973427?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "859c2eb3724647869eeb3d75396c9727"
    },
    {
        "room_name": "Carlton Building, Nottingham",
        "location": "Broad St, Nottingham, NG1 3AL",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Washing Machine",
            "Cosy Living Space",
            "High-Spec Kitchen And Bedroom",
            "Gym",
            "Lounge Area",
            "From£226/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/254954/cea0d73f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/254954/23081f78.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/254954/cea0d73f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/254954/cea0d73f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/carlton-house-nottingham-2302109408972?ref=search&regionId=34",
        "city": "Nottingham",
        "room_id": "9509e1dc3c954bf0b2cab26406ee0f87"
    },
    {
        "room_name": "X1 Arndale House, Liverpool",
        "location": "London Rd, Liverpool, L3 8JA",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Fully Furnished",
            "Complimentary Netflix",
            "On-Site Gym",
            "From£90/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/188357/440d6806.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188357/134f2e57.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188357/440d6806.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/188357/440d6806.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/x1-arndale-house-2108061188888?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "7b800dd71db14bd882e5863e892624e1"
    },
    {
        "room_name": "iQ Great Newton House, Liverpool",
        "location": "Lower Gill St, Liverpool, L3",
        "details": [
            "24 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "24/7 Staff Presence",
            "Bills Included",
            "TV",
            "Lounge",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147170/21e39353.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132926/69c27187.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147170/21e39353.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147170/21e39353.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-great-newton-house-liverpool-1608304787060?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "921e23dba68b4e8d81be780303a0c438"
    },
    {
        "room_name": "Prospect Point, Liverpool",
        "location": "Moira St, Liverpool, L6 1BA",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Lounge",
            "Laundry Facility",
            "Bills Included",
            "Common Room",
            "From£119/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148884/70a1a49a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148878/f749225d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148884/70a1a49a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148884/70a1a49a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/prospect-point-liverpool-2002134204705?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "475786ed9a51497d8bfb0fcabd34b551"
    },
    {
        "room_name": "X1 The Edge, Liverpool",
        "location": "Seymour St, Liverpool, L3 5PE",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Complimentary Netflix",
            "Laundry Facility",
            "On-Site Gym",
            "From£100/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/226674/4f1ee885.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188348/e69578f5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/226674/4f1ee885.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/226674/4f1ee885.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/x1-the-edge-liverpool-2108066899323?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "c26c8354a7264bf8a588666a8e79f1f4"
    },
    {
        "room_name": "The Exchange, Liverpool",
        "location": "Hotham St, Liverpool, L3 5UF",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Game Room",
            "Concierge",
            "Media Room",
            "Study Room",
            "TV Lounges",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/952135/05d33329.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/176422/95623bf9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/952135/05d33329.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/952135/05d33329.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-exchange-2101306721339?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "20e29ed912a7498184d91fa4be038d86"
    },
    {
        "room_name": "Borden Court, Liverpool",
        "location": "London Rd, Liverpool, L3 8JA",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "24/7 Security",
            "Bills Included",
            "Laundry Facility",
            "Washer/Dryer",
            "Transport Links",
            "From£79/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/963542/201f81e9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/963441/4055c5da.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/963542/201f81e9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/963542/201f81e9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/borden-court-liverpool-2402222309458?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "888b4c1a32dc42baa49db5b903e517e3"
    },
    {
        "room_name": "Element The Quarter, Liverpool",
        "location": "Low Hill, Liverpool, L6 1EJ",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry",
            "Games Room",
            "Safe Bike Storage",
            "24/7 Security 365 Days",
            "Wifi",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1106586/6bf52a60.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1106351/039d6f7b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1106586/6bf52a60.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1106586/6bf52a60.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/element-the-quarter-liverpool-2406116715152?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "8895e3ceb54b400fbf5344a84c82a464"
    },
    {
        "room_name": "Phoenix Place, Liverpool",
        "location": "Prince Edwin St, Liverpool, L5 3SJ",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "24h Security",
            "Laundry Facility",
            "Bills Included",
            "Wifi",
            "Cleaner",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/186018/aca9d947.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/186009/b82fd6cc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/186018/aca9d947.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/186018/aca9d947.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/phoenix-place-liverpool-2106267754634?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "beb0b36953ad48cbbbcde5e831f2a197"
    },
    {
        "room_name": "Falkland House, Liverpool",
        "location": "Falkland St, Liverpool, L3 8AF",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Great Location",
            "Bills Included",
            "Wifi",
            "Cleaner",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/145554/da7b069e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/145553/ac4645b1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/145554/da7b069e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/145554/da7b069e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/falkland-house-liverpool-1906279728760?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "94ccc85547734b3e8c595ffafaac1654"
    },
    {
        "room_name": "Apollo Court, Liverpool",
        "location": "Greek St, Liverpool, L3 5QJ",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facilities",
            "Bills Included",
            "Wifi",
            "Bike Storage",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141282/02301e54.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141255/5e06680c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141282/02301e54.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141282/02301e54.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/apollo-court-liverpool-1805171098203?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "59c44273c76848148b6acf91dea5639f"
    },
    {
        "room_name": "Albert Court, Liverpool",
        "location": "London Rd, Liverpool, L3 8JD",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Free Wifi",
            "Furniture",
            "From£126/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/219088/aa5ffeff.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137178/fb1c87bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219088/aa5ffeff.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/219088/aa5ffeff.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/albert-court-liverpool-1708313372308?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "b0e1cf63e0b14194a7fb5cc4cdc834af"
    },
    {
        "room_name": "The Railyard, Liverpool",
        "location": "Crown Station Pl, Liverpool, L7 3LA",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "BBQ Area",
            "BBQ Area",
            "Car Parking",
            "From£126/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141280/25881a6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141278/8d26ed09.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141280/25881a6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141280/25881a6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-railyard-liverpool-1805189329851?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "d50573aeea474170b898c17ea929d670"
    },
    {
        "room_name": "Queensland Place, Liverpool",
        "location": "Chatham Pl, Liverpool, L7 3AA",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Concierge",
            "TV Lounges",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/932188/b09c1b0b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/176414/5bdede7b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/932188/b09c1b0b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/932188/b09c1b0b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/queensland-place-2101309236510?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "c88727e226d44b2ab80d4eba85c0c94c"
    },
    {
        "room_name": "Plato House, Liverpool",
        "location": "Greek St, Liverpool, L3 5QJ",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Bills Included",
            "Common Room",
            "From£160/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/264878/e409abf0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146225/00a39d6d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/264878/e409abf0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/264878/e409abf0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/plato-house-liverpool-1907092608404?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "f21d91ad5f6e4356950a925a1c6b23be"
    },
    {
        "room_name": "Byrom Point, Liverpool",
        "location": "Great Crosshall St, Liverpool, L3 2AT",
        "details": [
            "12 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Wifi",
            "Elevators",
            "From£160/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/926166/d9604901.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132791/4a0d49a6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/926166/d9604901.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/926166/d9604901.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/byrom-point-liverpool-1608306844687?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "04550cdcc1014d9f8bfe188aa19dfce5"
    },
    {
        "room_name": "True Liverpool, Liverpool",
        "location": "Erskine St, Liverpool, L6 1AJ",
        "details": [
            "1 Room Option",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Zones",
            "Laundry Facilities",
            "Cycle Storage",
            "Garden",
            "Cinema",
            "From£167/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/166771/cb959fa7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/166764/96583ae4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/166771/cb959fa7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/166771/cb959fa7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-liverpool-2011117953561?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "5eb0bdf8d4b54feca8e23b96ccad10d9"
    },
    {
        "room_name": "Vita Student Crosshall Street, Liverpool",
        "location": "Crosshall St, Liverpool, L1",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry",
            "Cinema",
            "Study Rooms",
            "Gym",
            "Movie Room",
            "Bike Store",
            "Bike Rental",
            "From£197/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147089/889ed476.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132872/0dce90ac.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147089/889ed476.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147089/889ed476.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-crosshall-street-liverpool-1608304728969?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "682b8f0712514a02ba1b4a8b76befd45"
    },
    {
        "room_name": "Vita Student The Tinlings, Liverpool",
        "location": "Crosshall St, Liverpool, L1",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Swimming Pool",
            "Laundry Facility",
            "Bike Store",
            "Movie Room",
            "From£197/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/185469/dd6a71ca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/185469/a98806bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/185469/dd6a71ca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/185469/dd6a71ca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-the-tinlings-liverpool-2106189370710?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "f705609a1bb049738a0cacd4be4d295d"
    },
    {
        "room_name": "Kaplan Living Liverpool, Liverpool",
        "location": "Liverpool, L7 3FA",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "TV Lounge",
            "Games Room",
            "From£205/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1236178/16683e2a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1236160/4f17c64b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1236178/16683e2a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1236178/16683e2a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kaplan-living-liverpool-liverpool-2408202477469?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "e57d703e0f244c2b959fdab64fa2d5f0"
    },
    {
        "room_name": "The Ascent, Liverpool",
        "location": "Renshaw St, Liverpool, L1 2SD",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included Cinema",
            "From£221/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140963/b7398ce4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140963/1b27e700.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140963/b7398ce4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140963/b7398ce4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-ascent-liverpool-1805077073045?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "8611d472df734eb8bd64a9c604307709"
    },
    {
        "room_name": "Europa, Liverpool",
        "location": "Erskine St, Liverpool, L6 1AH",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Study Area",
            "Bills Included",
            "Lounge",
            "Laundry",
            "From£121/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139061/3e7f388a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132858/fe852742.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139061/3e7f388a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139061/3e7f388a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/europa-liverpool-1608302250167?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "4fce13cacc964b59a919a7281bef6b97"
    },
    {
        "room_name": "Cambridge Court, Liverpool",
        "location": "Cambridge Ct, Liverpool, L7 7JB",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recyling",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137817/4d61c547.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137816/a20cfd14.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137817/4d61c547.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137817/4d61c547.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cambridge-court-liverpool-1710240376939?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "93f4c7ca469f4b64b00493ceea60a196"
    },
    {
        "room_name": "Atlantic Point, Liverpool",
        "location": "Naylor St, Liverpool, L3 6LS",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Vending Machine",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "From£112/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148900/5a3cb593.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148898/6e414e23.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148900/5a3cb593.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148900/5a3cb593.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/atlantic-point-liverpool-2002135955868?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "21e28c0b5f254af29ef860dca6fb74e7"
    },
    {
        "room_name": "Paddington Park House, Liverpool",
        "location": "Paddington Village, Liverpool, L7 3RG",
        "details": [
            "1 Room Option",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Courtyard",
            "Laundry Facility",
            "Bike Storage",
            "Recycling",
            "Courtyard",
            "Laundry Room",
            "From£119/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148889/635327fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148889/741596f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148889/635327fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148889/635327fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/paddington-park-house-liverpool-2002130098795?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "b4fc0b29c32d427790dd2c35e53f1dce"
    },
    {
        "room_name": "Moorfield, Liverpool",
        "location": "Hatton Garden, Liverpool, L3 2EZ",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "TV Room",
            "CCTV",
            "Bills Included",
            "Laundry",
            "Recycling",
            "Cinema Room",
            "From£124/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148902/a4decfee.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148901/77729942.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148902/a4decfee.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148902/a4decfee.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/moorfield-liverpool-2002141197351?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "910e9e29275145d7ba324083067586c6"
    },
    {
        "room_name": "St Lukes View, Liverpool",
        "location": "Oldham St, Liverpool, L1 2SU",
        "details": [
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Study Area",
            "Laundry Facility",
            "Wi-Fi",
            "Laundry",
            "Recycling",
            "From£136/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137815/ea29a4be.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137814/1076c8f4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137815/ea29a4be.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137815/ea29a4be.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-lukes-view-liverpool-1710241326045?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "fabadf7ac6214ab88d33d8b2fc1e79bf"
    },
    {
        "room_name": "Lennon Studios, Liverpool",
        "location": "Cambridge Ct, Liverpool, L7 7AG",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "From£140/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137791/775ea03d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137791/106b8124.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137791/775ea03d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137791/775ea03d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lennon-studios-liverpool-1710235109897?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "fefecd71ad0446a68215ea9d0412b732"
    },
    {
        "room_name": "The Arch, Liverpool",
        "location": "Nelson St, Liverpool, L1 5DR",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Game Room",
            "Bills Included",
            "Cycle Storage",
            "Lounge",
            "Games Area",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132912/519ccef6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132909/97548c45.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132912/519ccef6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132912/519ccef6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-arch-liverpool-1608300157225?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "e806bc30fd5f4a60a7fe0fea59a8cf09"
    },
    {
        "room_name": "Limelight, Liverpool",
        "location": "Islington, Liverpool, L3 8LH",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "All Bills Included",
            "Study Space",
            "Gym",
            "Sky Lounge",
            "Games Room",
            "From£154/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/249263/ffd8a19f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/249248/635456de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/249263/ffd8a19f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/249263/ffd8a19f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/limelight-liverpool-2301060155956?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "71decdb2f0924fa2b6cc7ef585e30f27"
    },
    {
        "room_name": "The Lantern, Liverpool",
        "location": "Bolton St, Liverpool, L3 5AA",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bills Included",
            "Study Area",
            "Lounge",
            "Study Room",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139066/26f5ddb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139064/3b638776.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139066/26f5ddb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139066/26f5ddb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-lantern-liverpool-1802071640404?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "c7c054d9c309440cb252e46f23be69ef"
    },
    {
        "room_name": "Calico, Liverpool",
        "location": "Islington, Liverpool, L3 8LG",
        "details": [
            "26 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema",
            "Bills Included",
            "Game Room",
            "Gym",
            "Lounge",
            "From£163/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144262/debe1ceb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144254/7352a1f2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144262/debe1ceb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144262/debe1ceb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/calico-liverpool-1903075725801?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "81c180e9045a41ccb8fe31a86334a161"
    },
    {
        "room_name": "Cedar House, Liverpool",
        "location": "Cambridge St, Liverpool, L7 7JG",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Laundry Facility",
            "Bike Storage",
            "Wi-Fi",
            "Laundry",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137807/ad442a1a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137806/6b493f6b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137807/ad442a1a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137807/ad442a1a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cedar-house-liverpool-1710239411797?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "e16f954683bc4eeaa2e1e4896ee2519b"
    },
    {
        "room_name": "Benson Yard, Liverpool",
        "location": "Benson St, Liverpool, L1 2ST",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Cinema",
            "Game Room",
            "Lounge",
            "Laundry",
            "Courtyard",
            "From£169/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/261354/240e4af3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/261287/d879d46c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/261354/240e4af3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/261354/240e4af3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/benson-yard-liverpool-2303310251551?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "ad4391d37203414f9812d74f039f6747"
    },
    {
        "room_name": "Horizon Heights, Liverpool",
        "location": "Skelhorne St, Liverpool, L1 1JD",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machines",
            "Bike Storage",
            "Study Area",
            "Wi-Fi",
            "Recycling",
            "From£195/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144103/e4899b67.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144101/fdbd5b88.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144103/e4899b67.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144103/e4899b67.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/horizon-heights-liverpool-1902159623509?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "b5527143fdca48dfba3fff69381a5ec7"
    },
    {
        "room_name": "The Electra, Liverpool",
        "location": "Caledonia St, Liverpool, L7 7ED",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "TV",
            "From£210/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132903/410336a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132900/4e4154d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132903/410336a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132903/410336a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-electra-liverpool-1608304352045?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "eaaf0c14198644029f8e51aed8e27cd8"
    },
    {
        "room_name": "Pembroke Studios, Liverpool",
        "location": "Lower Gill St, Liverpool, L3 5BB",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "CCTV",
            "Bike Storage",
            "Laundry Room",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931545/c4801c28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931539/bbe56482.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931545/c4801c28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931545/c4801c28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/pembroke-studios-liverpool-2312084927246?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "202dcbdebdb445a6b1421868dfbb03b2"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 328 Upper Parliament St, Liverpool L8 7QL",
        "location": "Upper Parliament St, Liverpool, L8 7QL",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Furnished",
            "All Bills Included",
            "Location Benefit",
            "Recycling",
            "Close To Uni",
            "From£102/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943660/2afacc74.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943565/3cbc2923.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943660/2afacc74.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943660/2afacc74.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-upper-parliament-st-liverpool-l8-7ql-2401190419476?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "24077c827dd14f06b0fe8a78c6865ce0"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 85 Halsbury Road, Liverpool L6 6DG",
        "location": "Halsbury Rd, Liverpool, L6 6DG",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Recycling",
            "Central Heating",
            "Vacuum Cleaner",
            "Cleaner",
            "Recycling",
            "From£104/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1181897/e83445e2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1181791/f11552b6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1181897/e83445e2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1181897/e83445e2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-6-bedroom-apartment-85-halsbury-road-liverpool-l6-6dg-2407227460080?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "1a7566bef6144b83bae53b4d63693c10"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 169 Kensington, Liverpool L7 2QX",
        "location": "Kensington, Liverpool, L7 2QX",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Contents Insurance",
            "Smart Locking System",
            "Bills Included",
            "Recycling",
            "From£111/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943399/5d835387.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943331/67659983.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943399/5d835387.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943399/5d835387.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/4-bedroom-apartment-kensington-liverpool-l7-2qx-2401194594680?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "06ae69d2815842de991a357b4f3d70c4"
    },
    {
        "room_name": "Room in a 3 Bedroom Apartment, Flat 3 263 Edge Lane, Liverpool L7 9LB",
        "location": "Edge Ln, Liverpool, L7 9LB",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Fully Furnished",
            "Bills Included",
            "Central Heating",
            "Recycling",
            "Close To Uni",
            "From£111/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943868/8e974511.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943866/14fcc002.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943868/8e974511.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943868/8e974511.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/3-bedroom-apartment-263-edge-lane-liverpool-l7-9lb-2401223140714?ref=search&regionId=55",
        "city": "Liverpool",
        "room_id": "c5766576a8c44be58ca717d93f3305d8"
    },
    {
        "room_name": "iQ Weaver Place, Coventry",
        "location": "Bishop St, Coventry, CV1 1AQ",
        "details": [
            "24 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Games Lounge",
            "Bills Included",
            "TV",
            "Games Room",
            "From£138/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147100/fe98debd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147093/9dd975bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147100/fe98debd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147100/fe98debd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-weaver-place-coventry-1911143678165?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "51c32b0d86d04ed2b71561809ae4967f"
    },
    {
        "room_name": "Paradise Student Village, Coventry",
        "location": "Paradise St, Coventry, CV1 2JU",
        "details": [
            "10 Room Options",
            "5 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Bills Included",
            "Gym",
            "Common Room",
            "From£95/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141374/9bc9edf6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141374/ef19de4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141374/9bc9edf6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141374/9bc9edf6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/paradise-student-village-coventry-1805214644683?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "0148b294e55d4935982aa701f34ca436"
    },
    {
        "room_name": "Collegiate Corporation Village, Coventry",
        "location": "Corporation St, Coventry, CV1 1GU",
        "details": [
            "15 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "From£85/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/284820/f4bbc509.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139944/8ddb7759.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/284820/f4bbc509.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/284820/f4bbc509.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/corporation-village-1804023451082?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "c582aa4f52ed41dfaf6c68d2bdc292b0"
    },
    {
        "room_name": "Canvas Coventry Arundel House, Coventry",
        "location": "Whitefriars Ln, Coventry, CV1 2NA",
        "details": [
            "34 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Furnished",
            "Laundry Room",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148478/664b978c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148478/6d596243.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148478/664b978c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148478/664b978c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-arundel-house-coventry-2001248780824?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "06b9c4f34af04ff092f1687d81948b21"
    },
    {
        "room_name": "Merlin Point, Coventry",
        "location": "Coventry, CV1 2JW",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wi-Fi",
            "Bills Included",
            "Laundry Facility",
            "Courtyard",
            "From£80/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/202056/77adab32.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140020/a531cccd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/202056/77adab32.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/202056/77adab32.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/merlin-point-coventry-1804033596189?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "604d852f2b7742d995367f70c644c686"
    },
    {
        "room_name": "Sky Blue Point, Coventry",
        "location": "West St, Coventry, CV1 5EE",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "Study Area",
            "From£104/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/182777/e9a2a1c7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148826/5d5817b8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/182777/e9a2a1c7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/182777/e9a2a1c7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sky-blue-point-2002124830905?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "826922fcb30b42d389f4df921c829919"
    },
    {
        "room_name": "Greyfriars House, Coventry",
        "location": "Greyfriars Ln, Coventry, CV1 2GW",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Cinema Room",
            "Bills Included",
            "Onsite Gym",
            "From£85/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/255420/d6d97a0d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/255373/1800ab88.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/255420/d6d97a0d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/255420/d6d97a0d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/greyfriars-house-coventry-2302155057905?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "684429c998fc4a1bb635692d985be3df"
    },
    {
        "room_name": "Broad Lane House, Coventry",
        "location": "Broad Ln, Coventry, CV5 7AA",
        "details": [
            "17 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "From£98/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/175631/f5b219a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137435/ea218d97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175631/f5b219a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/175631/f5b219a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/broad-lane-house-coventry-1709145906306?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "94f3555e229e439db1ed7568fef304f0"
    },
    {
        "room_name": "Eden Square, Coventry",
        "location": "Stoney Stanton Rd, Coventry, CV1 4FS",
        "details": [
            "16 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Study Area",
            "Courtyard",
            "Games Room",
            "From£105/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149323/0e780d8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147915/7173c2f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149323/0e780d8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149323/0e780d8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/eden-square-coventry-1912131087660?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "0cfaf4b948874fc4964932f1617582e5"
    },
    {
        "room_name": "Gosford Gate, Coventry",
        "location": "Far Gosford St, Coventry, CV1 5DH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Laundry Facility",
            "Bills Included",
            "Games Area",
            "From£106/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144533/235dc194.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144533/0cfcc816.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144533/235dc194.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144533/235dc194.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/gosford-gate-1904173392365?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "6561323f05b048eaa671347edf26f871"
    },
    {
        "room_name": "Canvas Coventry City Point, Coventry",
        "location": "Gas St, Coventry, CV1 4FY",
        "details": [
            "28 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Bills Included",
            "Security",
            "Gym",
            "Cinema",
            "From£114/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1093613/cfe0a611.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147016/abce089a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1093613/cfe0a611.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1093613/cfe0a611.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-city-point-coventry-1911075325844?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "4500f572d34f4b2fbd54e97aa3d7f952"
    },
    {
        "room_name": "Collegiate Pillar Box, Coventry",
        "location": "Hertford St, Coventry, CV1 1LA",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Game Room",
            "Bills Included",
            "Study Facilities",
            "Storage",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/292424/8fd5754a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139783/7ca4bf3b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/292424/8fd5754a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/292424/8fd5754a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/pillarbox-coventry-1803276666927?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "b06756190c1a49edbe36331f4a29ba54"
    },
    {
        "room_name": "Trinity View, Coventry",
        "location": "Friars' Rd, Coventry, CV1 2LJ",
        "details": [
            "21 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Cinema Room",
            "Laundry Facility",
            "Bills Included",
            "Dryer",
            "Washer",
            "Games Zone",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1260766/4468f941.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1260670/d3dc89d8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1260766/4468f941.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1260766/4468f941.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/trinity-view-coventry-2409058259656?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "466f092a6bff4cb986b3d5adae4c124c"
    },
    {
        "room_name": "Abbey House, Coventry",
        "location": "Manor Rd, Coventry, CV1",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Study Area",
            "Bills Included",
            "Study Space",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139990/428a08af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139990/dc0c658f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139990/428a08af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139990/428a08af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/abbey-house-coventry-1804039830535?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "f1e49ed55a914453bfe8cbb5d72c7b16"
    },
    {
        "room_name": "Infinity, Coventry",
        "location": "Parkside, Coventry, CV1 2NE",
        "details": [
            "14 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Game Room",
            "Cinema",
            "Lounge",
            "Sky Lounge",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931728/8c99f40b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148035/4257bfe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931728/8c99f40b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931728/8c99f40b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/infinity-coventry-1912164480987?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "e10812cd180b4921936dad453a4e97b7"
    },
    {
        "room_name": "Albany Student Village, Coventry",
        "location": "Albany Rd, Coventry, CV5 6JR",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Study Area",
            "Cinema",
            "Pool Table",
            "Cinema Room",
            "From£141/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/208499/858eaf46.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/208499/e56533d8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/208499/858eaf46.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/208499/858eaf46.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/albany-student-village-2202108491124?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "9f78ff336c3749f6b1e64a6b955cf3e1"
    },
    {
        "room_name": "Theatre House, Coventry",
        "location": "Cox St, Coventry, CV1 5EF",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cctv",
            "Laundry Facility",
            "Modern Fitted Kitchenette",
            "City View",
            "From£144/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/264014/15bb50e7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/264014/10cd9f98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/264014/15bb50e7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/264014/15bb50e7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/theatre-house-coventry-2304200931178?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "aac31e36037843988532428db65541a0"
    },
    {
        "room_name": "Sherbourne Student Village, Coventry",
        "location": "Vincent St, Coventry, CV1 3HZ",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Bills Included",
            "Gym",
            "Bike Storage",
            "From£145/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141379/376b4ca3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141379/0762b67a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141379/376b4ca3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141379/376b4ca3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sherbourne-student-village-coventry-1805215728723?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "d10fc18a2c484686b22afa0dfeb19837"
    },
    {
        "room_name": "Julian Court, Coventry",
        "location": "Queen's Rd, Coventry, CV1 3EG",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry",
            "Cycle Storage",
            "Bills Included",
            "Cycle Storage",
            "From£145/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140010/f1e3264e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140010/93d29216.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140010/f1e3264e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140010/f1e3264e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/julian-court-coventry-1804038476918?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "9ef277ff58cf41dda77d354392e1d9de"
    },
    {
        "room_name": "Manor Park Court, Coventry",
        "location": "Park Rd, Coventry, CV1 2LE",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Communal Areas",
            "Bills Included",
            "Courtyard",
            "From£205/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139553/b4e1cfb7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139551/30e58bbd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139553/b4e1cfb7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139553/b4e1cfb7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manor-park-coventry-1803171984029?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "2886ec5b6d5b4e838eb3ab2ae69b2ba9"
    },
    {
        "room_name": "Vita Student Warwick Cannon Park, Coventry",
        "location": "De Montfort Way, Coventry, CV4 7FA",
        "details": [
            "9 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry",
            "Study Area",
            "Courtyard",
            "Movie Room",
            "Breakfast",
            "Bike Rental",
            "From£263/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/199375/cb32ecc9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199374/a653825d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199375/cb32ecc9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/199375/cb32ecc9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-cannon-park-warwick-2111175474759?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "5fcc6fdd13ae4785884018542bb79896"
    },
    {
        "room_name": "Raglan House, Coventry",
        "location": "Raglan St, Coventry, CV1 5QF",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "From£86/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148842/2e2160b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146664/6e0e303b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148842/2e2160b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148842/2e2160b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/raglan-house-coventry-1908217412415?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "18a86a3eb93444aa9a2d4d7be6cdb0ba"
    },
    {
        "room_name": "Gulson Gardens, Coventry",
        "location": "Gulson Rd, Coventry, CV1 2LA",
        "details": [
            "16 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Lounge",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147953/f69a2754.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147952/d51fef03.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147953/f69a2754.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147953/f69a2754.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/gulson-gardens-1912139168536?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "32f994579e94441c835e00fb1a4b316c"
    },
    {
        "room_name": "Urban Student Village, Coventry",
        "location": "Hill St, Coventry, CV1 4AN",
        "details": [
            "7 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Courtyard",
            "Study Area",
            "Bike Storage",
            "From£105/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149208/51803ced.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149207/26aaea40.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149208/51803ced.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149208/51803ced.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/urban-student-village-ringway-house-2003035672212?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "38f5f5f768cd4e2baf620d838c52c1ae"
    },
    {
        "room_name": "Queens Park House, Coventry",
        "location": "Queen's Rd, Coventry, CV1 3GX",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "From£144/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148823/97d8ab69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148820/c83dc518.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148823/97d8ab69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148823/97d8ab69.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/queen-s-park-house-2002125438366?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "e46ae359be9f4f2b8e486a86cdf54eed"
    },
    {
        "room_name": "33 Parkside, Coventry",
        "location": "Parkside, Coventry, CV1 2NE",
        "details": [
            "11 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema Room",
            "Fitness Studio",
            "Study Room",
            "Gym",
            "Courtyard",
            "Study Room",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138620/c17363e4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138620/86582aba.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138620/c17363e4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138620/c17363e4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/33-parkside-coventry-1712275598586?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "d69012cbea964c52ba7483b42a7adfec"
    },
    {
        "room_name": "City Village, Coventry",
        "location": "Bond St, Coventry, CV1 4FQ",
        "details": [
            "13 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Smart Tv",
            "From£168/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/157764/199930a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134985/111e4291.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/157764/199930a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/157764/199930a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/city-village-coventry-1706106092665?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "1f5dc440bc0846d2b4d4b0e7d3c9bd06"
    },
    {
        "room_name": "The Residence, Coventry",
        "location": "St Columba's Cl, Coventry, CV1 4BX",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Cinema Room",
            "Laundry Room",
            "Gym",
            "Ping Pong",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/199711/34fc9430.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143649/938fb513.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199711/34fc9430.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/199711/34fc9430.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-residence-coventry-1812088490966?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "dd43b99a5bf64c50b9ea5ba50d66474d"
    },
    {
        "room_name": "The Union, Leamington Spa",
        "location": "Althorpe St, Leamington Spa, CV31 2AU",
        "details": [
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Communal TV",
            "Pool Table",
            "Courtyard",
            "Pool Table",
            "Communal TV",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/257275/d73baca7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137276/0057536c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/257275/d73baca7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/257275/d73baca7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-union-leamington-1709078923444?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "74cf91bd9b334ae981765ca06124d74f"
    },
    {
        "room_name": "iQ Longwood Place, Warwick",
        "location": "Longwood Cl, Coventry, CV4 8HZ",
        "details": [
            "19 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Free WiFi",
            "Study Area",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1275080/b7faaeb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1274966/c6912930.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1275080/b7faaeb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1275080/b7faaeb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-longwood-place-warwick-2409127141811?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "1e2655a152cc426cb70d250a5c7e9503"
    },
    {
        "room_name": "The Oaks, Warwick",
        "location": "Westwood Way, Coventry, CV4 8JB",
        "details": [
            "10 Room Options",
            "4 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Aircon",
            "From£289/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/197309/674d8901.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147884/51f2d9dd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/197309/674d8901.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/197309/674d8901.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-oaks-1912128520660?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "51ab6e6d51304d9eaeda788c4c1ae395"
    },
    {
        "room_name": "Mercia Lodge, Coventry",
        "location": "Broadgate, Coventry, CV1 1NB",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cinema Room",
            "Study Spaces",
            "From£90/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148960/5e85e476.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148960/8b581896.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148960/5e85e476.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148960/5e85e476.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mercia-lodge-2002149439371?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "23af7f3a84164c1498d0234f3de4cea5"
    },
    {
        "room_name": "Collegiate Market Way, Coventry",
        "location": "Market Way, Coventry, CV1 1DL",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Common Room",
            "Bills Included",
            "Common Room",
            "On-Site Gym",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/172994/4c1922a6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139920/9017d075.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/172994/4c1922a6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/172994/4c1922a6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/market-way-1804026340355?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "18eb4866d09042339815deaaf3df17c3"
    },
    {
        "room_name": "Code Harper Road, Coventry",
        "location": "Harper Rd, Coventry, CV1 2PU",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bike Storage",
            "Communal Spaces",
            "Group Study Space",
            "Free Social Events",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1090718/0904049c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1090718/ee5b9814.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1090718/0904049c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1090718/0904049c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/code-harper-road-coventry-2405270909955?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "3d16b5b6987745efa8f2e403672d8d25"
    },
    {
        "room_name": "Collegiate Burges House, Coventry",
        "location": "Trinity St, Coventry, CV1 1FJ",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Private Fitness Suite",
            "24/7 Concierge",
            "Bills Included",
            "Lounge",
            "Laundry",
            "Study Room",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142743/95623605.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142743/741190eb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142743/95623605.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142743/95623605.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/burges-house-coventry-1809208021113?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "090e79f0ddae4af6af32377b7c8f041d"
    },
    {
        "room_name": "Phoenix Studios, Coventry",
        "location": "Villiers St, Coventry, CV2 4HP",
        "details": [
            "3 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Outdoor Area",
            "Washing Machine",
            "CCTV",
            "Paid Parking",
            "Bike Storage",
            "Laundry Room",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/220965/063283d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/220965/35930a9a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/220965/063283d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/220965/063283d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/phoenix-studios-coventry-2206218808199?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "dcad7707ab414818b164ba1c236267ef"
    },
    {
        "room_name": "Code Fairfax Street, Coventry",
        "location": "Fairfax St, Coventry, CV1 5RQ",
        "details": [
            "15 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Study Spaces",
            "Outdoor Space",
            "Communal Kitchen",
            "Free Social Events",
            "From£144/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1094345/867d42b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1090728/0b7b5d99.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1094345/867d42b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1094345/867d42b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/code-fairfax-street-2405275295127?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "852e4dd216864835a6217a9bef9b407f"
    },
    {
        "room_name": "Singer Hall, Coventry",
        "location": "Canterbury St, Coventry, CV1 5NR",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Study Area",
            "Laundry Facility",
            "Bills Included",
            "Games Room",
            "Desk Space",
            "Common Room",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/290345/88ba6af9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/290344/84e2b88c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/290345/88ba6af9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/290345/88ba6af9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/singer-hall-coventry-2308153926879?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "56e03234bf074f1a98e93d65114259b0"
    },
    {
        "room_name": "Vita Student Copper Towers, Coventry",
        "location": "Warwick Rd, Coventry, CV1 2EZ",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "CCTV",
            "Cinema",
            "Gym",
            "Courtyard",
            "Movie Room",
            "Breakfast",
            "From£207/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/240064/a5ce8123.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/240033/146ff6a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/240064/a5ce8123.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/240064/a5ce8123.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/copper-towers-coventry-2211115874866?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "9596227adf7f47ea88c004b914be0e3e"
    },
    {
        "room_name": "Canvas City Club, Coventry",
        "location": "Gas St, Coventry, CV1 4GJ",
        "details": [
            "4 Room Options",
            "1 Offer",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wi-Fi",
            "Gym",
            "Bills Included",
            "Gym",
            "Wi-Fi",
            "On-Site & Virtual Events",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147800/b2795f2e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147799/908714b5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147800/b2795f2e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147800/b2795f2e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-city-club-coventry-1912099901545?ref=search&regionId=70",
        "city": "Coventry",
        "room_id": "9bb403203289472e88c728d488ecc3e9"
    },
    {
        "room_name": "iQ Brocco, Sheffield",
        "location": "Edward St, Sheffield, S3 7GB",
        "details": [
            "8 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Onsite Cycle Storage",
            "Bills Included",
            "TV",
            "Study Area",
            "From£108/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/197404/7092b3b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143553/3f351366.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/197404/7092b3b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/197404/7092b3b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-brocco-sheffield-1811299144584?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "6539f8ad619b4514ac6aadd7a8104e2f"
    },
    {
        "room_name": "Sheffield 3, Sheffield",
        "location": "Hoyle St, Sheffield, S3 7LG",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Wifi",
            "Laundry Facility",
            "Aircon",
            "Elevator",
            "From£90/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/923070/36b2294c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134036/06827aab.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/923070/36b2294c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/923070/36b2294c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sheffield-3-sheffield-1608308958989?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "54b5857fc6814e038106c3dcdc45fcd5"
    },
    {
        "room_name": "iQ Century Square, Sheffield",
        "location": "Radford St, Sheffield, S3 7AZ",
        "details": [
            "15 Room Options",
            "5 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Study Area",
            "Cinema",
            "TV",
            "Free Gym",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143586/126c2ef9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143585/b330583a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143586/126c2ef9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143586/126c2ef9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-century-square-sheffield-1811309594809?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "68fea556e7da497b93195d5acb071a02"
    },
    {
        "room_name": "iQ Knight House, Sheffield",
        "location": "St Georges Cl, Sheffield, S3 7EA",
        "details": [
            "16 Room Options",
            "5 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Cinema",
            "Game Room",
            "TV",
            "Games Room",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143562/b2e911c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143559/4dbb986d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143562/b2e911c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143562/b2e911c2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-knight-house-sheffield-1811294656428?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "02af66abe7664894a0d2531a36bf9955"
    },
    {
        "room_name": "iQ Fenton House, Sheffield",
        "location": "Wellington St, Sheffield, S1 4HL",
        "details": [
            "12 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Social Spaces",
            "Bills Included",
            "TV",
            "Games Room",
            "From£118/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147141/a7c882b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143567/26543b77.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147141/a7c882b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147141/a7c882b4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-fenton-house-sheffield-1811303250711?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "bc5524967ea742839116aee02d57b8ae"
    },
    {
        "room_name": "iQ Steel, Sheffield",
        "location": "Pitt St, Sheffield, S1 4DQ",
        "details": [
            "11 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Free Contents Insurance",
            "Laundry Facility",
            "Study Area",
            "From£124/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/258003/cd0f907b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143576/8f1555ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/258003/cd0f907b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/258003/cd0f907b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-steel-sheffield-1811302006056?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "04a656becfee489d8ff25e50d28203a2"
    },
    {
        "room_name": "Hollis Croft, Sheffield",
        "location": "Hollis Croft, Sheffield, S1 4NQ",
        "details": [
            "18 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Bills Included",
            "From£105/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144332/b3e2a3f2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144332/4b19c858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144332/b3e2a3f2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144332/b3e2a3f2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/hollis-croft-sheffield-1903225773137?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "2511c070fd944346950f37ca01abe6b4"
    },
    {
        "room_name": "Central Place, Sheffield",
        "location": "Edward St, Sheffield, S3 7BY",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Onsite Laundry Facilities",
            "Yoga And Fitness Studio",
            "From£95/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149330/e5a1e1cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149330/ffa7c079.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149330/e5a1e1cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149330/e5a1e1cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/central-place-2003111791844?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "e64bcc65cac3450fad16540d3298f879"
    },
    {
        "room_name": "Cosmos, Sheffield",
        "location": "Moore St, Sheffield",
        "details": [
            "18 Room Options",
            "5 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/250519/cccf97bb.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183886/87eaad5f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/250519/cccf97bb.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/250519/cccf97bb.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cosmos-sheffield-2105227368838?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "1263bc02214f4c3695f9382ab0d0f29c"
    },
    {
        "room_name": "Central Quay, Sheffield",
        "location": "Alma St, Sheffield, S3 8RA",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Bills Included",
            "TV Area",
            "Games Area",
            "From£84/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137764/95629968.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137764/ccb4a8d2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137764/95629968.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137764/95629968.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/central-quay-sheffield-1710179261535?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "5ca90eb501484f94a731b42ecc23b5e5"
    },
    {
        "room_name": "The Trigon, Sheffield",
        "location": "Shoreham St, Sheffield, S1 4RA",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wifi",
            "Bills Included",
            "24/7 Security",
            "TV",
            "Courtyard",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1134302/e4a8c915.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1133907/eca25d52.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1134302/e4a8c915.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1134302/e4a8c915.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-trigon-sheffield-2406266237335?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "ed9bc43a3adb42438093595bb674a72f"
    },
    {
        "room_name": "Charlotte Court, Sheffield",
        "location": "Charlotte Rd, Sheffield, S2 4EQ",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Laundry Facility",
            "Study Area",
            "Bike Storage",
            "From£95/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/284731/d0fb8c5a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/284731/45ae9fb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/284731/d0fb8c5a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/284731/d0fb8c5a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/charlotte-court-sheffield-2307201938478?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "3c22792624f6451d9ab0b6e2700b1885"
    },
    {
        "room_name": "Aspect 3 Apartments, Sheffield",
        "location": "Edward St, Sheffield, S3 7GE",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "All Bills Included",
            "Communal Laundry",
            "WiFi",
            "Xbox",
            "Recycling",
            "From£92/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139342/561c623a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139342/45e12264.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139342/561c623a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139342/561c623a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/aspect-3-apartments-sheffield-1803071860318?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "f20e2b46efc24cd78c7b44463ec39c56"
    },
    {
        "room_name": "Nebula, Sheffield",
        "location": "Egerton St, Sheffield, S1 4JX",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Bike Storage",
            "Bills Included",
            "Gym",
            "From£95/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/183562/b90868d0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183558/cba17840.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183562/b90868d0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/183562/b90868d0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/nebula-sheffield-2105128997701?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "51ac3402cc4947bbb4223f7a3b261973"
    },
    {
        "room_name": "Sharman Court, Sheffield",
        "location": "Broad Ln, Sheffield, S1",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Games Room",
            "Bills Included",
            "Study Area",
            "TV Areas",
            "Pool Tables",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/170002/1e544ffb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170002/8df433ab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170002/1e544ffb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/170002/1e544ffb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/capitol-sharman-court-2012028260369?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "c11e5e4abc9749ea95de634281a44df6"
    },
    {
        "room_name": "The Albion Student Accommodation, Sheffield",
        "location": "Brook Dr, Sheffield, S3 7HN",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Student Complex",
            "On Street Parking",
            "Converted Modern Pub",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/204644/80b94565.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/204643/beba4e64.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/204644/80b94565.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/204644/80b94565.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-albion-student-accommodation-sheffield-2112249288864?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "6049ece1b5a04a098a3d6eb5001a6dd1"
    },
    {
        "room_name": "Solly Houses @ Central Place, Sheffield",
        "location": "Edward St, Sheffield, S3",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Safety And Security",
            "Laundry Facility",
            "BBQ",
            "Gym",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141362/d60bace1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141362/16fef4ed.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141362/d60bace1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141362/d60bace1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/solly-house-sheffield-1805210621408?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "39e0751d00db4a468a7f04740024111c"
    },
    {
        "room_name": "Steelworks, Sheffield",
        "location": "Rockingham St, Sheffield, S1 4WB",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "Gym",
            "Cinema",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/169664/0812251c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/169647/fbb47b16.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/169664/0812251c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/169664/0812251c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/steelworks-2012015024163?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "4f7922ca89854133b27cd270395b9ae1"
    },
    {
        "room_name": "Portland Tower Student Accommodation, Sheffield",
        "location": "Portland Ln, Sheffield, S1 4DG",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Secure Bike Store",
            "Free Superfast WIFI",
            "Bills Included",
            "Secure Bike Store",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/204634/55e6b468.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/204627/78b1db24.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/204634/55e6b468.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/204634/55e6b468.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/portland-tower-2112243359344?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "01409ca12d0b4fcd9e4ee97e262df09b"
    },
    {
        "room_name": "Portobello Point, Sheffield",
        "location": "Portobello St, Sheffield, S1 4AA",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Bike Storage",
            "On-Site Gym",
            "From£100/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140989/432024c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140989/474b2376.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140989/432024c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140989/432024c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/portobello-point-sheffield-1805094343500?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "9cc1de00e1584c5094d071c80d6e3edf"
    },
    {
        "room_name": "Portobello Point, Sheffield",
        "location": "Portobello St, Sheffield, S1 4AA",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Bike Storage",
            "On-Site Gym",
            "From£100/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140989/432024c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140989/474b2376.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140989/432024c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140989/432024c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/portobello-point-sheffield-1805094343500?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "34d622f632a144e98ff927a92cf0efe2"
    },
    {
        "room_name": "Cornerhouse, Sheffield",
        "location": "Edward St, Sheffield, S3 7HX",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "Bills Included",
            "From£101/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133982/8a014dea.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133982/84e8631d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133982/8a014dea.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133982/8a014dea.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cornerhouse-sheffield-1608303588696?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "9b7c7cb86cd44ca5b5cde1c2123b68f1"
    },
    {
        "room_name": "Stratus, Sheffield",
        "location": "Sharrow Vale Rd, Sheffield, S11 8ZE",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Bills Included",
            "Laundry Facility",
            "TV (Communal)",
            "From£105/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/292577/3e546096.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/292576/b5643858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/292577/3e546096.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/292577/3e546096.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/stratus-sheffield-2308258766239?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "7f944ab76a254af785426280fa854423"
    },
    {
        "room_name": "Bailey Fields, Sheffield",
        "location": "Rockingham St, Sheffield, S1",
        "details": [
            "22 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Social Space",
            "Dining Room",
            "Gym",
            "Wifi",
            "From£114/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139099/eec06875.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139099/dea5e845.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139099/eec06875.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139099/eec06875.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bailey-fields-sheffield-1802089140424?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "55cf7e012d1b439aba943f13e91c7465"
    },
    {
        "room_name": "St Mary's House, Sheffield",
        "location": "London Rd, Sheffield, S2 4LA",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Games Room",
            "From£115/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/233330/c08eb064.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/233324/4a1b325e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/233330/c08eb064.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/233330/c08eb064.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-mary-s-house-sheffield-2209201422614?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "801aa151a08244438b3b19cd41aa6718"
    },
    {
        "room_name": "Page Apartments, Sheffield",
        "location": "Sheffield, S3 7BY",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "BBQ",
            "Elevators",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141348/71f8c0b1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141347/1a8aa1be.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141348/71f8c0b1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141348/71f8c0b1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/page-apartments-1805195120154?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "c4d7fe1442734a6391e30b26cf69d242"
    },
    {
        "room_name": "Sugar Cube, Sheffield",
        "location": "Fitzalan Sq, Sheffield, S1 2AZ",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cinema Room",
            "Indoor Garden",
            "Cafe",
            "Games",
            "Wi-Fi",
            "Events",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144290/f318424a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144288/cb26afa6.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144290/f318424a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144290/f318424a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sugar-cube-sheffield-1903081835774?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "3d6b0519890545fcad92079d803ee00b"
    },
    {
        "room_name": "Collegiate The Moor, Sheffield",
        "location": "Holy Grn, Sheffield, S1 4JA",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Common Room",
            "Laundry Facility",
            "Common Room",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/173033/385ad069.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140021/dc68be97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/173033/385ad069.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/173033/385ad069.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-moor-sheffield-1804037986349?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "462dd3cd68df45828ab21f21a10674c5"
    },
    {
        "room_name": "Nurtur House, Sheffield",
        "location": "Allen St, Sheffield, S3 7TA",
        "details": [
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Rooftop Terrace",
            "Resident'S Gym",
            "Private Study Rooms",
            "Calm Room",
            "Cycle Storage",
            "From£140/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149669/fe73d15d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149664/509b011d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149669/fe73d15d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149669/fe73d15d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/nurtur-house-2003280092405?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "7662b0a7c99946cb852d5e0f307eaa0f"
    },
    {
        "room_name": "Sovereign Newbank, Sheffield",
        "location": "Queen St, Sheffield, S1 2DW",
        "details": [
            "15 Room Options",
            "4 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Room",
            "Gym",
            "Courtyard",
            "Free WiFi",
            "From£148/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/941596/efe9c746.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/941517/94bd13c8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/941596/efe9c746.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/941596/efe9c746.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sovereign-newbank-sheffield-2401167385117?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "f2a1e18c2d8e4649b919e9af418c97dd"
    },
    {
        "room_name": "Laycock Studios, Sheffield",
        "location": "Suffolk Rd, Sheffield, S2 4BU",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Fan",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/136809/94de1989.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136809/fcec90f6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136809/94de1989.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/136809/94de1989.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/laycock-studios-sheffield-1708039952915?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "1061bdb3377d49b7931808f2971c8b03"
    },
    {
        "room_name": "LIV Student Sheffield, Sheffield",
        "location": "Ecclesall Rd, Sheffield, S11 8HY",
        "details": [
            "17 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Study Area",
            "Cinema",
            "Bills Included",
            "Gym",
            "From£176/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/198512/9326745f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144535/3e9d070a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/198512/9326745f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/198512/9326745f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/liv-student-sheffield-1904186659585?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "87a201760beb4b869146f2948560d27e"
    },
    {
        "room_name": "Vita Student Telephone House, Sheffield",
        "location": "Charter Sq, Sheffield, S1 1BA",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Swimming Pool",
            "Laundry Facility",
            "Gym",
            "Movie Room",
            "Bike Store",
            "From£182/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147115/b5055236.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134109/7ba338a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147115/b5055236.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147115/b5055236.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-sheffield-telephone-house-sheffield-1608301949714?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "48c7122128e948fc91bbe8d4667d500b"
    },
    {
        "room_name": "Kings Chambers, Sheffield",
        "location": "King St, Sheffield, S3 8LF",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Bills Included",
            "Lift",
            "Weekly Cleaning",
            "Lift",
            "Weekly Cleaning",
            "From£85/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/958479/626f9593.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958475/8e476e0b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958479/626f9593.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/958479/626f9593.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kings-chambers-sheffield-2402169285466?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "0d76cda84d3a41ee9e24875667c67792"
    },
    {
        "room_name": "Exchange Works, Sheffield",
        "location": "Arundel St, Sheffield, S1 4RE",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facilities",
            "Courtyard",
            "Study Area",
            "TV",
            "Lounge",
            "From£142/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138056/08d4ce2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137767/b5652e43.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138056/08d4ce2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138056/08d4ce2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/exchange-works-sheffield-1710178778291?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "e388ebed14174fc2bf3cdff031de0438"
    },
    {
        "room_name": "Truro Sheffield, Sheffield",
        "location": "Matilda St, Sheffield, S1 4QG",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Bills Included",
            "Social Area",
            "Laundry",
            "Courtyard",
            "From£72/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148025/c2f8a5b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148011/09da253d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148025/c2f8a5b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148025/c2f8a5b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/truro-sheffield-1912141598730?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "df8e13d7db0947bfbff60e3af1fdc112"
    },
    {
        "room_name": "Westhill Hall, Sheffield",
        "location": "Eldon St, Sheffield, S1 4NN",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Laundry Facility",
            "CCTV",
            "Laundry",
            "Recycling",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/209383/158ba8e6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/209380/fff1ee76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/209383/158ba8e6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/209383/158ba8e6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/westhill-hall-sheffield-2202211095247?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "181ddb8ff8184193a259779ea9b2e12d"
    },
    {
        "room_name": "The Forge, Sheffield",
        "location": "Boston St, Sheffield, S2 4QG",
        "details": [
            "8 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bike Storage",
            "Laundry Facility",
            "Recycling",
            "Courtyard",
            "Table Tennis",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137774/54240a7e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137774/025e8c77.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137774/54240a7e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137774/54240a7e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-forge-sheffield-1710175035428?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "94dbad404b2c46c193444baa5cdcddee"
    },
    {
        "room_name": "Straits Manor, Sheffield",
        "location": "West St, Sheffield, S1 4FD",
        "details": [
            "9 Room Options",
            "4 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Cleaner",
            "Pool Table",
            "Study Room",
            "From£110/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143641/e7ac9a2b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143641/4b21b380.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143641/e7ac9a2b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143641/e7ac9a2b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/straits-manor-sheffield-1812083208033?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "1223507c5d1243808b1b5a4b1cbb1229"
    },
    {
        "room_name": "Westbar House, Sheffield",
        "location": "Furnace Hill, Sheffield, S3 7BZ",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "Hob",
            "Double Bed",
            "Ensuite Bathroom",
            "Gym",
            "Courtyard",
            "Study Room",
            "Communal TV",
            "Bike Storage",
            "From£115/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/134028/fad07b29.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134028/62b23951.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134028/fad07b29.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/134028/fad07b29.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/westbar-house-sheffield-1608303963797?ref=search&regionId=37",
        "city": "Sheffield",
        "room_id": "1262167ea86d45c99fef544017ff37f4"
    },
    {
        "room_name": "Oxley Residence, Leeds",
        "location": "Leeds, LS16 8HL",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym On Site",
            "Fitness Room",
            "Study Room",
            "Laundry",
            "BBQ Area",
            "From£119/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/206481/7f82fa1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/206481/348b4675.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/206481/7f82fa1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/206481/7f82fa1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/oxley-residence-2201173259776?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "625c0e53f40447cb8335a56a9ad1a399"
    },
    {
        "room_name": "iQ Altus House, Leeds",
        "location": "Tower House St, Leeds, LS2 8GH",
        "details": [
            "22 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Cinema Room",
            "Laundry Facility",
            "TV",
            "Games Room",
            "Study Area",
            "From£223/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/181457/27549e1e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/181427/d079e689.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/181457/27549e1e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/181457/27549e1e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-altus-house-2104015422909?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "964e4c63bb1b456ca3ce5635679667dc"
    },
    {
        "room_name": "Vita Student St Albans, Leeds",
        "location": "Cross Belgrave St, Leeds, LS2 8JP",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Cinema",
            "Gym",
            "Bike Store",
            "Movie Room",
            "Free Events",
            "From£269/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143903/692ed3f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143900/ab11ef1a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143903/692ed3f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143903/692ed3f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-st-albans-leeds-1901154054247?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "e00e36add2814462b7cf5c94f3159cc5"
    },
    {
        "room_name": "Vita Student Portland Crescent, Leeds",
        "location": "Portland Cres, Leeds, LS1 3AY",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Swimming Pool",
            "Laundry Facility",
            "Bike Store",
            "Bike Rental",
            "From£265/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146933/e7ffb85c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146928/1c566569.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146933/e7ffb85c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146933/e7ffb85c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-portland-crescent-leeds-1911046272831?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "6178122a10764d40b46a578da12709d6"
    },
    {
        "room_name": "Mary Morris House, Leeds",
        "location": "Shire Oak Rd, Leeds, LS6 2DE",
        "details": [
            "7 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Internet",
            "Bills Included",
            "CCTV",
            "Snug Sofas",
            "Outdoor Area",
            "TV Communal",
            "From£95/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/266930/c7a32e16.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266812/fdd6f2d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266930/c7a32e16.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/266930/c7a32e16.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mary-morris-house-leeds-2305097208388?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "7f88c4e532f844ed9d02d7aa2f076752"
    },
    {
        "room_name": "Triangle, Leeds",
        "location": "Burley Rd, Leeds, LS3 1JG",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Tv",
            "Bills Included",
            "Comfy Sofas",
            "Car Parking",
            "Social Hubs",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/188502/96cc205b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188484/e9a513a6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188502/96cc205b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/188502/96cc205b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/triangle-leeds-2108090880649?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "4ccaee6472ea4e85947706ac7b9d25ac"
    },
    {
        "room_name": "Trinity Hall, Leeds",
        "location": "Holborn Approach, Leeds, LS6 2FD",
        "details": [
            "15 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Games Room",
            "On-Site Laundry",
            "Garden",
            "Cinema Room",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132637/c9426563.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132637/7fd6e5a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132637/c9426563.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132637/c9426563.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/trinity-hall-leeds-1608306977557?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "9825a866b5254e5395b12a8ae37a748d"
    },
    {
        "room_name": "The Leather Works, Leeds",
        "location": "Meanwood Rd, Leeds, LS7 2DZ",
        "details": [
            "22 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bills Included",
            "Semester Stay Available",
            "Cinema",
            "Bike Hire",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/945984/8d1b3eac.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218599/81208a10.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/945984/8d1b3eac.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/945984/8d1b3eac.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-leather-works-leeds-2205196802695?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "2e8fc0d7bdd64dbab445baaf93cc72d5"
    },
    {
        "room_name": "Woodhouse Flats, Leeds",
        "location": "St Mark's St, Leeds, LS2 9EP",
        "details": [
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "On Site Maintenance",
            "Bike Storage",
            "Hospitality Team",
            "Desk Space",
            "Bike Storage",
            "TV (Communal)",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/221684/c3e8f46f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221683/6a0031b9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221684/c3e8f46f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/221684/c3e8f46f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/woodhouse-leeds-2206300787703?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "3db5e7a3f6a4469cb5a50236ba9eb0ac"
    },
    {
        "room_name": "Austin Hall, Leeds",
        "location": "Servia Rd, Leeds, LS7 1NJ",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Laundry Facility",
            "Bills Included",
            "Recycling",
            "Desk Space",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/283736/c25e9fb2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/283736/87087c53.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/283736/c25e9fb2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/283736/c25e9fb2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/austin-hall-leeds-2307144414510?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "fb6cfa3694274760b78f6d14fb15986e"
    },
    {
        "room_name": "Burley Road, Leeds",
        "location": "Burley Rd, Leeds, LS3 1LN",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bike Storage",
            "Bills Included",
            "Laundry Facility",
            "Free Wifi",
            "Furniture",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140828/c8bdde56.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140827/10f16918.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140828/c8bdde56.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140828/c8bdde56.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/burley-road-leeds-1805042199784?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "bd3449c1d4f043258be40e1e1c1cf132"
    },
    {
        "room_name": "Triangle@Iconinc, Leeds",
        "location": "Westfield Rd, Leeds, LS3 1DH",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "Sauna",
            "Events",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148721/6a757d59.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148721/914b6756.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148721/6a757d59.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148721/6a757d59.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/triangle-iconinc-leeds-2002073702505?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "53b8ad3142f6446ead90ffbdab99df41"
    },
    {
        "room_name": "Asquith House, Leeds",
        "location": "Servia Rd, Leeds, LS7 1NJ",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Game Room",
            "Lounge",
            "Recycling",
            "Desk Space",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/283775/00801570.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/283775/0190f09a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/283775/00801570.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/283775/00801570.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/asquith-house-leeds-2307144853570?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "853d2fcb987144faa6fed097661d7580"
    },
    {
        "room_name": "Tribeka, Leeds",
        "location": "Cookridge St, Leeds, LS2 3AW",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Game Room",
            "Games Area",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/960557/394ce730.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/960522/3f3b9bad.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/960557/394ce730.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/960557/394ce730.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/tribeka-leeds-2402199728654?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "43fc7937df54408ba05495934ec0575f"
    },
    {
        "room_name": "The Glassworks, Leeds",
        "location": "Cardigan Rd, Leeds, LS6 1FN",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Parking",
            "Laundry Facility",
            "Bills Included",
            "Sauna",
            "From£169/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/175825/ee164ede.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140940/83c07857.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/175825/ee164ede.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/175825/ee164ede.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-glassworks-leeds-1805076749228?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "88d025ff76c040d69afd083b2bc8f3e6"
    },
    {
        "room_name": "ASA Briggs House, Leeds",
        "location": "St John's Rd, Leeds, LS3 1FF",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Flexible Tenancy",
            "January 2021 Move-In",
            "TV Areas",
            "Pool Tables",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132628/0770b186.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132626/200454e6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132628/0770b186.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132628/0770b186.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/asa-briggs-house-leeds-1608307761777?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "e5ad44a9e396448a8595bc25e88e5ae3"
    },
    {
        "room_name": "Study Inn Brotherton House, Leeds",
        "location": "Grace St, Leeds, LS1 2FN",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Spa",
            "Bowling Lanes",
            "Yoga Room",
            "Concierge",
            "Onsite Gym",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200424/3037d461.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200424/b180f533.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200424/3037d461.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200424/3037d461.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/study-inn-brotherton-house-leeds-2111248297362?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "125bb5ef0fb0481ba59b74903ac6e585"
    },
    {
        "room_name": "iQ Marsden House, Leeds",
        "location": "Burley Rd, Leeds, LS3 1HY",
        "details": [
            "5 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "Laundry Facility",
            "TV",
            "Garden",
            "Free Gym",
            "From£186/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143428/f9caa022.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143426/ce50e70a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143428/f9caa022.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143428/f9caa022.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-marsden-house-leeds-1811262834266?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "95dc0a45aeac41a4ae76ba4296bf8c09"
    },
    {
        "room_name": "Leonardo, Leeds",
        "location": "Rossington St, Leeds, LS2 8HD",
        "details": [
            "18 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Free Gym",
            "Social Spaces",
            "From£189/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/261117/f424d832.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/261075/e515145f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/261117/f424d832.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/261117/f424d832.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/leonardo-leed-2303308345406?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "af5d07d318d343e58efd33b5e940832d"
    },
    {
        "room_name": "The Foundry, Leeds",
        "location": "Cavendish St, Leeds, LS3 1BN",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Cinema",
            "Laundry Facility",
            "Kitchen",
            "Smart TV",
            "Games Room",
            "From£195/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/196968/fb0e5ebb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139635/6d84c374.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/196968/fb0e5ebb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/196968/fb0e5ebb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-foundry-leeds-1803221119503?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "d911ce69297541eea5b4ad4968ec61dc"
    },
    {
        "room_name": "Union Student Living, Leeds",
        "location": "Rutland Mount, Leeds, LS3 1EF",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Sofa Bed",
            "From£210/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/188835/1c1a1254.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188832/21723b97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188835/1c1a1254.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/188835/1c1a1254.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/hop-apartments-2108179424353?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "c1e7c2434dc54e449d10fd185e2901ca"
    },
    {
        "room_name": "Briggate Studios, Leeds",
        "location": "Briggate, Leeds, LS1 6AA",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Study Area",
            "Game Room",
            "From£219/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/266637/19f58497.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266628/ca898fac.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266637/19f58497.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/266637/19f58497.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/briggate-studios-leeds-2305061579275?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "9b359cb7fce947b9b1479d82da7a4d8f"
    },
    {
        "room_name": "Trapezium, Leeds",
        "location": "Park Ln, Leeds",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Cinema",
            "Ensuite",
            "Laundry",
            "From£235/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200937/2f09219f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200937/80ef3802.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200937/2f09219f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200937/2f09219f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/trapezium-2111264269573?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "15feb850a9614638b1aedfa8184b249a"
    },
    {
        "room_name": "The Edge, Leeds",
        "location": "Westfield Rd, Leeds, LS3 1DH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Sauna",
            "From£239/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140953/a97acf28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140953/e28345fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140953/a97acf28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140953/a97acf28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-edge-leeds-1805073214052?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "2f379c05adb8422c9b97e5e34c4f777b"
    },
    {
        "room_name": "Sycamore House, Leeds",
        "location": "Woodhouse Square, Leeds, LS3 1BP",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "FREE On-Site Gym",
            "Secure Cycle Store",
            "Study Space",
            "Free On-Site Gym",
            "From£258/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144440/d7152834.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144433/81641b1d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144440/d7152834.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144440/d7152834.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sycamore-house-leeds-1904116486965?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "b3aca1538d134439b82e384701c13b2d"
    },
    {
        "room_name": "iQ Leeds, Leeds",
        "location": "Cross Chancellor St, Leeds, LS6 2TL",
        "details": [
            "14 Room Options",
            "5 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/176178/9b88a18f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132618/40693eb3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/176178/9b88a18f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/176178/9b88a18f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-leeds-1608307643472?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "9b358ba6856142f59b59c1f58403830a"
    },
    {
        "room_name": "The Priory, Leeds",
        "location": "Springfield Mount, Leeds, LS2 9NG",
        "details": [
            "12 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Communal TV",
            "Bike Storage",
            "Recycling",
            "Courtyard",
            "Games Room",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/213268/4d2cab75.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142277/5c99813c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/213268/4d2cab75.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/213268/4d2cab75.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-priory-leeds-1806204119482?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "d8c72ec02d814826a88e0cd87bc24dbe"
    },
    {
        "room_name": "The Refinery, Leeds",
        "location": "Bingley St, Leeds, LS3 1LX",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Study Area",
            "Lounge",
            "Laundry",
            "From£169/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/186390/ac500a83.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147990/d24c9335.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/186390/ac500a83.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/186390/ac500a83.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-refinery-1912142448075?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "63db1093d8904ce8990fd2f7d7866542"
    },
    {
        "room_name": "Sky Plaza, Leeds",
        "location": "Clay Pit Ln, Leeds, LS2 8NW",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "From£176/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142310/db30da92.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142292/6490b265.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142310/db30da92.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142310/db30da92.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sky-plaza-leeds-1806201208330?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "5e79b66813454d7aab1e585bd286b3f3"
    },
    {
        "room_name": "The Plaza, Leeds",
        "location": "Clay Pit Ln, Leeds, LS2 8AR",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry",
            "Vending Machine",
            "Gym",
            "Laundry",
            "Recycling",
            "From£184/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142327/44479cc5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142326/9f9ac593.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142327/44479cc5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142327/44479cc5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-plaza-leeds-1806226064522?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "12aef3f0cdec43b3aeebdb363cad0e46"
    },
    {
        "room_name": "Cityside, Leeds",
        "location": "Calverley St, Leeds, LS1 3EY",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Laundry Facility",
            "Lounge",
            "From£350/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132646/19a38d28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132646/667163f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132646/19a38d28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132646/19a38d28.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cityside-leeds-1608301074487?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "e952cd9a8f74437bacbbfee9ead0ff8e"
    },
    {
        "room_name": "iQ Arkwright House, Bradford",
        "location": "Tumbling Hill St, Bradford, BD7 1DB",
        "details": [
            "11 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Free Gym",
            "From£92/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143347/3e984904.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143340/b89bba57.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143347/3e984904.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143347/3e984904.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-arkwright-house-bradford-1811232809194?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "78784e20b85448f38912798918b5269b"
    },
    {
        "room_name": "Longside House, Bradford",
        "location": "Longside Ln, Bradford, BD7 1SA",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Cinema",
            "Bike Storage",
            "WiFi",
            "Recycling",
            "From£75/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146251/5cc265f8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146249/567bb463.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146251/5cc265f8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146251/5cc265f8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/longside-house-bradford-1907093898750?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "720574adfaf2401f80b77d03b54769e3"
    },
    {
        "room_name": "Horton House, Bradford",
        "location": "Great Horton Rd, Bradford, BD7 1QG",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Laundry Facility",
            "Washing Machine",
            "Gym",
            "Wifi",
            "From£90/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146247/6daf8d86.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146243/68b7b20e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146247/6daf8d86.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146247/6daf8d86.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/horton-house-bradford-1907091396145?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "d6b1761d5c0e4e2f82ce0d5f45ba0564"
    },
    {
        "room_name": "The Green, Bradford",
        "location": "Longside Ln, Bradford, BD7 1DF",
        "details": [
            "6 Room Options",
            "4 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bike Storage",
            "Laundry Facility",
            "Bills Included",
            "Cinema",
            "Courtyard",
            "Laundry Room",
            "From£72/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/172521/bcfa67d9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147245/3df313c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/172521/bcfa67d9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/172521/bcfa67d9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-green-village-bradford-1911209517229?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "51d953b48f704b4ba71c06498e51852c"
    },
    {
        "room_name": "Welton Road, Leeds",
        "location": "Welton Rd, Leeds, LS6 1EE",
        "details": [
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Free Laundry Facilities",
            "All Bills Included",
            "CCTV",
            "Recycling",
            "Free Laundry",
            "Outdoor Area",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/162936/36ba8ed7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/162936/d278d16f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/162936/36ba8ed7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/162936/36ba8ed7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/14-welton-road-2010076462209?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "336a93925b1540b992047f7f05efc67c"
    },
    {
        "room_name": "Leeds City West, Leeds",
        "location": "Burley Rd, Leeds, LS3 1JR",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "Sauna",
            "Steam Room",
            "From£200/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/165793/3bee52af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/165793/de8b2f32.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/165793/3bee52af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/165793/3bee52af.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/leeds-city-west-leeds-2011064065683?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "8924681288cb448db7b3b710cdfc284c"
    },
    {
        "room_name": "James Baillie Park, Leeds",
        "location": "N Hill Rd, Leeds, LS6 2EN",
        "details": [
            "11 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Common Area",
            "Laundry",
            "Recycling",
            "Common Area",
            "Bike Storage",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/284385/fcbccab0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132660/db916b17.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/284385/fcbccab0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/284385/fcbccab0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/james-baillie-park-leeds-1608300576345?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "6d4e6e6bba224d258dab98178729a9d2"
    },
    {
        "room_name": "Clarence Dock Village, Leeds",
        "location": "Clarence Rd, Leeds, LS10 1LU",
        "details": [
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bike Storage",
            "Laundry Facility",
            "Laundry",
            "Common Area",
            "Bike Storage",
            "Communal Study Space",
            "From£116/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/221704/2041c38f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221703/bc7cbf5f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221704/2041c38f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/221704/2041c38f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/clarence-dock-village-leeds-2207012806284?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "76fd6677a5ad4fdea4ba0d93fc9ee822"
    },
    {
        "room_name": "Hepworth Lodge, Leeds",
        "location": "Marlborough St, Leeds, LS1 4LA",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Bike Storage",
            "Wi-Fi",
            "Laundry",
            "Bike Storage",
            "From£161/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/202598/63692f87.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/202594/83e04bf7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/202598/63692f87.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/202598/63692f87.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/hepworth-lodge-2112071227221?ref=search&regionId=43",
        "city": "Leeds",
        "room_id": "b7cb0460069246488dcb20cf13fc9e4d"
    },
    {
        "room_name": "True Newcastle Opto, Newcastle Upon Tyne",
        "location": "City Rd, Newcastle upon Tyne, NE1 2AQ",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Zones",
            "Laundry Facilities",
            "Cycle Storage",
            "Cinema",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/180642/ccebbfe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/180624/7d87ef18.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/180642/ccebbfe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/180642/ccebbfe5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-newcastle-opto-2103235404425?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "42ddec6f13f64b6a8cf4182b3836be26"
    },
    {
        "room_name": "St Mary's Newcastle, Newcastle",
        "location": "Fenham Hall Dr, Newcastle upon Tyne, NE4 9YH",
        "details": [
            "26 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Communal Spaces",
            "Bike Storage",
            "Vending Machines",
            "WiFi",
            "Garden",
            "From£110/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/263193/86267043.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/233635/561487ae.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/263193/86267043.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/263193/86267043.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-mary-s-hall-newcastle-2209239947087?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "7abe290aba4845548b49f5d856f59369"
    },
    {
        "room_name": "Garth Heads, Newcastle",
        "location": "Melbourne St, Newcastle upon Tyne, NE1 2JE",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bills Included",
            "Game Room",
            "Gym",
            "Games Room",
            "From£121/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139261/0d265887.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139259/77324fa7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139261/0d265887.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139261/0d265887.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/garth-heads-newcastle-1802147411365?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "96352c1965604240990eafb9b823010c"
    },
    {
        "room_name": "Market House, Newcastle Upon Tyne",
        "location": "Market St, Newcastle upon Tyne, NE1 6AN",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Game Room",
            "Laundry Facility",
            "Games Room",
            "From£160/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139090/6f5a84b6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139090/d405dc2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139090/6f5a84b6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139090/6f5a84b6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/market-house-newcastle-1802084819383?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "0267f51262ff41eeb0e309b57419c131"
    },
    {
        "room_name": "City Co-Living Newcastle, Newcastle Upon Tyne",
        "location": "Pilgrim St, Newcastle upon Tyne, NE1 6SU",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Cinema",
            "Coffee Station",
            "Dining Rooms",
            "State Of The Art Gym",
            "From£177/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/258426/35af4a7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/255311/af8c1c1e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/258426/35af4a7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/258426/35af4a7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/city-co-living-newcastle-newcastle-upon-tyne-2302146479879?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "bdf66d475b6744d281162eb1baf925e5"
    },
    {
        "room_name": "Newcastle 1, Newcastle",
        "location": "Blandford Square, Newcastle upon Tyne, NE1 4HZ",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "BBQ",
            "From£179/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141370/83cc5ee7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141367/e476699b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141370/83cc5ee7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141370/83cc5ee7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/newcastle-1-newcastle-1805215820050?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "04e28ea3a132445e82d9484835fee922"
    },
    {
        "room_name": "iQ Collingwood, Newcastle",
        "location": "Newcastle upon Tyne, NE1 6PA",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Roof Terrace",
            "Bills Included",
            "Lounge",
            "Free Gym",
            "From£186/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/197569/06bd2e08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143280/33968403.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/197569/06bd2e08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/197569/06bd2e08.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-collingwood-newcastle-1811212286455?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "37fba9494e18472a9e6a4461da355da2"
    },
    {
        "room_name": "Collegiate Plummer House, Newcastle",
        "location": "Market St, Newcastle upon Tyne, NE1",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "VIP Bar",
            "Study Room",
            "From£209/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142508/a39b5801.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142506/ec15114b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142508/a39b5801.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142508/a39b5801.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/plummer-house-newcastle-1807118570212?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "e69dbdefd7964f069497728e2b8cd6eb"
    },
    {
        "room_name": "INTO Newcastle University, Newcastle",
        "location": "Newcastle upon Tyne, NE1 7RU",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Study Area",
            "New",
            "Groceries",
            "Housekeeping",
            "Communal Spaces",
            "From£285/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/262576/75731cde.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/262576/742e87a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/262576/75731cde.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/262576/75731cde.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/into-newcastle-university-newcastle-2304120744357?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "5dbe40f5170d4960a099ec349973fa91"
    },
    {
        "room_name": "St James' Point, Newcastle",
        "location": "Pitt St, Newcastle upon Tyne, NE4 5SU",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Best Payment Plan",
            "Outdoor Courtyard",
            "Laundry Facility",
            "From£220/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146830/a913f086.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141351/48236003.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146830/a913f086.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146830/a913f086.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-james-point-newcastle-1805217566851?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "801f6f99cfd4469ab7c95c767a55c545"
    },
    {
        "room_name": "Vita Student Strawberry Place, Newcastle",
        "location": "Strawberry Pl, Newcastle upon Tyne, NE1 4PQ",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Vending Machine",
            "Laundry Facility",
            "Movie Room",
            "Free Events",
            "From£223/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141882/582efee3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139945/84805e65.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141882/582efee3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141882/582efee3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-strawberry-place-newcastle-1804027443913?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "d4232e5433a64d2c9184277ec6fd3768"
    },
    {
        "room_name": "Vita Student Westgate, Newcastle upon Tyne",
        "location": "Westgate Rd, Newcastle upon Tyne, NE1 1TT",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "CCTV",
            "Courtyard",
            "Free Events",
            "Bike Rental",
            "Spacious Hub",
            "From£227/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147592/6e5e61ac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133708/0eff672a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147592/6e5e61ac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147592/6e5e61ac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-westgate-newcastle-1608302336874?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "5522c28af06c4230a61c66ca4d93c412"
    },
    {
        "room_name": "St James' House, Newcastle",
        "location": "St James Ter, Newcastle upon Tyne, NE1 4NF",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Best Payment Plan",
            "Bike Storage",
            "Game Room",
            "TV",
            "From£299/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141365/ef6c2aa6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141360/7ed5a470.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141365/ef6c2aa6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141365/ef6c2aa6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-james-house-newcastle-1805215767732?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "319a8fd3fcca4ddf904e77c2e1bf6692"
    },
    {
        "room_name": "Magnet Court, Newcastle",
        "location": "Gallowgate, Newcastle upon Tyne, NE1 4SN",
        "details": [
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Laundry Facility",
            "Study Area",
            "Laundry",
            "Recycling",
            "Common Area",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143857/1ced892a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142375/9ee4d253.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143857/1ced892a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143857/1ced892a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/magnet-court-newcastle-1806238527793?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "f7653c7a575f479c856b7fbb1018e236"
    },
    {
        "room_name": "The View, Newcastle Upon Tyne",
        "location": "Barrack Rd, Newcastle upon Tyne, NE1 4SH",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Game Room",
            "Bills Included",
            "Cycle Storage",
            "From£167/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/201538/2acb245d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133701/c7dc6626.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201538/2acb245d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/201538/2acb245d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-view-newcastle-upon-tyne-1608303739862?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "50cef70416f4421d8b6c42706be64114"
    },
    {
        "room_name": "Quay Point, Newcastle",
        "location": "Newcastle upon Tyne, NE1 6PZ",
        "details": [
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Common Area",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149008/d5e22e8e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149005/991b3fc4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149008/d5e22e8e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149008/d5e22e8e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/quay-point-newcastle-2002157567190?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "14fa9b98f9884f34b20ca05ca17e2796"
    },
    {
        "room_name": "Verde, Newcastle Upon Tyne",
        "location": "Pitt St, Newcastle upon Tyne, NE4 5TH",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Cinema",
            "Bills Included",
            "Games Room",
            "Study Room",
            "From£250/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/170863/8af572aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133657/87bc6a59.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170863/8af572aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/170863/8af572aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/verde-newcastle-upon-tyne-1608304599225?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "ed9ac04d582a41149c63673c86c6f7cb"
    },
    {
        "room_name": "Roman House, Newcastle",
        "location": "Newcastle upon Tyne, NE1 3NP",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Lounge",
            "Laundry",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140405/f46324d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140374/a30f8d29.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140405/f46324d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140405/f46324d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/roman-house-newcastle-1804161239069?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "b252265c3964407b98ff4eba2e00c8fa"
    },
    {
        "room_name": "Dunn House, Sunderland",
        "location": "North Bridge St, Sunderland, SR5 1AH",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Wifi",
            "From£205/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931577/f551eb53.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/173947/e87142ec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931577/f551eb53.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931577/f551eb53.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/dunn-house-2012297322173?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "65e8be80cc3a4b43b0c34a91654abfe3"
    },
    {
        "room_name": "Norfolk Street, Sunderland",
        "location": "Norfolk St, Sunderland, SR1 1EE",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "TV (Communal)",
            "Cleaner",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146981/3826edd0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146979/e08be59a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146981/3826edd0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146981/3826edd0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/norfolk-street-sunderland-1911065735599?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "eef2221d67d549f98a60060d57e631f9"
    },
    {
        "room_name": "Step House, Newcastle Upon Tyne",
        "location": "Stepney Ln, Newcastle upon Tyne, NE1 6PN",
        "details": [
            "7 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Game Room",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/184006/25090483.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/184006/d6ded27e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/184006/25090483.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/184006/25090483.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/step-house-newcastle-upon-tyne-2105250100942?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "5006ed3c093c456ab6a869323849c7f6"
    },
    {
        "room_name": "Yugo Knoll Court, Newcastle",
        "location": "Tower St, Newcastle upon Tyne, NE1 2HW",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Common Room",
            "Bike Storage",
            "Communal Spaces",
            "From£136/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133707/5ee5ef40.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133705/66f19310.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133707/5ee5ef40.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133707/5ee5ef40.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/knoll-court-1608301077804?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "bc32d8bdd67d450db7c62ddfb60bf30c"
    },
    {
        "room_name": "Burgess House, Newcastle",
        "location": "St James' Blvd, Newcastle upon Tyne, NE1 4BW",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "Gym",
            "CCTV",
            "Laundry Room",
            "Gym",
            "City Centre",
            "Free Wi-Fi",
            "Social Area",
            "Cinema Room",
            "Car Parking",
            "From£163/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/941504/a7ae07a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/941389/2e6845c4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/941504/a7ae07a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/941504/a7ae07a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/burgess-house-newcastle-2401160801123?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "21dfcd82697d4319b26e5f44918bd9c3"
    },
    {
        "room_name": "Oxford House, Newcastle",
        "location": "Oxford St, Newcastle upon Tyne, NE1 8AA",
        "details": [
            "10 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "Laundry Facility",
            "Free Gym",
            "TV Lounge",
            "Broadband",
            "Games Area",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/279377/55287583.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/279331/559d2071.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/279377/55287583.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/279377/55287583.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/oxford-house-newcastle-2306195853556?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "d64e7b4ebc1a4289aa23cef6f829f19f"
    },
    {
        "room_name": "The Foundry, Newcastle",
        "location": "Clarence St, Newcastle upon Tyne, NE2 1YL",
        "details": [
            "5 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Game Room",
            "Laundry Facility",
            "Communal Lounge",
            "Games Area",
            "Study Space",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148282/accbe1fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133618/6cdf4db7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148282/accbe1fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148282/accbe1fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-foundry-newcastle-1608301658224?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "e56dfffc472f4314b8e8099abc768805"
    },
    {
        "room_name": "The Bridge, Newcastle",
        "location": "Union St, Newcastle upon Tyne, NE2 1AH",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Utilities Included",
            "Gym Access",
            "COVID 19 Cancellation Policy",
            "Garden",
            "Balcony",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138447/c5034ea7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138432/86d1c0a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138447/c5034ea7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138447/c5034ea7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/nido-the-bridge-1712094768648?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "9856db0d368f44b7b166e51918295e67"
    },
    {
        "room_name": "Newcastle City, Newcastle Upon Tyne",
        "location": "Clavering Pl, Newcastle upon Tyne, NE1",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "Gym",
            "Sauna",
            "Steam Room",
            "From£375/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156798/b3bcc63e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156798/9979fa3e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156798/b3bcc63e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156798/b3bcc63e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/newcastle-city-newcastle-upon-tyne-2007316584308?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "323101164024405a8ae220dbe65426ea"
    },
    {
        "room_name": "All Saints House, Sunderland",
        "location": "Portobello Ln, Sunderland, SR6 0DN",
        "details": [
            "12 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "24/7 CCTV",
            "Laundry Room",
            "Car Parking",
            "Gym",
            "Free Wi-Fi",
            "Car Parking",
            "Laundry Room",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/941623/0c02a33c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/941518/27f77b0a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/941623/0c02a33c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/941623/0c02a33c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/all-saints-house-sunderland-2401166250591?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "a2f1150f97ee4960859c7383a0bac756"
    },
    {
        "room_name": "Manor Bank, Newcastle",
        "location": "Pandon Bank, Newcastle upon Tyne, NE1 2JA",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Study Area",
            "Laundry",
            "Recycling",
            "Common Area",
            "From£101/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/153724/6a63607d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142380/93ffb36c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/153724/6a63607d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/153724/6a63607d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manor-bank-newcastle-1806238094654?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "b92c42e48ae44f178fa216a6ecae556e"
    },
    {
        "room_name": "Byron Central, Newcastle",
        "location": "Byron St, Newcastle upon Tyne, NE2 1XH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Study Area",
            "Laundry",
            "Recycling",
            "Cinema Room",
            "From£114/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148985/9c5e356a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148980/3ec39e5f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148985/9c5e356a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148985/9c5e356a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/byron-central-newcastle-2002148828013?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "10679cc71b444f40ba81d41d5c5cbbaf"
    },
    {
        "room_name": "Quay Point Studios, Newcastle",
        "location": "Melbourne St, Newcastle upon Tyne, NE1 2JB",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine ˙",
            "Laundry",
            "Recycling",
            "From£134/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149004/bca739c7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148987/421ddcda.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149004/bca739c7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149004/bca739c7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/quay-point-studios-newcastle-2002155924885?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "692b21c43b7e4fea9d791440ec96ac78"
    },
    {
        "room_name": "The Glassworks, Newcastle Upon Tyne",
        "location": "Coquet St, Newcastle upon Tyne, NE1 2QF",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema Room",
            "All Bills Included",
            "Study Room",
            "BBQ Area",
            "Courtyard",
            "Ping Pong",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/227153/6b47a168.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147610/85434952.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/227153/6b47a168.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/227153/6b47a168.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-glassworks-newcastle-1912056488189?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "27fa82eb7be3438499b5d06db8462c7d"
    },
    {
        "room_name": "Wellington St Plaza, Newcastle",
        "location": "Wellington St, Newcastle upon Tyne, NE4 5SA",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Bike Storage",
            "Laundry Facility",
            "Laundry",
            "Recycling",
            "From£154/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149036/96e903e3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149014/36a49007.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149036/96e903e3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149036/96e903e3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/wellington-st-plaza-newcastle-2002157791267?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "10d879fa110c4ac3b6cbf6a4612e47a7"
    },
    {
        "room_name": "Newgate Court, Newcastle",
        "location": "Grainger St, Newcastle upon Tyne, NE1 5AT",
        "details": [
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Vending Machine",
            "Laundry Facility",
            "Laundry",
            "Recycling",
            "Common Area",
            "Cinema Room",
            "From£203/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142379/0cee4091.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142377/9435338e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142379/0cee4091.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142379/0cee4091.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/newgate-court-newcastle-1806239425440?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "e778484ae92741f3b39a87ee1307768f"
    },
    {
        "room_name": "The Shield, Newcastle",
        "location": "Clarence St, Newcastle upon Tyne, NE2 1YN",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Lounge",
            "Laundry",
            "Courtyard",
            "From£131/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/136945/7f65e662.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136938/1db70604.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136945/7f65e662.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/136945/7f65e662.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-shield-newcastle-1708080584621?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "6eab6a4e448f47118328ad3a5acfc8ce"
    },
    {
        "room_name": "Stepney Yard, Newcastle Upon Tyne",
        "location": "Stepney Rd, Newcastle upon Tyne, NE2 1TZ",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema",
            "Game Room",
            "Study Area",
            "Garden",
            "Cinema Room",
            "Housekeeping",
            "From£200/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138409/fb0da1aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138399/85c2ff19.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138409/fb0da1aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138409/fb0da1aa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/nido-stepney-yard-1712082129304?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "95ddd4291e7d49c5810fa107b450ba34"
    },
    {
        "room_name": "Millfield House, Sunderland",
        "location": "Hylton Rd, Sunderland, SR4 7BB",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Onsite Maintenace",
            "Laundry Facility",
            "Bills Included",
            "Cleaner",
            "Laundry Room",
            "From£110/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931533/7d782bc2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146974/6ff5f298.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931533/7d782bc2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931533/7d782bc2.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/millfield-house-sunderland-1911066371968?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "47ef023fa8aa443c81952e685d4e1d8d"
    },
    {
        "room_name": "Park Lane House, Sunderland",
        "location": "Stockton Rd, Sunderland, SR2 7AQ",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Bills Included",
            "Cleaner",
            "Common Room",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146983/e21ea40e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146982/6e0913e1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146983/e21ea40e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146983/e21ea40e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/park-lane-sunderland-1911063502576?ref=search&regionId=32",
        "city": "Newcastle upon tyne",
        "room_id": "64fcdf40584c492a9154c9e4ae3c8a66"
    },
    {
        "room_name": "Canvas River Street Tower, Manchester",
        "location": "River St, Manchester, M15 5GQ",
        "details": [
            "13 Room Options",
            "4 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Cinema",
            "Games Room",
            "Study Spaces",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147766/4f6229f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147766/6efe3aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147766/4f6229f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147766/4f6229f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-river-street-tower-manchester-1912099136519?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "b1faf7bb8ed8427f9a38bdad664abd57"
    },
    {
        "room_name": "City Edge, Manchester",
        "location": "Ardwick Green S, Manchester, M13 9XE",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Parking",
            "Laundry Room",
            "Parking",
            "Quiet Area",
            "Laundry Room",
            "Central Area",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148716/ec04bdec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148710/bc379c16.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148716/ec04bdec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148716/ec04bdec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/city-edge-2002056940227?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "b102f3ffd2ce480980a52a4e9f396880"
    },
    {
        "room_name": "Ladybarn House, Manchester",
        "location": "Moseley Rd., Manchester, M14 6ND",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Bills Included",
            "Laundry Facility",
            "CCTV",
            "Garden",
            "From£160/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/958587/ba3f2c3a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958587/9ef1fee7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958587/ba3f2c3a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/958587/ba3f2c3a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lady-barn-house-manchester-2402165398553?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "edc9e9315515487da1839adc56550488"
    },
    {
        "room_name": "Vita Student Circle Square, Manchester",
        "location": "Nobel Wy., Manchester, M1 7FA",
        "details": [
            "1 Room Option",
            "5 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Cinema",
            "Study Room",
            "Movie Room",
            "Free Events",
            "Bike Rental",
            "From£318/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133570/a35bdaf3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133570/9ebf19e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133570/a35bdaf3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133570/a35bdaf3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-circle-square-manchester-1608300741843?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "a13448be0b55477ca158e5893705231a"
    },
    {
        "room_name": "Vita Student First Street, Manchester",
        "location": "Jack Rosenthal St, Manchester, M15 4RB",
        "details": [
            "2 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Swimming Pool",
            "Laundry Facility",
            "Movie Room",
            "Free Events",
            "From£312/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141875/9d00839e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139896/41a641a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141875/9d00839e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141875/9d00839e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-first-street-manchester-1803316763602?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "e64a6ad109314f0aa2e8641e81d16c07"
    },
    {
        "room_name": "Moss Court, Manchester",
        "location": "Moss Ln E, Manchester, M14 4PQ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "On-Site Laundry",
            "All Bills Included",
            "CCTV",
            "Free Wifi",
            "Reception",
            "Central Area",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148701/6862d123.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148701/65a33e23.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148701/6862d123.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148701/6862d123.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/moss-court-2002051724372?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "579d4e5bc03e4fc1bfc9fb93b81817fe"
    },
    {
        "room_name": "Riverside House, Manchester",
        "location": "Blackfriars Rd, Salford, M3 7FU",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "CCTV",
            "On-Site Laundry",
            "Broadband",
            "Cinema Room",
            "From£204/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1102984/4c4c7d92.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102887/7334c541.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102984/4c4c7d92.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1102984/4c4c7d92.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/riverside-house-manchester-2406084247915?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "e71dbdd1d19d4f73a5f7eff18812ea88"
    },
    {
        "room_name": "Harry Lord House, Manchester",
        "location": "Humphrey Rd, Manchester, M16",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Communal Laundry",
            "CCTV",
            "Bills Included",
            "From£215/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/924898/86a4738e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924898/4bc294ca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924898/86a4738e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/924898/86a4738e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/harry-lord-house-manchester-2311159017965?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "5e18e28fbc07444fab55415a7a6aa826"
    },
    {
        "room_name": "iQ Lambert & Fairfield House, Manchester",
        "location": "Granby Row, Manchester, M60 7LH",
        "details": [
            "16 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Game Room",
            "Bills Included",
            "TV",
            "Lounge",
            "From£235/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/196913/07411fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133583/50c12453.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/196913/07411fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/196913/07411fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-lambert-fairfield-house-manchester-1608303994293?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "5f9ce44017bb49ff91593e889d1c1549"
    },
    {
        "room_name": "Dwell 121 Princess Street, Manchester",
        "location": "Princess St, Manchester, M1 7AG",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Underfloor Heating",
            "Premium Living",
            "From£251/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/926457/cf4b70dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148685/4c425a8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/926457/cf4b70dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/926457/cf4b70dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/dwell-121-princess-street-2002038737937?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "d49f150f732d4d27876d8a1af8de894c"
    },
    {
        "room_name": "iQ Manchester Gardens, Manchester",
        "location": "Hope Rd, Manchester, M14 5ET",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Onsite Car Parking",
            "Laundry Facility",
            "Bills Included",
            "Garden",
            "Study Area",
            "From£237/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144826/2d533576.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144005/4f07cd31.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144826/2d533576.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144826/2d533576.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-manchester-gardens-manchester-1901296749601?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "6f4603c5c3d34cbbbcb814b7f778a527"
    },
    {
        "room_name": "iQ Wilmslow Park, Manchester",
        "location": "Hathersage Rd, Manchester, M13 0JQ",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Free Gym",
            "From£260/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133568/31e135e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133561/6155c188.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133568/31e135e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133568/31e135e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-wilmslow-park-house-manchester-1608305817951?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "2681455524ca4d42991f540c3614f1be"
    },
    {
        "room_name": "Britannia Students Heald Court, Manchester",
        "location": "Heald Grove, Manchester, M14 4PE",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Lounge Area",
            "Reception",
            "Lounge Area",
            "Outdoor Area",
            "Bike Storage",
            "From£283/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1200150/b1bbf0a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200117/c2799fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200150/b1bbf0a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1200150/b1bbf0a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/britannia-students-heald-court-manchester-2408012148903?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "26bccc65295f4a718f080ffd1cbb9ab5"
    },
    {
        "room_name": "iQ Hollingworth House, Manchester",
        "location": "Whitworth St W, Manchester, M1 3GW",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cinema",
            "Bills Included",
            "Game Room",
            "Free Gym",
            "From£298/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147169/5b582ff8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143472/cd6b1bff.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147169/5b582ff8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147169/5b582ff8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-hollingworth-house-manchester-1811275429673?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "e7e4ae12450b4e7bbebbbb56c4178fcc"
    },
    {
        "room_name": "iQ Kerria Apartments, Manchester",
        "location": "Hathersage Rd, Manchester, M13 0JQ",
        "details": [
            "17 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Study Area",
            "Lounge",
            "From£313/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143482/9cbc4fbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143481/0363c22f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143482/9cbc4fbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143482/9cbc4fbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-kerria-apartments-manchester-1811271827877?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "fc893701ef8245ee89cdb0bdeb5b1394"
    },
    {
        "room_name": "iQ Fletcher House, Manchester",
        "location": "Fairfield St, Manchester, M1 3GN",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Laundry Facility",
            "Free Gym",
            "From£341/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143471/3730ecf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143468/dd026983.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143471/3730ecf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143471/3730ecf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-fletcher-house-manchester-1811277697576?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "936f8f73ef5a47f58f34a0b033f19b95"
    },
    {
        "room_name": "Piccadilly Point, Manchester",
        "location": "Berry St, Manchester, M1 2AD",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Gym",
            "From£172/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142639/9b0157d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142615/ff42b7cf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142639/9b0157d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142639/9b0157d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/piccadilly-point-manchester-1808135757042?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "b0286907dd25481c95d70f2703e51985"
    },
    {
        "room_name": "New Medlock House, Manchester",
        "location": "Chester St, Manchester",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machines",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "From£215/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142634/51d83831.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142605/1f5d53d0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142634/51d83831.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142634/51d83831.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/new-medlock-house-manchester-1808130542856?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "f7552a5437d447bbb13c814c5348fb81"
    },
    {
        "room_name": "Bridgewater Heights, Manchester",
        "location": "Great Marlborough St, Manchester, M1",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "From£223/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/151750/1fd91c9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148929/7bd142f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/151750/1fd91c9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/151750/1fd91c9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bridgewater-heights-manchester-2002145605325?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "4f52b59683594d1a96347bdecb782563"
    },
    {
        "room_name": "Square Gardens, Manchester",
        "location": "Manchester, M15",
        "details": [
            "15 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wifi",
            "Gym",
            "Bills Included",
            "Media",
            "Lounge",
            "Cinema Room",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1033604/75e3e751.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1030839/3a9b016f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1033604/75e3e751.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1033604/75e3e751.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/square-gardens-manchester-2404189841822?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "a219514259d6427bbe007c2cfd191c52"
    },
    {
        "room_name": "Parkway Gate, Manchester",
        "location": "Chester St, Manchester, M15",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Vending Machine",
            "Laundry",
            "Recycling",
            "From£255/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142611/5be420fe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142610/b4f8e511.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142611/5be420fe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142611/5be420fe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/parkway-gate-manchester-1808131715500?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "6c6d03c6ccb34838abc31cf6aed23516"
    },
    {
        "room_name": "Artisan Heights, Manchester",
        "location": "New Wakefield St, Manchester, M1 5AA",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "From£275/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/159720/1c8cf836.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149159/aef5df46.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/159720/1c8cf836.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/159720/1c8cf836.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/artisan-heights-manchester-2002243858684?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "34d07c698cc84495ae9c6f716e97749b"
    },
    {
        "room_name": "Thurloe Street, Manchester",
        "location": "Thurloe St, Manchester, M14 5SG",
        "details": [
            "28 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Ample Storage",
            "Contemporary Living Space",
            "Stylish Furnishings",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1110700/9aa88675.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1095271/0a9940db.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1110700/9aa88675.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1110700/9aa88675.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/thurloe-street-manchester-2405309046201?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "4e9ceab1a5ef4a03a39c9585a8b31017"
    },
    {
        "room_name": "iQ Student Quarter, Salford",
        "location": "Seaford Rd, Salford, M6 6FN",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Study Area",
            "Bills Included",
            "TV",
            "Lounge",
            "From£207/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143542/0c23178d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143539/4d217d29.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143542/0c23178d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143542/0c23178d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-student-quarter-salford-1811299838700?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "3cc46b71a15b4062b5dabf7dc854c9d6"
    },
    {
        "room_name": "True Manchester Salford, Salford",
        "location": "King William St, Salford, M50 3BG",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "CCTV Cameras",
            "Cinema",
            "Courtyard",
            "From£232/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1168867/d6bfae9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1167294/dae74bec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1168867/d6bfae9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1168867/d6bfae9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-manchester-salford-salford-2407152675927?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "b002628c7e2540058a6820c9fb3863a4"
    },
    {
        "room_name": "MSV South, Manchester",
        "location": "Great Western St, Manchester, M14 4AH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Onsite CCTV",
            "Bills Included",
            "Table-Tennis",
            "100 Mbps Internet",
            "Social Events Calendar",
            "From£210/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/161040/54bd30bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139245/5b263891.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/161040/54bd30bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/161040/54bd30bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/msv-south-manchester-1802136140893?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "a3590b09f39a48c3b894402bd079c082"
    },
    {
        "room_name": "Element, Manchester",
        "location": "Cambridge St, Manchester, M15 6BS",
        "details": [
            "5 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Foldable Bikes",
            "Laundry Facility",
            "Study Area",
            "TV Lounge",
            "Cinema Room",
            "From£330/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/219473/9fdc89de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219472/f0a9c92c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219473/9fdc89de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/219473/9fdc89de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/element-manchester-2206066790529?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "9b53e8c22de544babdabf277b48fe668"
    },
    {
        "room_name": "Manchester Corn Exchange, Manchester",
        "location": "Exchange Sq, Manchester",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Sauna",
            "Laundry",
            "Steam Room",
            "From£399/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156813/c9e81aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156799/6d362faa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156813/c9e81aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156813/c9e81aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manchester-corn-exchange-manchester-2007313820155?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "119ea868804147dba1e1369c0ddca42d"
    },
    {
        "room_name": "Manchester City, Manchester",
        "location": "Princess St, Manchester, M1 4JY",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "Sauna",
            "Laundry",
            "From£425/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156800/402f1c7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156800/6ef50870.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156800/402f1c7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156800/402f1c7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manchester-city-manchester-2007318515993?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "45eac7f5ae7543f4ae67eab991d34021"
    },
    {
        "room_name": "Mill Point, Manchester",
        "location": "Berry St, Manchester, M1 2AR",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Bike Storage",
            "Wi-Fi",
            "Laundry",
            "Recycling",
            "From£158/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148954/4cc4d944.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148952/bdd057db.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148954/4cc4d944.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148954/4cc4d944.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mill-point-manchester-2002142172845?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "85f7030c91964cbf9cb70f82a183f0cd"
    },
    {
        "room_name": "Kincardine Court, Manchester",
        "location": "Kincardine Rd, Manchester, M13 9SY",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Bike Storage",
            "Study Area",
            "Wi-Fi",
            "Recycling",
            "Common Area",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/203876/744ffcc8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142608/6df9f63f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/203876/744ffcc8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/203876/744ffcc8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kincardine-court-manchester-1808137208545?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "3d15f64e143f495caa485dbaae8823b4"
    },
    {
        "room_name": "Sir Charles Groves Hall, Manchester",
        "location": "Booth St W, Manchester, M15 6PF",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "Recycling",
            "From£190/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148945/dd3689d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148945/8215fa38.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148945/dd3689d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148945/dd3689d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sir-charles-groves-hall-manchester-2002142435086?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "ffff2945e9744ca2a29c61e991555863"
    },
    {
        "room_name": "Manchester Student Village (MSV), Manchester",
        "location": "Lower Chatham St, Manchester",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Game Room",
            "Study Area",
            "Bills Included",
            "Study Room",
            "TV & PS4 Area",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139236/74dc2ded.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139227/c24fe643.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139236/74dc2ded.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139236/74dc2ded.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manchester-student-village-msv-manchester-1802136315744?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "fafa517ae359440993d60b372a1e541c"
    },
    {
        "room_name": "Weston Court, Manchester",
        "location": "Cromwell Range, Manchester, M14 5JR",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facilities",
            "Bike Storage",
            "Bills Included",
            "Outdoor Seating Area",
            "From£147/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139257/d9431fab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139255/43d1c4e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139257/d9431fab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139257/d9431fab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/weston-court-manchester-1802149414341?ref=search&regionId=56",
        "city": "Manchester",
        "room_id": "b9055e33039b47e4ae21c932940042ca"
    },
    {
        "room_name": "Collegiate Crown Place, Cardiff",
        "location": "St Andrew's Pl, Cardiff, CF10 3BE",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Study Room",
            "Courtyard",
            "Games Room",
            "Study Room",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/252339/3b03cf62.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141770/11f2601d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/252339/3b03cf62.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/252339/3b03cf62.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/crown-place-cardiff-1806034929653?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "541048e4367e43abaa83f19d29226e03"
    },
    {
        "room_name": "Collegiate Eclipse, Cardiff",
        "location": "Newport Rd Ln, Cardiff, CF24 0SP",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Private Cinema",
            "Gym & Fitness Suite",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143975/109cc310.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137396/00a7eb77.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143975/109cc310.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143975/109cc310.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/eclipse-cardiff-1709122002822?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "3471f58b74954dcf903fafca403e40c8"
    },
    {
        "room_name": "The West Wing, Cardiff",
        "location": "Glossop Rd, Cardiff, CF24 0JU",
        "details": [
            "14 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "Free Gym",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/198312/00a31ea9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143777/87c0872d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/198312/00a31ea9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/198312/00a31ea9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-west-wing-cardiff-1812149982500?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "d8f6aa55e4404cf19ccb0169e9ec6548"
    },
    {
        "room_name": "Zenith, Cardiff",
        "location": "Herbert St, Cardiff, CF10",
        "details": [
            "20 Room Options",
            "4 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cinema Room",
            "From£180/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/189423/a8d69ab5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144691/124f971b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/189423/a8d69ab5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/189423/a8d69ab5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/zenith-cardiff-1905077765811?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "9686bff35c4042ec8e005a94ab48624a"
    },
    {
        "room_name": "Glendower House, Cardiff",
        "location": "St Andrew's Ln, Cardiff, CF10 3DJ",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Cinema Room",
            "Bills Included",
            "Wi-Fi",
            "Games Room",
            "From£215/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139419/48705cb4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139415/e384fa6e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139419/48705cb4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139419/48705cb4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/glendower-house-cardiff-1803094436581?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "acd023b372024f2da5691feb2ee1fc09"
    },
    {
        "room_name": "City Heights, Cardiff",
        "location": "City Rd, Cardiff, CF24 3JR",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Game Room",
            "TV Area",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/182301/77d55a49.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144848/104fbf45.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/182301/77d55a49.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/182301/77d55a49.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/city-heights-cardiff-1905188224315?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "178f2512115f43d085dda0f4fa315fef"
    },
    {
        "room_name": "Collegiate The Neighbourhood, Cardiff",
        "location": "The Parade, Cardiff, CF24 3AD",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Game Room",
            "Bills Included",
            "Gym",
            "Cinema",
            "From£280/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140198/3894ba44.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140159/df4a72dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140198/3894ba44.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140198/3894ba44.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-neighbourhood-cardiff-1804096706571?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "837a88fadef1440886e0eb6c56e9ad91"
    },
    {
        "room_name": "Bridge Street Exchange, Cardiff",
        "location": "Bridge St, Cardiff, CF10",
        "details": [
            "27 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Lounge",
            "Laundry",
            "From£180/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139018/00e9486e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139008/bcb0143c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139018/00e9486e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139018/00e9486e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bridge-street-exchange-cardiff-1802051429698?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "c6f330c5607f45498768e4ab5eabaeda"
    },
    {
        "room_name": "Cambrian Point, Cardiff",
        "location": "Maindy Rd, Cardiff, CF24 4HJ",
        "details": [
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wi-Fi",
            "Laundry Facility",
            "Bills Included",
            "Laundry",
            "Bike Storage",
            "From£184/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148799/91e90f37.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148798/d2f39706.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148799/91e90f37.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148799/91e90f37.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cambrian-point-cardiff-2002116832750?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "1cbc4dd4146c4631ad950200aa103461"
    },
    {
        "room_name": "Severn Point, Cardiff",
        "location": "Blackweir Terrace, Cardiff, CF10",
        "details": [
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "Common Area",
            "Bike Storage",
            "From£155/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148813/204a9967.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148812/dbac86ca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148813/204a9967.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148813/204a9967.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/severn-point-cardiff-2002127697862?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "b52db0c2ed714cb79ed471b2dcfc3850"
    },
    {
        "room_name": "Livin, Cardiff",
        "location": "City Rd, Cardiff, CF24 3BJ",
        "details": [
            "12 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Wi-Fi",
            "Games Area",
            "Parcel Service",
            "From£180/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139435/6e06de57.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139432/84318174.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139435/6e06de57.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139435/6e06de57.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/livin-cardiff-1803100419709?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "4adbef59b80c489ca0a67c7f4dc34902"
    },
    {
        "room_name": "Vita Student Park Place, Cardiff",
        "location": "Park Pl, Cardiff, CF10 3FH",
        "details": [
            "1 Room Option",
            "5 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Cctv",
            "Breakfast",
            "Free Events",
            "Bike Rental",
            "Spacious Hub",
            "From£232/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/199361/50fde5a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199320/1c6f582a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/199361/50fde5a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/199361/50fde5a8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-park-place-cardiff-2111171581582?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "c97ab5b7d8ad4170801de64aac99df49"
    },
    {
        "room_name": "Adam Street Gardens, Cardiff",
        "location": "Adam St, Cardiff, CF24 2FL",
        "details": [
            "3 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Laundry",
            "Recycling",
            "Common Area",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148804/2be502a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148801/6a807bf4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148804/2be502a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148804/2be502a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/adam-street-gardens-cardiff-2002111797963?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "4ad6802f356f4c478fe78f0f8826d095"
    },
    {
        "room_name": "Howard Gardens, Cardiff",
        "location": "Howard Gardens, Cardiff, CF24 0FA",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Study Space",
            "Karaoke Room",
            "TV Area",
            "Games Zone",
            "Common Room",
            "Study Space",
            "Maintenance",
            "From£142/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149197/49ae3c2c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149192/0619b090.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149197/49ae3c2c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149197/49ae3c2c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/howard-gardens-cardiff-2003029437243?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "18d950306a7046c7a1bd6685c11ff3de"
    },
    {
        "room_name": "Ty Pont Haearn, Cardiff",
        "location": "Pellett St, Cardiff, CF10 4FS",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Breakfast Bar",
            "Fire System",
            "CCTV",
            "TV Lounge",
            "Common Area",
            "Noticeboard",
            "From£154/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148818/a6715c3e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148814/a224ed64.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148818/a6715c3e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148818/a6715c3e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/ty-pont-haearn-cardiff-2002122768050?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "a8836a2494824a15a27a11b9e5f79609"
    },
    {
        "room_name": "Blackweir Lodge, Cardiff",
        "location": "Blackweir Terrace, Cardiff, CF10 3EY",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "WIFI",
            "Vending Machine",
            "Laundry Facility",
            "Laundry",
            "Recycling",
            "Cinema Room",
            "From£156/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148811/9de392c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148809/6e81bd1f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148811/9de392c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148811/9de392c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/blackweir-lodge-2002120760706?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "0310ef50957d4f729dd9a1892b98c494"
    },
    {
        "room_name": "The Bakery, Cardiff",
        "location": "Pendyris St, Cardiff, CF11 6YY",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wi-Fi",
            "CCTV",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "Bike Storage",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148807/2a64a155.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148806/8ea79d38.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148807/2a64a155.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148807/2a64a155.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-bakery-cardiff-2002120272378?ref=search&regionId=69",
        "city": "Cardiff",
        "room_id": "af639f667f4646b4b0348c938c935ffb"
    },
    {
        "room_name": "St Davids, Swansea",
        "location": "New Cut Rd, Swansea, SA1 2DN",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Game Room",
            "Best Payment Plan",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138109/47e40ab5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134209/e770cdb1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138109/47e40ab5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138109/47e40ab5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-davids-swansea-1608304447328?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "0a24d5acd5b545be96d1c743bf529a0e"
    },
    {
        "room_name": "Coppergate, Swansea",
        "location": "The Kingsway, Swansea, SA1 5JZ",
        "details": [
            "18 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Game Room",
            "Games Zone",
            "Maintenance",
            "Common Room",
            "Study Spaces",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144469/38507bbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144469/925fddd9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144469/38507bbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144469/38507bbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/coppergate-swansea-1904110655738?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "720971de9c28469883fdf931169a90ab"
    },
    {
        "room_name": "Seren, Swansea",
        "location": "Alexandra Rd, Swansea, SA1 5BD",
        "details": [
            "16 Room Options",
            "5 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Study Area",
            "Game Room",
            "Bills Included",
            "From£148/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/264157/336d92b0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/183890/aee37554.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/264157/336d92b0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/264157/336d92b0.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/seren-swansea-2105224670128?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "ef42d6cc8d564ab2a07df038d43f2bdf"
    },
    {
        "room_name": "Crown Place, Swansea",
        "location": "Kings Rd, Swansea, SA1 8PH",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema",
            "Laundry Facility",
            "Bills Included",
            "Games Room",
            "From£164/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/208274/4362d660.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/208274/0d935885.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/208274/4362d660.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/208274/4362d660.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/crown-place-swansea-2202087997250?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "24e45953a37647478b15a60fb5158e1e"
    },
    {
        "room_name": "True Swansea, Swansea",
        "location": "Morfa Rd, Swansea, SA1 2FD",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Zones",
            "Laundry Facilities",
            "Cycle Storage",
            "Garden",
            "Cinema",
            "From£184/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931440/cac6ea6f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147707/5dc3038c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931440/cac6ea6f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931440/cac6ea6f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-swansea-1912076432225?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "f4869b1e32fc4a74a03672b9f4055758"
    },
    {
        "room_name": "Oldway Centre, Swansea",
        "location": "High St, Swansea, SA1 5AQ",
        "details": [
            "29 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Game Room",
            "Laundry Facility",
            "Lounge",
            "Laundry",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/196280/8f5118e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143136/65714987.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/196280/8f5118e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/196280/8f5118e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/oldway-centre-swansea-1811150208901?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "1c43f39b3b5d4e00ab34cf205087340a"
    },
    {
        "room_name": "Ty Nant, Swansea",
        "location": "High St, Swansea, SA1 1AD",
        "details": [
            "21 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Vending Machine",
            "Laundry Facility",
            "Study Area",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/259361/8e30bc12.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/259361/ea9b2818.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/259361/8e30bc12.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/259361/8e30bc12.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/ty-nant-sawnsea-2303179278198?ref=search&regionId=64",
        "city": "Swansea",
        "room_id": "a056bc993e314d15905672b3e9123879"
    },
    {
        "room_name": "Edinburgh College Residence, Edinburgh",
        "location": "W Granton Rd, Edinburgh, EH5 1QE",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Source",
            "Vending Machines",
            "Bike Storage",
            "Bike Storage",
            "On-Site Laundry",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/257540/4adfa3fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/257540/e70a8b19.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/257540/4adfa3fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/257540/4adfa3fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/edinburgh-college-residence-edinburgh-2302249688697?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "8bbfe8a519cf4aa68c0937d1983d5ef4"
    },
    {
        "room_name": "Haymarket, Edinburgh",
        "location": "W Park Pl, Edinburgh, EH11 2EE",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Study Area",
            "Kitchen",
            "Onsite Gym",
            "From£200/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/138352/100ed241.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138352/4f32aec5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138352/100ed241.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/138352/100ed241.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/nido-haymarket-1712073342079?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "fb5289473a714a14ae260cd5396a5730"
    },
    {
        "room_name": "Scotland Nest Straits Meadow Edinburgh, Edinburgh",
        "location": "London Rd, Edinburgh, EH7 6AA",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bike Storage",
            "Laundry Facility",
            "Game Room",
            "TV",
            "Free WiFi",
            "From£215/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/221803/67f50fc4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221803/f392f789.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/221803/67f50fc4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/221803/67f50fc4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/londonnest-straits-meadow-edinburgh-2207029823387?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "c1e77680451c442f8ca8ccdf1a91fcba"
    },
    {
        "room_name": "Norton’s Student Living, Edinburgh",
        "location": "Montrose Terrace, Edinburgh, EH7 5AF",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Cinema",
            "Wi-Fi",
            "Wi-Fi",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1146953/bb309087.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1146846/27eec659.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1146953/bb309087.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1146953/bb309087.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/norton-s-student-living-edinburgh-2407030575802?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "9b6a4a9ebc404eb7bb6281fa5ef2a190"
    },
    {
        "room_name": "Vita Student Iona Street, Edinburgh",
        "location": "Iona St, Edinburgh, EH6 8SW",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry",
            "Cinema",
            "Gardens",
            "Pool Table",
            "Bike Store",
            "Free Events",
            "From£323/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/240147/9b4bdac8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/240115/14ced3de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/240147/9b4bdac8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/240147/9b4bdac8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iona-street-edinburgh-2211118074596?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "620a347c86a4412cabbd07a6bc1e208b"
    },
    {
        "room_name": "Huxley Studios, Edinburgh",
        "location": "Stanley Pl, Edinburgh, EH7 5AD",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "From£260/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200854/fe360b2c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200853/f37b1f65.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200854/fe360b2c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200854/fe360b2c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/huxley-studios-edinburgh-2111260977116?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "195b53cc6d3c42d3b1a9fbe5decb065f"
    },
    {
        "room_name": "iQ Fountainbridge, Edinburgh",
        "location": "Dundee St, Edinburgh, EH11 1AB",
        "details": [
            "16 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Lounge",
            "Cinema",
            "Free Gym",
            "From£264/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/241081/2f790c5d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132338/7c1405d7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/241081/2f790c5d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/241081/2f790c5d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-fountainbridge-edinburgh-1608300685810?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "ee12ef4b098c4d508c77fe3185b30f54"
    },
    {
        "room_name": "iQ Grove, Edinburgh",
        "location": "Grove St, Edinburgh, EH3 8FD",
        "details": [
            "11 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Laundry Facility",
            "Lounge",
            "Free Gym",
            "From£269/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/177227/efc30e26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132329/bd656c34.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/177227/efc30e26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/177227/efc30e26.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-grove-edinburgh-1608300223545?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "75aab16863ae4585811b3dd18cc232a8"
    },
    {
        "room_name": "Portsburgh Court, Edinburgh",
        "location": "Lady Lawson St, Edinburgh, EH3 9DH",
        "details": [
            "15 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Elevators",
            "From£279/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/167899/ff92af76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141307/dba6c20d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/167899/ff92af76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/167899/ff92af76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/portsburgh-court-edinburgh-1805183221385?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "fc4e1296a0fd4714bc0f0f2066658cdc"
    },
    {
        "room_name": "iQ Elliott House, Edinburgh",
        "location": "Hillside Cres, Edinburgh, EH7 5EA",
        "details": [
            "20 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Central Location",
            "Bills Included",
            "Study Area",
            "From£283/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/923491/035ad65a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132360/534eaa23.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/923491/035ad65a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/923491/035ad65a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-elliott-house-edinburgh-1608309486540?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "f8a55e1a24bc4f80b7830a6fc85951d9"
    },
    {
        "room_name": "Vita Student Fountainbridge, Edinburgh",
        "location": "Fountainbridge, Edinburgh, EH3 9QG",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Poker Room",
            "Bike Store",
            "From£410/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139885/9733390a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139880/f5bfa262.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139885/9733390a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139885/9733390a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-edinburgh-1803310226165?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "75255a1b1d8446639fe1d7f0f7ee5d27"
    },
    {
        "room_name": "Westfield, Edinburgh",
        "location": "Westfield Rd, Edinburgh, EH11 2QB",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Gym",
            "Study Area",
            "Game Area",
            "Gym",
            "Study Area",
            "Games Area",
            "Common Area",
            "From£217/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/211172/9d018713.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211172/b03eb7cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211172/9d018713.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/211172/9d018713.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/westfield-edinburgh-2203118532486?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "4c644407db514031862905cfeab75a53"
    },
    {
        "room_name": "Haddington Place, Edinburgh",
        "location": "Edinburgh, EH7 4AG",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Wi-Fi",
            "Gym",
            "Bills Included",
            "Wi-Fi",
            "Free On-Site Gym",
            "From£315/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132378/4ba1a0a5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132363/7f86c9b3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132378/4ba1a0a5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132378/4ba1a0a5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/haddington-place-edinburgh-1608305073349?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "907f52c750444c7fa7cd68a308608c0e"
    },
    {
        "room_name": "iQ The Mont, Edinburgh",
        "location": "Rillbank Terrace, Edinburgh, EH9 1FA",
        "details": [
            "12 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Cinema",
            "Free Gym",
            "Cinema Room",
            "Social Spaces",
            "From£318/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/265158/b2a3df98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/245874/f4355da4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/265158/b2a3df98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/265158/b2a3df98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-mont-edinburgh-2212072985470?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "ee6b4627933b4680878520af2807b126"
    },
    {
        "room_name": "Brewers Court, Edinburgh",
        "location": "Calton Rd, Edinburgh, EH8 8DP",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Study Area",
            "Communal Lounge",
            "Laundry Facility",
            "From£365/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/953903/529aa7e9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/168140/d8828592.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/953903/529aa7e9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/953903/529aa7e9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/brewer-s-court-2011207564999?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "e832176d30bc4d0995ce1bd63024018f"
    },
    {
        "room_name": "Gorgie, Edinburgh",
        "location": "Gorgie Rd, Edinburgh, EH11 3AR",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Study Area",
            "Bills Included",
            "Game Room",
            "Games Area",
            "Common Area",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/211171/5a42260b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211171/1f6644e2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/211171/5a42260b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/211171/5a42260b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/gorgie-edinburgh-2203114677572?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "96971d78aa104e86877eaa5fa4f78bc5"
    },
    {
        "room_name": "Straits Meadow, Edinburgh",
        "location": "London Rd, Edinburgh, EH7 6AA",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "On Site Maintenance",
            "Laundry Facility",
            "Cleaner",
            "Recycling",
            "Games Room",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/170410/18f8eb0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170410/85cbbc80.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170410/18f8eb0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/170410/18f8eb0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/straits-meadow-2012058311673?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "b166380e7b6b4aecb74973639505d1a6"
    },
    {
        "room_name": "Chalmers Street, Edinburgh",
        "location": "Chalmers St, Edinburgh, EH3 9FE",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Not Available",
            "Laundry",
            "Recycling",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142352/7ccbc0ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142350/36c175d0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142352/7ccbc0ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142352/7ccbc0ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/chalmers-street-edinburgh-1806222540976?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "cfae9a3d4c2c4120af0cdc91d61761b9"
    },
    {
        "room_name": "New Park, Edinburgh",
        "location": "Bothwell St, Edinburgh, EH7",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Cinema",
            "Bills Included",
            "Lounge",
            "Games Area",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/170833/04052b92.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136635/3b1958a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/170833/04052b92.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/170833/04052b92.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/new-park-edinburgh-1707285341704?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "4acb1fd1937e41e6b487195041fa2c3c"
    },
    {
        "room_name": "The Old Printworks, Edinburgh",
        "location": "Bernard Terrace, Edinburgh, EH8 9NX",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry",
            "Bike Storage",
            "Gym",
            "Laundry",
            "Recycling",
            "From£265/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142356/29860c8b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142355/94301ff1.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142356/29860c8b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142356/29860c8b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-old-printworks-1806227279380?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "d60861bf785e456da224e4f89a647ba6"
    },
    {
        "room_name": "The Keel Houses, Edinburgh",
        "location": "Lower Gilmore Pl, Edinburgh, EH3 9NY",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Cinema",
            "Bills Included",
            "Laundry Facility",
            "Mahjong",
            "Ping Pong",
            "Courtyard",
            "From£295/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/250180/79073a00.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/250175/f1115005.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/250180/79073a00.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/250180/79073a00.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-keel-houses-edinburgh-2301116150044?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "b3cbfab9e4354bbe85c53f0867abe1f1"
    },
    {
        "room_name": "Mayfield Residences, Edinburgh",
        "location": "Mayfield Rd, Edinburgh, EH9 3BE",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cinema Room",
            "Bike Storage",
            "From£309/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/200058/fe8c1eec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200040/efdf3330.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/200058/fe8c1eec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/200058/fe8c1eec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mayfield-residences-2111226782435?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "fd0da6318a0e42248278abc04428e99a"
    },
    {
        "room_name": "Murieston Crescent, Edinburgh",
        "location": "Edinburgh, EH11",
        "details": [
            "8 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Game Room",
            "Pinboard",
            "Games Area",
            "Study Space",
            "From£245/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/218428/e9319272.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/201657/c7d55e41.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/218428/e9319272.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/218428/e9319272.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/murieston-crescent-edinburgh-2112029753087?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "62c3b93481e1493fa10cb55999cf1e56"
    },
    {
        "room_name": "Panmure Court, Edinburgh",
        "location": "Calton Rd, Edinburgh, EH8 8DP",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Best Payment Plan",
            "Laundry Facility",
            "Gym",
            "BBQ",
            "Aircon",
            "Elevators",
            "Study Area",
            "From£289/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141313/b2377858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141312/578e30a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141313/b2377858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141313/b2377858.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/panmure-court-edinburgh-1805193858225?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "93646b8d6cf94315a7151fc38541ce7c"
    },
    {
        "room_name": "Student Castle Edinburgh, Edinburgh",
        "location": "Causewayside, Edinburgh, EH9 1PH",
        "details": [
            "2 Offers",
            "Pay In Instalment",
            "Gym",
            "Bike Storage",
            "Bills Included",
            "Gym",
            "Study Area",
            "Common Area",
            "Bike Storage",
            "Ultra-Fast WiFi",
            "From£337/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/165309/bb0e1bc5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/165293/742a74bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/165309/bb0e1bc5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/165309/bb0e1bc5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/student-castle-edinburgh-2011035052129?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "0ca7351647aa48b99694d441e370f83f"
    },
    {
        "room_name": "Lady Nicolson Court, Edinburgh",
        "location": "Potterrow, Edinburgh, EH8 9BT",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Communal Lounge",
            "Wifi",
            "Elevators",
            "Study Area",
            "From£399/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/948971/e2e21d99.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141315/4f28afa0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/948971/e2e21d99.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/948971/e2e21d99.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lady-nicolson-court-edinburgh-1805199695222?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "c31417e4b5c64706b8698683291927bf"
    },
    {
        "room_name": "The Bridge House, Edinburgh",
        "location": "Fountainbridge, Edinburgh, EH3 9QG",
        "details": [
            "2 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry",
            "Recycling",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "Bike Storage",
            "From£220/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/158478/ecc4e0f6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142348/713733bc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/158478/ecc4e0f6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/158478/ecc4e0f6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-bridge-house-edinburgh-1806220982740?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "ec0d5a3fa73a429088ea6c9006cce708"
    },
    {
        "room_name": "Sugarhouse Close, Edinburgh",
        "location": "Holyrood Rd, Edinburgh, EH8 8FF",
        "details": [
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Bills Included",
            "Laundry",
            "Recycling",
            "Common Area",
            "Bike Storage",
            "From£265/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142354/9f204340.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137248/181f9c36.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142354/9f204340.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142354/9f204340.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sugarhouse-close-1709061389713?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "24e953281c18454cb4645939dd6d5433"
    },
    {
        "room_name": "Potterrow, Edinburgh",
        "location": "Potterrow, Edinburgh, EH8 9BL",
        "details": [
            "10 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Lounge",
            "Laundry",
            "Games Room",
            "Study Rooms",
            "Cycle Store",
            "From£174/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132326/225782f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132325/fbbf0d20.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132326/225782f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132326/225782f0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/potterrow-1608308572135?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "a851560b3f2b4dea858825458f2b081a"
    },
    {
        "room_name": "Yugo Arran House, Edinburgh",
        "location": "Drysdale Rd, Edinburgh, EH11 1FA",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Cinema",
            "Courtyard",
            "Common Room",
            "Gaming Room",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148516/c40c25a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132352/0aca650b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148516/c40c25a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148516/c40c25a0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/arran-house-edinburgh-1608303088340?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "134469535cdc490980269df1a8cc55a0"
    },
    {
        "room_name": "Yugo Canal Point, Edinburgh",
        "location": "W Tollcross, Edinburgh, EH3 9QW",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Common Room",
            "On-Site Laundry",
            "Study Room",
            "Study Room",
            "Common Room",
            "From£222/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132392/07f5b7b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132389/96649b0e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132392/07f5b7b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132392/07f5b7b5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canal-point-1608300378027?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "2e302df21ebe4249a3c95c1bebad19c6"
    },
    {
        "room_name": "Yugo Brae House, Edinburgh",
        "location": "Abbeyhill, Edinburgh, EH8 8EH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Social Events",
            "Laundry Facility",
            "Courtyard",
            "Study Room",
            "From£242/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/158680/121848e4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/158677/41f59a70.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/158680/121848e4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/158680/121848e4.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/brae-house-2008223544129?ref=search&regionId=71",
        "city": "Edinburgh",
        "room_id": "bc7c4840c81746c9b839f3ca64656b3e"
    },
    {
        "room_name": "iQ Student Quarter, Salford",
        "location": "Seaford Rd, Salford, M6 6FN",
        "details": [
            "9 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Study Area",
            "Bills Included",
            "TV",
            "Lounge",
            "From£207/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143542/0c23178d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143539/4d217d29.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143542/0c23178d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143542/0c23178d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-student-quarter-salford-1811299838700?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "ed65edf7234d40cab5fe65f3abd5b199"
    },
    {
        "room_name": "True Manchester Salford, Salford",
        "location": "King William St, Salford, M50 3BG",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "CCTV Cameras",
            "Cinema",
            "Courtyard",
            "From£232/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1168867/d6bfae9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1167294/dae74bec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1168867/d6bfae9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1168867/d6bfae9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/true-manchester-salford-salford-2407152675927?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "887c851aa04d4dadb4d134c704d2c6bf"
    },
    {
        "room_name": "Canvas River Street Tower, Manchester",
        "location": "River St, Manchester, M15 5GQ",
        "details": [
            "13 Room Options",
            "4 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Study Area",
            "Cinema",
            "Games Room",
            "Study Spaces",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147766/4f6229f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147766/6efe3aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147766/4f6229f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147766/4f6229f1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/canvas-river-street-tower-manchester-1912099136519?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "1a7e7a7e65ed475da801ac5ba993a9ee"
    },
    {
        "room_name": "City Edge, Manchester",
        "location": "Ardwick Green S, Manchester, M13 9XE",
        "details": [
            "4 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Parking",
            "Laundry Room",
            "Parking",
            "Quiet Area",
            "Laundry Room",
            "Central Area",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148716/ec04bdec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148710/bc379c16.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148716/ec04bdec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148716/ec04bdec.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/city-edge-2002056940227?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "6a4ba5c69e6244eebaf96f4bb6d12fa4"
    },
    {
        "room_name": "Ladybarn House, Manchester",
        "location": "Moseley Rd., Manchester, M14 6ND",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Bills Included",
            "Laundry Facility",
            "CCTV",
            "Garden",
            "From£160/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/958587/ba3f2c3a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958587/9ef1fee7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/958587/ba3f2c3a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/958587/ba3f2c3a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/lady-barn-house-manchester-2402165398553?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "c0f14bfca9e94038bdf4aa6c2d1bd2be"
    },
    {
        "room_name": "Vita Student Circle Square, Manchester",
        "location": "Nobel Wy., Manchester, M1 7FA",
        "details": [
            "1 Room Option",
            "5 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Cinema",
            "Study Room",
            "Movie Room",
            "Free Events",
            "Bike Rental",
            "From£318/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133570/a35bdaf3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133570/9ebf19e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133570/a35bdaf3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133570/a35bdaf3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-circle-square-manchester-1608300741843?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "b4193b6200ff4c43bf4d64f1f17d6e6b"
    },
    {
        "room_name": "Vita Student First Street, Manchester",
        "location": "Jack Rosenthal St, Manchester, M15 4RB",
        "details": [
            "2 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Swimming Pool",
            "Laundry Facility",
            "Movie Room",
            "Free Events",
            "From£312/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141875/9d00839e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139896/41a641a1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141875/9d00839e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141875/9d00839e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-first-street-manchester-1803316763602?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "bf432bdd563840eaa5f0b1492a27a7d2"
    },
    {
        "room_name": "Riverside House, Manchester",
        "location": "Blackfriars Rd, Salford, M3 7FU",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bills Included",
            "CCTV",
            "On-Site Laundry",
            "Broadband",
            "Cinema Room",
            "From£204/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1102984/4c4c7d92.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102887/7334c541.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1102984/4c4c7d92.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1102984/4c4c7d92.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/riverside-house-manchester-2406084247915?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "2f215c0404704d8abee47073bafa0ed5"
    },
    {
        "room_name": "Harry Lord House, Manchester",
        "location": "Humphrey Rd, Manchester, M16",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Communal Laundry",
            "CCTV",
            "Bills Included",
            "From£215/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/924898/86a4738e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924898/4bc294ca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/924898/86a4738e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/924898/86a4738e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/harry-lord-house-manchester-2311159017965?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "c9d8da6ba36148ceb28b64c408439320"
    },
    {
        "room_name": "Moss Court, Manchester",
        "location": "Moss Ln E, Manchester, M14 4PQ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "On-Site Laundry",
            "All Bills Included",
            "CCTV",
            "Free Wifi",
            "Reception",
            "Central Area",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148701/6862d123.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148701/65a33e23.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148701/6862d123.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148701/6862d123.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/moss-court-2002051724372?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "5b093e255d754e0fb52f791a3532f15f"
    },
    {
        "room_name": "Dwell 121 Princess Street, Manchester",
        "location": "Princess St, Manchester, M1 7AG",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Laundry Facility",
            "Underfloor Heating",
            "Premium Living",
            "From£251/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/926457/cf4b70dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148685/4c425a8b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/926457/cf4b70dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/926457/cf4b70dc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/dwell-121-princess-street-2002038737937?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "4fc065ec06e24d78b2cbd35949443877"
    },
    {
        "room_name": "iQ Lambert & Fairfield House, Manchester",
        "location": "Granby Row, Manchester, M60 7LH",
        "details": [
            "16 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Game Room",
            "Bills Included",
            "TV",
            "Lounge",
            "From£235/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/196913/07411fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133583/50c12453.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/196913/07411fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/196913/07411fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-lambert-fairfield-house-manchester-1608303994293?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "7e61c450576a435e9ada7114cf643a36"
    },
    {
        "room_name": "iQ Hollingworth House, Manchester",
        "location": "Whitworth St W, Manchester, M1 3GW",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Cinema",
            "Bills Included",
            "Game Room",
            "Free Gym",
            "From£298/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147169/5b582ff8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143472/cd6b1bff.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147169/5b582ff8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147169/5b582ff8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-hollingworth-house-manchester-1811275429673?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "d175bd19404343f7a383e5515d209695"
    },
    {
        "room_name": "iQ Fletcher House, Manchester",
        "location": "Fairfield St, Manchester, M1 3GN",
        "details": [
            "3 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "Laundry Facility",
            "Free Gym",
            "From£341/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143471/3730ecf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143468/dd026983.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143471/3730ecf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143471/3730ecf1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-fletcher-house-manchester-1811277697576?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "fc92f75249ab474199531b6cba143853"
    },
    {
        "room_name": "iQ Wilmslow Park, Manchester",
        "location": "Hathersage Rd, Manchester, M13 0JQ",
        "details": [
            "8 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Free Gym",
            "From£260/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133568/31e135e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133561/6155c188.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133568/31e135e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133568/31e135e0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-wilmslow-park-house-manchester-1608305817951?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "dd79671288c44c69a53cee683c76a232"
    },
    {
        "room_name": "Britannia Students Heald Court, Manchester",
        "location": "Heald Grove, Manchester, M14 4PE",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Lounge Area",
            "Reception",
            "Lounge Area",
            "Outdoor Area",
            "Bike Storage",
            "From£283/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1200150/b1bbf0a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200117/c2799fc6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1200150/b1bbf0a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1200150/b1bbf0a9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/britannia-students-heald-court-manchester-2408012148903?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "63f63381c3124447b921c80eb6dd9d91"
    },
    {
        "room_name": "iQ Manchester Gardens, Manchester",
        "location": "Hope Rd, Manchester, M14 5ET",
        "details": [
            "11 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Onsite Car Parking",
            "Laundry Facility",
            "Bills Included",
            "Garden",
            "Study Area",
            "From£237/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144826/2d533576.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144005/4f07cd31.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144826/2d533576.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144826/2d533576.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-manchester-gardens-manchester-1901296749601?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "91945beb5a0a443b9aa1feb8c4259b04"
    },
    {
        "room_name": "iQ Kerria Apartments, Manchester",
        "location": "Hathersage Rd, Manchester, M13 0JQ",
        "details": [
            "17 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Bills Included",
            "Study Area",
            "Lounge",
            "From£313/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143482/9cbc4fbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143481/0363c22f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143482/9cbc4fbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143482/9cbc4fbc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-kerria-apartments-manchester-1811271827877?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "5a9ed36866b9427599ee3e0104021183"
    },
    {
        "room_name": "New Medlock House, Manchester",
        "location": "Chester St, Manchester",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machines",
            "Laundry Facility",
            "Bike Storage",
            "Laundry",
            "Recycling",
            "From£215/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142634/51d83831.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142605/1f5d53d0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142634/51d83831.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142634/51d83831.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/new-medlock-house-manchester-1808130542856?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "759d90fadc7c4f5091caac35a016bb2e"
    },
    {
        "room_name": "Square Gardens, Manchester",
        "location": "Manchester, M15",
        "details": [
            "15 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wifi",
            "Gym",
            "Bills Included",
            "Media",
            "Lounge",
            "Cinema Room",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1033604/75e3e751.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1030839/3a9b016f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1033604/75e3e751.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1033604/75e3e751.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/square-gardens-manchester-2404189841822?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "4b8d4f267a4e485985313c26180f6d91"
    },
    {
        "room_name": "Bridgewater Heights, Manchester",
        "location": "Great Marlborough St, Manchester, M1",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "From£223/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/151750/1fd91c9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148929/7bd142f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/151750/1fd91c9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/151750/1fd91c9f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bridgewater-heights-manchester-2002145605325?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "c4c8ec77121849f98ef5c43545f84abf"
    },
    {
        "room_name": "Piccadilly Point, Manchester",
        "location": "Berry St, Manchester, M1 2AD",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Gym",
            "From£172/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142639/9b0157d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142615/ff42b7cf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142639/9b0157d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142639/9b0157d3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/piccadilly-point-manchester-1808135757042?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "88a6a3c9218b4fd9a739ef63316316fd"
    },
    {
        "room_name": "Parkway Gate, Manchester",
        "location": "Chester St, Manchester, M15",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Vending Machine",
            "Laundry",
            "Recycling",
            "From£255/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/142611/5be420fe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142610/b4f8e511.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142611/5be420fe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/142611/5be420fe.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/parkway-gate-manchester-1808131715500?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "7813f79cd88445c58efb41f4bcb37947"
    },
    {
        "room_name": "Artisan Heights, Manchester",
        "location": "New Wakefield St, Manchester, M1 5AA",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "From£275/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/159720/1c8cf836.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149159/aef5df46.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/159720/1c8cf836.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/159720/1c8cf836.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/artisan-heights-manchester-2002243858684?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "ae86712be240425e8d70592a3b510cd1"
    },
    {
        "room_name": "Thurloe Street, Manchester",
        "location": "Thurloe St, Manchester, M14 5SG",
        "details": [
            "28 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Ample Storage",
            "Contemporary Living Space",
            "Stylish Furnishings",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1110700/9aa88675.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1095271/0a9940db.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1110700/9aa88675.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1110700/9aa88675.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/thurloe-street-manchester-2405309046201?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "fc42f953dc4246f5a926367c59a547f7"
    },
    {
        "room_name": "Manchester Corn Exchange, Manchester",
        "location": "Exchange Sq, Manchester",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Sauna",
            "Laundry",
            "Steam Room",
            "From£399/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156813/c9e81aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156799/6d362faa.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156813/c9e81aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156813/c9e81aab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manchester-corn-exchange-manchester-2007313820155?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "348de8e5af6b4628894be90c29969a8e"
    },
    {
        "room_name": "Element, Manchester",
        "location": "Cambridge St, Manchester, M15 6BS",
        "details": [
            "5 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Foldable Bikes",
            "Laundry Facility",
            "Study Area",
            "TV Lounge",
            "Cinema Room",
            "From£330/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/219473/9fdc89de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219472/f0a9c92c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/219473/9fdc89de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/219473/9fdc89de.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/element-manchester-2206066790529?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "833f1b8eea594098852a6ee55daf7e66"
    },
    {
        "room_name": "MSV South, Manchester",
        "location": "Great Western St, Manchester, M14 4AH",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Onsite CCTV",
            "Bills Included",
            "Table-Tennis",
            "100 Mbps Internet",
            "Social Events Calendar",
            "From£210/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/161040/54bd30bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139245/5b263891.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/161040/54bd30bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/161040/54bd30bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/msv-south-manchester-1802136140893?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "a3b08a3c73744a3e9aa368d1c512f489"
    },
    {
        "room_name": "Manchester City, Manchester",
        "location": "Princess St, Manchester, M1 4JY",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Gym",
            "Sauna",
            "Laundry",
            "From£425/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/156800/402f1c7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156800/6ef50870.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/156800/402f1c7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/156800/402f1c7c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manchester-city-manchester-2007318515993?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "0cf99b8d3eb74f7596a2a6ade4fa8f04"
    },
    {
        "room_name": "Mill Point, Manchester",
        "location": "Berry St, Manchester, M1 2AR",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Vending Machine",
            "Laundry Facility",
            "Bike Storage",
            "Wi-Fi",
            "Laundry",
            "Recycling",
            "From£158/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148954/4cc4d944.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148952/bdd057db.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148954/4cc4d944.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148954/4cc4d944.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mill-point-manchester-2002142172845?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "89bb3bedc9004318bbdd95608b617165"
    },
    {
        "room_name": "Sir Charles Groves Hall, Manchester",
        "location": "Booth St W, Manchester, M15 6PF",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Vending Machine",
            "Laundry",
            "Recycling",
            "From£190/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148945/dd3689d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148945/8215fa38.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148945/dd3689d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148945/dd3689d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/sir-charles-groves-hall-manchester-2002142435086?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "a5c411c3bf4044c88d63158d5d67337b"
    },
    {
        "room_name": "Kincardine Court, Manchester",
        "location": "Kincardine Rd, Manchester, M13 9SY",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Recycling",
            "Bike Storage",
            "Study Area",
            "Wi-Fi",
            "Recycling",
            "Common Area",
            "From£159/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/203876/744ffcc8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/142608/6df9f63f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/203876/744ffcc8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/203876/744ffcc8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/kincardine-court-manchester-1808137208545?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "3e4f29d8507c4cff8ee6d9a51b6955c5"
    },
    {
        "room_name": "Manchester Student Village (MSV), Manchester",
        "location": "Lower Chatham St, Manchester",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Game Room",
            "Study Area",
            "Bills Included",
            "Study Room",
            "TV & PS4 Area",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139236/74dc2ded.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139227/c24fe643.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139236/74dc2ded.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139236/74dc2ded.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/manchester-student-village-msv-manchester-1802136315744?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "4480afc87d8149e5b23a15469c05fab1"
    },
    {
        "room_name": "Weston Court, Manchester",
        "location": "Cromwell Range, Manchester, M14 5JR",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facilities",
            "Bike Storage",
            "Bills Included",
            "Outdoor Seating Area",
            "From£147/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139257/d9431fab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139255/43d1c4e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139257/d9431fab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139257/d9431fab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/weston-court-manchester-1802149414341?ref=search&regionId=76",
        "city": "Salford",
        "room_id": "6d394a6650bf40b1b2c51cbec803a7df"
    },
    {
        "room_name": "Yugo Beckley Point, Plymouth",
        "location": "Cobourg St, Plymouth, PL1 1SP",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Cinema",
            "Bills Included",
            "Sky Lounge",
            "Study Room",
            "From£134/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146481/5385e82c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146456/46200c58.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146481/5385e82c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146481/5385e82c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/beckley-point-1907236997690?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "be6b9de4730e498e96a305b4e75eb0d1"
    },
    {
        "room_name": "iQ Astor House, Plymouth",
        "location": "Notte St, Plymouth, PL1 2AQ",
        "details": [
            "15 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Cinema",
            "Game Room",
            "Bills Included",
            "Sky Lounge",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149164/5d574685.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143524/6fdeb8b0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149164/5d574685.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149164/5d574685.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-astor-house-plymouth-1811294156651?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "bc44c40d34fa49f49e609b73394ffe96"
    },
    {
        "room_name": "Central Point, Plymouth",
        "location": "Royal Parade, Plymouth, PL1 1DZ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Games Room",
            "Ultra-Fast WiFi",
            "Cycle Storage",
            "Games Area",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137855/68509768.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137855/cca1dccc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137855/68509768.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137855/68509768.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/central-point-plymouth-1710258578817?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "6ca878e43c3a40cea32b5f2c4467fcba"
    },
    {
        "room_name": "Plymouth Cross House, Plymouth",
        "location": "Raleigh St, Plymouth, PL1 1ET",
        "details": [
            "13 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Cinema",
            "Game Room",
            "Games Room",
            "Cinema Room",
            "From£148/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144703/f40c8edb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144694/5c1f317e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144703/f40c8edb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144703/f40c8edb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/plymouth-cross-house-plymouth-1905074875904?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "4daa21c5822743c19903ccccb7af874a"
    },
    {
        "room_name": "St Augustine's House, Plymouth",
        "location": "Alexandra Rd, Plymouth, PL4 7EG",
        "details": [
            "7 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Bike Storage",
            "Bills Included",
            "CCTV",
            "Internet",
            "Bike Storage",
            "Roof Terrace",
            "From£315/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/133866/2046d1cd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133862/692e4505.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/133866/2046d1cd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/133866/2046d1cd.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-augustine-s-house-1608306200738?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "ee159247377a4cc9b02501a2d4cc677a"
    },
    {
        "room_name": "Alexandra Works, Plymouth",
        "location": "Alexandra Rd, Plymouth, PL4 7DU",
        "details": [
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Cycle Storage",
            "Cinema",
            "Living Area",
            "Cycle Storage",
            "Ultra-Fast Wifi",
            "From£124/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137853/c03b5ddc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137853/f8645b4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137853/c03b5ddc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137853/c03b5ddc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/alexandra-works-plymouth-1710258548123?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "3a47dbbe226f4417877ea5d320474dd2"
    },
    {
        "room_name": "St Thomas Court, Plymouth",
        "location": "Gasking St, Plymouth, PL4 9AP",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Bills Included",
            "Study Area",
            "Cycle Storage",
            "Common Area",
            "Living Area",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137864/867d6b61.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137864/557d4118.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137864/867d6b61.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137864/867d6b61.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-thomas-court-plymouth-1710253312193?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "d8befc7bd560466092f6fcc6d1cefac4"
    },
    {
        "room_name": "Discovery Heights, Plymouth",
        "location": "Cobourg St, Plymouth, PL1 1UH",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Game Room",
            "Study Area",
            "Cycle Storage",
            "Games Area",
            "Common Area",
            "From£139/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137858/2a806051.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137858/753baa58.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137858/2a806051.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137858/2a806051.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/discovery-heights-plymouth-1710258691042?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "6e52cad2869b465f87dd68ab143f93d0"
    },
    {
        "room_name": "St Teresa House, Plymouth",
        "location": "Beaumont Rd, Plymouth, PL4 9AZ",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Game Room",
            "Bills Included",
            "TV Area",
            "Games Area",
            "Study Area",
            "From£142/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/137861/a47c3a51.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137861/9ce3f391.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137861/a47c3a51.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/137861/a47c3a51.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/st-teresa-house-plymouth-1710258348081?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "b9f370138d8947d4816eb2722794e9a9"
    },
    {
        "room_name": "The Reservoir, Plymouth",
        "location": "North Hill, Plymouth, PL4 8ET",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Modern Communal Area",
            "24/7 CCTV",
            "Bike Storage",
            "On-Site Management",
            "From£165/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/266078/82a7d1e5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266077/369c56a4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266078/82a7d1e5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/266078/82a7d1e5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-reservoir-plymouth-2305035818655?ref=search&regionId=74",
        "city": "Plymouth",
        "room_id": "3512c8f20bf8490fb0d8bc68ea99d7f4"
    },
    {
        "room_name": "Mealmarket Exchange, Aberdeen",
        "location": "Mealmarket St, Aberdeen, AB24 5SW",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bike Storage",
            "Bills Included",
            "Elevator",
            "Bike Storage",
            "From£115/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/922929/2e7578c3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141297/f7a88814.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/922929/2e7578c3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/922929/2e7578c3.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/mealmarket-exchange-aberdeen-1805186432439?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "87efc6227d7345b394c01796a8fbc986"
    },
    {
        "room_name": "Pittodrie Street, Aberdeen",
        "location": "Pittodrie St, Aberdeen, AB24 5AD",
        "details": [
            "13 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "In-House Housekeeping",
            "Laundry Facility",
            "High Speed WiFi",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143883/cd7a5e76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143883/12e379e4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143883/cd7a5e76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143883/cd7a5e76.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/pittodrie-street-aberdeen-1901147089020?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "bed419b94add4a9fa8ca81f4f8b077a6"
    },
    {
        "room_name": "Justice Mill Studios, Aberdeen",
        "location": "Justice Mill Ln, Aberdeen, AB11 6EQ",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "All Utility Bills Included",
            "Bike Storage",
            "Study Area",
            "Gym",
            "Cinema Room",
            "From£166/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/245352/f4398b4f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/245352/bfdca4ba.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/245352/f4398b4f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/245352/f4398b4f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/justice-mill-studios-aberdeen-2212058841801?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "52a5647f0d3749a78ce08e42e8488401"
    },
    {
        "room_name": "The Combworks, Aberdeen",
        "location": "George St, Aberdeen, AB25 3YB",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Utility Bills Included",
            "CCTV",
            "Secure Bike Storage",
            "House Puppy",
            "From£184/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/144516/507c77c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144516/497d8438.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144516/507c77c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/144516/507c77c0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-combworks-aberdeen-1904165250733?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "550abdf2f5f24119a2cfbe97ef5dc23d"
    },
    {
        "room_name": "Caledon Court, Aberdeen",
        "location": "Auchinyell Rd, Aberdeen, AB10 7FU",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Game Room",
            "Table Football",
            "Pool",
            "Games Room",
            "From£140/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148695/1a2c99ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138958/57877b43.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148695/1a2c99ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148695/1a2c99ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/caledon-court-aberdeen-1802024461796?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "4521264f0b4749558d90b9f41e902dc6"
    },
    {
        "room_name": "Spring Gardens, Aberdeen",
        "location": "Spring Garden, Aberdeen, AB25 1GN",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry",
            "Vending Machine",
            "Laundry",
            "Recycling",
            "From£84/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139279/0f1b94e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137756/974c6091.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139279/0f1b94e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139279/0f1b94e5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/spring-gardens-aberdeen-1710167055452?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "54c9150cbd39453d9aeda249a1a0adf6"
    },
    {
        "room_name": "ABeleven, Aberdeen",
        "location": "Willowbank Rd, Aberdeen, AB11 6AE",
        "details": [
            "4 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Garden",
            "Bike Storage",
            "Gym",
            "Garden",
            "Bike Storage",
            "Outdoor Area",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1273089/61f5062f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1273002/afe26aad.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1273089/61f5062f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1273089/61f5062f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/abeleven-aberdeen-2409113460067?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "7abf2f00a15748f6b9c3ddf7b9f8f96a"
    },
    {
        "room_name": "Powis Place, Aberdeen",
        "location": "Powis Pl, Aberdeen, AB25 3TX",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cleaner",
            "Courtyard",
            "Recycling",
            "From£140/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/944816/0bee6cbe.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/131988/c3d1bdca.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/944816/0bee6cbe.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/944816/0bee6cbe.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/powis-place-aberdeen-1608305674276?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "f68491c161854682b16c4684c35b2023"
    },
    {
        "room_name": "Causeway View, Aberdeen",
        "location": "Causewayend, Aberdeen, AB25 3DD",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Laundry",
            "Vending Machine",
            "Gym",
            "Wi-Fi",
            "Laundry",
            "From£178/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140143/9e03dd1b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/137759/feae6188.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140143/9e03dd1b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140143/9e03dd1b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/causeway-view-1710172366780?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "3cfa5a81d4254b99a2d736bb3eca12de"
    },
    {
        "room_name": "Trinity Court, Aberdeen",
        "location": "Pittodrie Pl, Aberdeen, AB24 5QP",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Washing Machine/Dryer",
            "Bike Storage",
            "Bills Included",
            "Parking",
            "Cleaning",
            "Appliances",
            "Maintenance",
            "From£92/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/173115/100e7789.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/145600/889e41ad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/173115/100e7789.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/173115/100e7789.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/trinity-court-1907039840057?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "a5140c21d89b4bc49e94d78dbeb15bdb"
    },
    {
        "room_name": "Linksfield, Aberdeen",
        "location": "Linksfield Rd, Aberdeen, AB24 5RJ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Parking",
            "Cleaning",
            "Bills Included",
            "TV",
            "Appliances",
            "Maintenance",
            "Gated Garden",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/206004/39f60277.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/145611/e68c3d25.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/206004/39f60277.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/206004/39f60277.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/linksfield-aberdeen-1907033959220?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "eab72b59551744aa9a8c739997ec3b52"
    },
    {
        "room_name": "Froghall, Aberdeen",
        "location": "Froghall Rd, Aberdeen, AB24 3JL",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Parking",
            "Cleaning",
            "Bike Storage",
            "Parking",
            "Cleaning",
            "Appliances",
            "Maintenance",
            "Gated Garden",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/210306/fc96e0e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/145608/82778279.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/210306/fc96e0e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/210306/fc96e0e8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/froghall-1907034305280?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "c5d908c64fbe49848ad0f2adb32be39e"
    },
    {
        "room_name": "207 King Street, Aberdeen",
        "location": "King St, Aberdeen, AB24 5AH",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Parking",
            "Bike Storage",
            "Laundry Room",
            "Parking",
            "Cleaning",
            "Large TV",
            "Appliances",
            "Bike Storage",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148320/9d2bff2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148320/860552fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148320/9d2bff2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148320/9d2bff2a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/king-street-aberdeen-2001172888345?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "5e5f9b3783ea4c30b5498a514c746aed"
    },
    {
        "room_name": "403 King Street, Aberdeen",
        "location": "King St, Aberdeen, AB24 3BZ",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Parking",
            "Bike Storage",
            "All Utility Bills Included",
            "Parking",
            "Cleaning",
            "Appliances",
            "Maintenance",
            "Bike Storage",
            "From£124/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/173054/e22576b7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/173053/5ad0ef21.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/173054/e22576b7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/173054/e22576b7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/403-king-street-2012211693285?ref=search&regionId=19",
        "city": "Aberdeen",
        "room_id": "4c3f4f12025d4a29aadd7c5d8257a8cc"
    },
    {
        "room_name": "Tramways, Chester",
        "location": "Crewe St, Chester, CH1 3DA",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Aircon",
            "Elevators",
            "Cafeteria",
            "From£174/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139141/1dfda927.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136881/39d728a3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139141/1dfda927.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139141/1dfda927.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/tramways-chester-1708052122857?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "cc88cc4508cf47a7a67cc86eb61a554c"
    },
    {
        "room_name": "Trinity Hall, Chester",
        "location": "George St, Chester, CH1 3EQ",
        "details": [
            "6 Room Options",
            "1 Offer",
            "No Visa No Pay",
            "Pay In Instalment",
            "Parking",
            "Laundry Facility",
            "Bills Included",
            "TV",
            "Cleaner",
            "Recycling",
            "From£125/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/266865/6b4e400e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132219/842bca0e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/266865/6b4e400e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/266865/6b4e400e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/trinity-halls-chester-1608301257519?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "e34682c760444091b06e70e83a237546"
    },
    {
        "room_name": "Waterside Court, Chester",
        "location": "Whipcord Ln, Chester, CH1 4GD",
        "details": [
            "5 Room Options",
            "1 Offer",
            "No Visa No Pay",
            "Pay In Instalment",
            "Parking",
            "Bills Included",
            "Laundry Facility",
            "TV",
            "Cleaner",
            "Recycling",
            "From£135/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/188807/61aaa10b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144578/18b5fe72.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/188807/61aaa10b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/188807/61aaa10b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/waterside-court-chester-1904200535319?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "6f2033e32c0c4866be93e8b6f757e087"
    },
    {
        "room_name": "Northgate Point, Chester",
        "location": "Trafford St, Chester, CH1 3HP",
        "details": [
            "5 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Cinema",
            "Study Space",
            "Communal Laundry",
            "From£160/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139333/4ebc4ff4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139333/1def92f8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139333/4ebc4ff4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139333/4ebc4ff4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/northgate-point-chester-1803070817196?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "107e632d269c4b8ba1b7681ca5cc89c2"
    },
    {
        "room_name": "The Towpath, Chester",
        "location": "Wharf View, Chester, CH1 4GX",
        "details": [
            "15 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Aircon",
            "Elevators",
            "Bike Storage",
            "From£169/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/136903/b885cd6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136891/f65835ff.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136903/b885cd6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/136903/b885cd6c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-towpath-chester-1708051689297?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "3541b099a7a649fab2dba5a039b3a868"
    },
    {
        "room_name": "Northgate Studios, Chester",
        "location": "Trafford St, Chester, CH1 3HP",
        "details": [
            "10 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "All Bills Included",
            "Study Space",
            "Cinema",
            "Communal Laundry",
            "From£187/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139666/124f2004.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139666/ad24957d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139666/124f2004.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139666/124f2004.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/northgate-studios-chester-1803231057428?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "ad268460f7114a0bba7fa5e608dfc819"
    },
    {
        "room_name": "Snowdon Hall, Wrexham",
        "location": "Vicarage Hill, Wrexham, LL13 7HN",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "BBQ Area",
            "Laundry Facility",
            "BBQ",
            "Bike Storage",
            "Outdoor Area",
            "Vending Machine",
            "From£117/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/134226/26449c61.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134222/6440be37.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/134226/26449c61.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/134226/26449c61.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/snowdon-hall-wrexham-1608309036814?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "dad03640caa144808365e3f5ac7a96b1"
    },
    {
        "room_name": "Abbeygate, Chester",
        "location": "Victoria Rd, Chester, CH2 2AX",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Cycle Store",
            "Laundry",
            "Car Parking",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/139146/17b0f16c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132221/fb7e1add.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139146/17b0f16c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/139146/17b0f16c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/abbeygate-chester-1608307431738?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "6d7e64ede52a47e68fbb30702c16868a"
    },
    {
        "room_name": "Tudor Place, Chester",
        "location": "Liverpool Rd, Chester, CH2 1AA",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Courtyard",
            "Study Area",
            "Cycle Store",
            "Cinema Room",
            "From£148/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148003/e2002db7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147999/cc455989.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148003/e2002db7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148003/e2002db7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/tudor-place-1912141144001?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "94a85ea9101147209840a288687b6dd1"
    },
    {
        "room_name": "Granary Studios, Chester",
        "location": "Hunter St, Chester, CH1 2AR",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Smart TV",
            "Games Room",
            "Cycle Store",
            "From£212/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147949/5923cb32.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147945/0e1db838.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147949/5923cb32.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147949/5923cb32.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/granary-studios-1912135224258?ref=search&regionId=46",
        "city": "Chester",
        "room_id": "b587071c6ebf4896b0071ed38bc552a6"
    },
    {
        "room_name": "iQ Hayes Wharf, Lincoln",
        "location": "Holmes Rd, Lincoln, LN1 1RF",
        "details": [
            "7 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Regular Social Event",
            "Secure Bicycle Storage",
            "Laundry Facility",
            "TV",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143448/541d4fcd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143442/39489062.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143448/541d4fcd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143448/541d4fcd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-hayes-wharf-lincoln-1811275045355?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "95fcbd427e6446539922ab5a5ffd85c6"
    },
    {
        "room_name": "Yugo Park Court, Lincoln",
        "location": "Park St, Lincoln, LN1 1UR",
        "details": [
            "2 Room Options",
            "3 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "On-Site Laundry",
            "Bike Storage",
            "Wellbeing Events",
            "Common Room",
            "Bike Storage",
            "From£114/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146509/43382cad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146508/48bac824.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146509/43382cad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146509/43382cad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/park-court-1907244278288?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "71e72386b1954b50aee96f7235a725d8"
    },
    {
        "room_name": "iQ Pavilions, Lincoln",
        "location": "Ruston Way, Lincoln, LN6 7BQ",
        "details": [
            "23 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "TV",
            "Lounge",
            "From£127/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/287042/28eac40f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143449/3fef6bce.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/287042/28eac40f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/287042/28eac40f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iq-pavilions-lincoln-1811272386094?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "4d87d82293764d408461c1c73f8eea0c"
    },
    {
        "room_name": "The Junxion, Lincoln",
        "location": "Brayford Wharf E, Lincoln, LN5 7BG",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Laundry Facility",
            "Maintenance",
            "Bike Storage",
            "Lounge",
            "Bike Storage",
            "On-Site Laundry",
            "From£121/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/171082/1226675d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/171082/2545935a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/171082/1226675d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/171082/1226675d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-junxion-lincoln-2012119101346?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "8c6851ea34b64e6286e7c44770d79c40"
    },
    {
        "room_name": "Pine Mill, Lincoln",
        "location": "Beevor St, Lincoln, LN6 7DJ",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Parking",
            "Bills Included",
            "Microwave",
            "Car Parking",
            "Communal Living",
            "From£132/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/177062/6207882d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/177062/e4bef31e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/177062/6207882d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/177062/6207882d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/pine-mill-2102081913609?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "7a0536b6b5784a869759a486cc861ec5"
    },
    {
        "room_name": "Gravity, Lincoln",
        "location": "Beaumont Fee, Lincoln, LN1 1UW",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Game Room",
            "Bills Included",
            "Gym",
            "From£182/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143601/e007688d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143601/71390cd7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143601/e007688d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143601/e007688d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/gravity-lincoln-1812049521931?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "871a72f237db480699afa8a49b8a4f09"
    },
    {
        "room_name": "Student Castle Lincoln, Lincoln",
        "location": "St Marks St., Lincoln, LN5 7BA",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Study Area",
            "Bills Included",
            "Gym",
            "Common Area",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149123/71fa3022.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149123/6a51642d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149123/71fa3022.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149123/71fa3022.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/student-castle-lincoln-2002205205544?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "23f305d1c9a641f58c06e3fec853c3b5"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 13 Blankney Crescent, Lincoln LN2 2EW",
        "location": "Blankney Cres, Lincoln, LN2 2EW",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "Recycling",
            "Refurbished",
            "From£81/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943742/972ef3cc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942919/668d7f9b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943742/972ef3cc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943742/972ef3cc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-13-blankney-crescent-lincoln-ln2-2ew-2401185313924?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "c15c3e2bf94c4d44bfc47a40421727db"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 64 A Monson Street, Lincoln LN5 7SB",
        "location": "Monson St, Lincoln, LN5 7SB",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Recycling",
            "Bike Storage",
            "Laundry Facility",
            "Wifi",
            "Cleaner",
            "Recycling",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1177597/8cbb4168.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1177564/bec99e98.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1177597/8cbb4168.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1177597/8cbb4168.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-6-bedroom-apartment-64-a-monson-street-lincoln-ln5-7sb-2407197162441?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "616a2400ff0b465aa70dfdc43073678b"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 3 Baggholme Road, Lincoln LN2 5BQ",
        "location": "Baggholme Rd, Lincoln, LN2 5BQ",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Recycling",
            "Central Heating",
            "Lockable Bedroom",
            "Recycling",
            "TV (Communal)",
            "From£94/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1157406/a0cda633.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157384/feebbfbd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157406/a0cda633.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1157406/a0cda633.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-6-bedroom-apartment-3-baggholme-road-lincoln-ln2-5bq-2407096500488?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "892cf03ac4e44ecd856ab8f37d289d40"
    },
    {
        "room_name": "Room in a 5 Bedroom Apartment, 64C Monson Street, Lincoln LN5 7SB",
        "location": "Monson St, Lincoln, LN5 7SB",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Recycling",
            "Laundry Facility",
            "Bills Included",
            "Wifi",
            "Cleaner",
            "Recycling",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1155807/2050fa79.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1155788/1a7d0cfc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1155807/2050fa79.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1155807/2050fa79.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-5-bedroom-apartment-64c-monson-street-lincoln-ln5-7sb-2407083026459?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "7a9d736c44064cdda302d4488cc577bc"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 53 Ripon Street, Lincoln LN5 7NH",
        "location": "Ripon St, Lincoln, LN5 7NH",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Central Heating",
            "Laundry Facility",
            "Lockable Bedroom",
            "Wifi",
            "Cleaner",
            "Recycling",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1155945/4109c877.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1155912/b9238e8f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1155945/4109c877.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1155945/4109c877.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-6-bedroom-apartment-53-ripon-street-lincoln-ln5-7nh-2407085096955?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "4e79c67bb37c4799b149d5cc7a1d7376"
    },
    {
        "room_name": "Room in a 5 Bedroom Apartment, 99 Monks Road, Lincoln LN2 5HS",
        "location": "Monks Rd, Lincoln, LN2 5HS",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "TV (Communal)",
            "Wifi",
            "Recycling",
            "TV (Communal)",
            "From£99/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1152495/d70ce29e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1152462/a4df21e9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1152495/d70ce29e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1152495/d70ce29e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-5-bedroom-apartment-99-monks-road-lincoln-ln2-5hs-2407057202717?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "f219bf3d60274ba5a77cdd9bde89e358"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 64 D Monson Street, Lincoln LN5 7SB",
        "location": "Monson St, Lincoln, LN5 7SB",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "TV (Communal)",
            "Newly Renovated",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/945973/f5c48e45.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943159/c5f3a789.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/945973/f5c48e45.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/945973/f5c48e45.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/4-bedroom-apartment-64-d-monson-street-lincoln-ln5-7rz-2401187768762?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "7ec0b2380cef464b8498c14116d2e9e2"
    },
    {
        "room_name": "Room in a 8 Bedroom Apartment, 71 Monks Road, Lincoln LN2 5HP",
        "location": "Monks Rd, Lincoln, LN2 5HP",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Central Heating",
            "Lockable Bedroom",
            "TV",
            "Wifi",
            "Cleaner",
            "Recycling",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1157374/3468dc4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157341/bc994fb6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157374/3468dc4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1157374/3468dc4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-8-bedroom-apartment-71-monks-road-lincoln-ln2-5hp-2407092175781?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "e460d9ee024a4748a175e467a362e3f1"
    },
    {
        "room_name": "6 Bedroom Apartment, 32 Monks Road, Lincoln LN2 5HW",
        "location": "Monks Rd, Lincoln, LN2 5HW",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Fully Furnished",
            "Bills Included",
            "Location Benefit",
            "Living Room",
            "Close To Uni",
            "From£117/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943122/d903aab6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943122/d903aab6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943122/d903aab6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-32-monks-road-lincoln-ln2-5hw-2401181490831?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "fcfde68a18f94d0ca725d74c4ab9a2e5"
    },
    {
        "room_name": "Room in a 8 Bedroom Apartment, 67 Monks Road, Lincoln LN2 5HP",
        "location": "Monks Rd, Lincoln, LN2 5HP",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Central Heating",
            "Wifi",
            "Recycling",
            "TV (Communal)",
            "From£117/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943144/692841d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943142/9dcebb06.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943144/692841d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943144/692841d6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/8-bedroom-apartment-67-monks-road-lincoln-ln2-5hp-2401181620528?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "6fa533032dad4e9388e767c046435605"
    },
    {
        "room_name": "Crosstrend House, Lincoln",
        "location": "Newport, Lincoln, LN1 3DF",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Fully Furnished",
            "Laundry Facility",
            "CCTV",
            "Wifi",
            "Tv In Loige",
            "Car Parking",
            "Communal Lounge",
            "From£130/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/230685/e727afe8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/230685/0028ac04.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/230685/e727afe8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/230685/e727afe8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/crosstrend-house-lincoln-2209028240039?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "36c570d2184248ecb132e46a3f623af1"
    },
    {
        "room_name": "College Mews, Lincoln",
        "location": "Holmes Rd, Lincoln, LN1 1RF",
        "details": [
            "2 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Bills Included",
            "Cctv",
            "Communal Courtyard",
            "From£167/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/216325/bdf34911.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/216325/94a07db9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/216325/bdf34911.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/216325/bdf34911.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/college-mews-2204151933561?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "805d93995c564ad8a388914366724a89"
    },
    {
        "room_name": "Room in a 12 Bedroom Apartment, Freya House, Thesiger Street, Lincoln LN5 7UL",
        "location": "Thesiger St, Lincoln, LN5 7UL",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Fully Furnished",
            "Bills Included",
            "Lounge",
            "Close To Uni",
            "Flatscreen TV",
            "Big Comfy Sofas",
            "From£65/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943954/0c0cb304.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943951/d2720938.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943954/0c0cb304.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943954/0c0cb304.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/12-bedroom-apartment-freya-house-thesiger-street-lincoln-ln5-7ul-2401221372505?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "b81bebe00d09444bbb1940636613ed86"
    },
    {
        "room_name": "Room in a 5 Bedroom Apartment, 1 Mount Street, Lincoln LN1 3JE",
        "location": "Mount St, Lincoln, LN1 3JE",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "WiFi",
            "Bills Included",
            "Laundry Facility",
            "TV",
            "WiFi",
            "Cooker",
            "Fridge",
            "Freezer",
            "Dishwasher",
            "From£69/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943074/6122d5d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943055/5ce773c1.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943074/6122d5d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943074/6122d5d8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/5-bedroom-apartment-1-mount-street-lincoln-ln1-3je-2401189627333?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "7b17b4413a7a4257a31977913fcf6aee"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 24 West Parade, Lincoln LN1 1HY",
        "location": "W Parade, Lincoln, LN1 1HY",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Contents Insurance",
            "Smart Locking System",
            "Bills Included",
            "Recycling",
            "Close To Uni",
            "TV (Communal)",
            "From£81/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943117/8343747f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943113/e16f4fa9.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943117/8343747f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943117/8343747f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-4-bedroom-apartment-24-west-parade-lincoln-ln1-1hy-2401181834646?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "7760cf5f08664cb1885e3bf754eea241"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 126 Scorer Street, Lincoln LN5 7SX",
        "location": "Scorer St, Lincoln, LN5",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Fully Furnished",
            "Bills Included",
            "Living Room",
            "Close To Uni",
            "Flatscreen TV",
            "Big Comfy Sofas",
            "From£83/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943772/5cfac3cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942939/4a2e8ece.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943772/5cfac3cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943772/5cfac3cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-126-scorer-street-lincoln-ln5-7sx-2401186964293?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "5a4bdad50ac541d789a0f923207f8a91"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 1 Baggholme Road, Lincoln LN2 5BQ",
        "location": "Baggholme Rd, Lincoln, LN2 5BQ",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Fully Furnished",
            "Bills Included",
            "Location Benefit",
            "Recycling",
            "Close To Uni",
            "TV (Communal)",
            "From£88/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943940/48849243.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943939/d9e081c9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943940/48849243.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943940/48849243.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-1-baggholme-road-lincoln-ln2-5bq-2401221391569?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "431ac9e8bcf44398b1e2e38df751348b"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 258 West Parade, Lincoln LN1 1LY",
        "location": "W Parade, Lincoln, LN1 1LY",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "Recycling",
            "TV (Communal)",
            "Furnished Interiors",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943063/c618c98b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942921/1d659447.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943063/c618c98b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943063/c618c98b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-258-west-parade-lincoln-ln1-1qs-2401183866163?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "72f7fe22b1fa4f85960e1f19b1c750c3"
    },
    {
        "room_name": "Room in a 7 Bedroom Apartment, 10 Richmond Road, Lincoln LN1 1LQ",
        "location": "Richmond Rd, Lincoln, LN1 1LQ",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Contents Insurance",
            "Smart Locking System",
            "Bills Included",
            "WiFi",
            "Recycling",
            "Plush Sofas",
            "Close To Uni",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943141/a511081c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943125/214677a8.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943141/a511081c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943141/a511081c.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-7-bedroom-apartment-10-richmond-road-lincoln-ln1-1lq-2401185568574?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "81fadfe641d5438fbdbd97443008ef29"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 232 West Parade, Lincoln LN1 1LY",
        "location": "W Parade, Lincoln, LN1 1LY",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "WiFi",
            "Bills Included",
            "Fob Entry System",
            "WiFi",
            "Recycling",
            "TV (Communal)",
            "Communal Dining",
            "Central Heating",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/942965/ccb55e24.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942924/9bab1a0a.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942965/ccb55e24.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/942965/ccb55e24.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-232-west-parade-lincoln-ln1-1ly-lincoln-2401183933855?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "9376f26ade374ec4adde4e19d1f11388"
    },
    {
        "room_name": "Room in a 5 Bedroom Apartment, 43 Thesiger Street, Lincoln LN5 7UW",
        "location": "Thesiger St, Lincoln, LN5 7UW",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Washing Machine",
            "Recycling",
            "Furnished",
            "Modern Appliances",
            "Well-Equipped Kitchen",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943156/5271de66.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943149/1fe47a3b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943156/5271de66.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943156/5271de66.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/5-bedroom-apartment-43-thesiger-street-lincoln-ln5-7uw-2401184198298?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "ed4a6e09e0f448ccb56d61aed537ead0"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 214 West Parade, Lincoln LN1 1LY",
        "location": "W Parade, Lincoln, LN1 1LY",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Close To Campus",
            "Central Heating",
            "Bills Included",
            "Wifi",
            "Recycling",
            "Central Heating",
            "Close To Campus",
            "From£89/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1152617/fbb1ef97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1152584/5f506677.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1152617/fbb1ef97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1152617/fbb1ef97.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-6-bedroom-apartment-214-west-parade-lincoln-ln1-1ly-2407051411056?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "0815c8dc563a45f4bec8dbf294716125"
    },
    {
        "room_name": "Room in a 7 Bedroom Apartment, 64B Monson Street, Lincoln LN5 7SB",
        "location": "Monson St, Lincoln, LN5 7SB",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Bike Storage",
            "Central Heating",
            "TV (Communal)",
            "Wifi",
            "Cleaner",
            "Recycling",
            "Bike Storage",
            "TV (Communal)",
            "From£94/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1156239/57f52b2f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1156205/8250a079.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1156239/57f52b2f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1156239/57f52b2f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-7-bedroom-apartment-64b-monson-street-lincoln-ln5-7sb-2407086031758?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "6f62b94815d743ca899be39e213f4876"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 64E Monson Street, Lincoln LN5 7SB",
        "location": "Monson St, Lincoln, LN5 7SB",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Central Heating",
            "Washing Machine",
            "Dishwasher",
            "TV (Communal)",
            "Central Heating",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1157354/02c51a4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157241/a278baad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157354/02c51a4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1157354/02c51a4e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-4-bedroom-apartment-64e-monson-street-lincoln-ln5-7sb-2407092511693?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "31f6c9c16951471789d4cf9d4c13b656"
    },
    {
        "room_name": "Room in a 7 Bedroom Apartment, 19 St. Catherines, Lincoln LN5 8LT",
        "location": "St Catherines, Lincoln, LN5 8LT",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "Recycling",
            "Superfast Broadband",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1153740/565c83d7.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1153707/0892bb15.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1153740/565c83d7.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1153740/565c83d7.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-7-bedroom-apartment-19-st-catherines-lincoln-ln5-8lt-2407067485693?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "2b1519e4f71f4cb391aa6311d776daee"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 44 Mount Street, Lincoln LN1 3JG",
        "location": "Mount St, Lincoln, LN1 3JG",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Furnished",
            "All Bills Included",
            "Location Benefit",
            "Broadband",
            "Recycling",
            "TV (Communal)",
            "From£97/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943023/42274616.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942918/05bb5f0f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943023/42274616.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943023/42274616.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/4-bedroom-apartment-44-mount-street-lincoln-ln1-3jg-2401187089528?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "90cc859d23d34e1e8b43e8b0ae9f16f0"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 7 Westbourne Grove, Lincoln LN1 1RP",
        "location": "Westbourne Grove, Lincoln, LN1 1RP",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "Recycling",
            "Modern Appliances",
            "Superfast Broadband",
            "From£100/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1153656/95ff5bdc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1153604/a2f9335b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1153656/95ff5bdc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1153656/95ff5bdc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-4-bedroom-apartment-7-westbourne-grove-lincoln-ln1-1rp-2407069888901?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "74363f8d092c42fba613f744475702f4"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 75 West Parade, Lincoln LN1 1QL",
        "location": "W Parade, Lincoln, LN1 1QL",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Maintenance",
            "Living Room",
            "Close To Uni",
            "Flatscreen TV",
            "Big Comfy Sofas",
            "From£106/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943112/82eff077.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943075/723616e3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943112/82eff077.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943112/82eff077.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/4-bedroom-apartment-75-west-parade-lincoln-ln1-1ql-2401184699959?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "e317f0a89f1f4c0abd2812497d5bb52b"
    },
    {
        "room_name": "Room in a 8 Bedroom Apartment, 69 Monks Road, Lincoln LN2 5HP",
        "location": "Monks Rd, Lincoln, LN2 5HP",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Fully Furnished",
            "Bills Included",
            "Recycling",
            "Close To Uni",
            "Flatscreen TV",
            "Central Heating",
            "From£109/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943762/fea66750.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942925/321fe8fd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943762/fea66750.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943762/fea66750.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/7-bedroom-apartment-69-monks-road-lincoln-ln2-5hp-2401184261648?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "0ed9d2c167eb43b286a5ceb12c1ffed6"
    },
    {
        "room_name": "Room in a 4 Bedroom Apartment, 11 Charles Street West, Lincoln LN1 1QP",
        "location": "Charles St W, Lincoln, LN1 1QP",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Laundry Facility",
            "Recycling",
            "Dining Table",
            "TV (Communal)",
            "Superfast Broadband",
            "From£113/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943317/9b256195.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943123/134d465a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943317/9b256195.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943317/9b256195.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/4-bedroom-apartment-11-charles-street-west-lincoln-ln1-1qp-2401186099143?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "95c14579451a45aeb994a647fac4311d"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 148 West Parade, Lincoln LN1 1QL",
        "location": "W Parade, Lincoln, LN1 1QL",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Furnished",
            "All Bills Included",
            "Location Benefit",
            "Dryer",
            "Close To Uni",
            "Flatscreen TV",
            "Washing Machine",
            "From£114/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943172/3d7061b2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943153/360de102.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943172/3d7061b2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943172/3d7061b2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-148-west-parade-lincoln-ln1-1ql-2401187295609?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "051f21e48d1e465f8c67b642878d02fe"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 47 Monks Road, Lincoln LN2 5HN",
        "location": "Monks Rd, Lincoln, LN2 5HN",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Fully Furnished",
            "Laundry Facility",
            "Bills Included",
            "Living Room",
            "Close To Uni",
            "Flatscreen TV",
            "From£117/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943150/69dd6f3c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943148/dd21e8d4.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943150/69dd6f3c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943150/69dd6f3c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-47-monks-road-lincoln-ln2-5hn-2401188106311?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "2fed3cff5a304e56b112b21d1860624c"
    },
    {
        "room_name": "6 Bedroom Apartment, 43 Monks Road, Lincoln LN2 5HN",
        "location": "Monks Rd, Lincoln, LN2 5HN",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Recycling",
            "Central Heating",
            "TV",
            "Recycling",
            "TV (Communal)",
            "Central Heating",
            "From£117/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/946322/da29b11e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943126/bb3730a7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/946322/da29b11e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/946322/da29b11e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-43-monks-road-lincoln-ln2-5hn-2401186181584?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "73ea99d6d4cd4150a4007b4508ab0108"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 45 Monks Road, Lincoln LN2 5HN",
        "location": "Monks Rd, Lincoln, LN2 5HN",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Bills Included",
            "Terraced House",
            "Flat Screen TV",
            "Close To Uni",
            "Flatscreen TV",
            "Big Comfy Sofas",
            "From£117/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943137/ba1b4014.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943118/b49bdcf0.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943137/ba1b4014.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943137/ba1b4014.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/6-bedroom-apartment-45-monks-road-lincoln-ln2-5hn-2401180516219?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "b836f622931b4ab3b87d40f6694ae44c"
    },
    {
        "room_name": "Room in a 10 Bedroom Apartment, 15 Monks Road, Lincoln LN2 5HL",
        "location": "Monks Rd, Lincoln, LN2 5HL",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Recycling",
            "TV (Communal)",
            "Bills Included",
            "Wifi",
            "Recycling",
            "TV (Communal)",
            "From£120/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/945882/0be60da5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943152/ddae8e60.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/945882/0be60da5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/945882/0be60da5.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/10-bedroom-apartment-15-monks-road-lincoln-ln2-5hl-2401186186362?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "51a442f2923847758a8eea10c88a62e0"
    },
    {
        "room_name": "Room in a 7 Bedroom Apartment, 106 Yarborough Road, Lincoln LN1 1HR",
        "location": "Yarborough Rd, Lincoln, LN1 1HR",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Recycling",
            "Laundry Facility",
            "Central Heating",
            "Wifi",
            "Recycling",
            "Central Heating",
            "Close To Campus",
            "From£124/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/953057/efd61164.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/952781/bd5453a6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/953057/efd61164.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/953057/efd61164.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/studio-on-yarborough-road-lincoln-studio-room-106-yarborough-road-lincoln-ln1-1hr-2402088997656?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "f79bc710a3d543628b2b671be317c690"
    },
    {
        "room_name": "Room in a 7 Bedroom Apartment, 104 Yarborough Road, Lincoln LN1 1HR",
        "location": "Yarborough Rd, Lincoln, LN1 1HR",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Contents Insurance",
            "Smart Locking System",
            "Bills Included",
            "Recycling",
            "Close To Uni",
            "TV (Communal)",
            "From£129/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943147/e3dbe6d5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943143/4a9c526e.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943147/e3dbe6d5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943147/e3dbe6d5.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/studio-room-104-yarborough-road-lincoln-ln1-1hr-2401187120904?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "c9b68979ec2845e394b39f9d3594cdef"
    },
    {
        "room_name": "Room in a 2 Bedroom Apartment, 20 G Portland Street, Lincoln LN5 7JX",
        "location": "Portland St, Lincoln, LN5 7JX",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Central Heating",
            "TV",
            "Bills Included",
            "Wifi",
            "Cleaner",
            "Recycling",
            "TV (Communal)",
            "From£134/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1156131/6344e986.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1156097/1ce30b57.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1156131/6344e986.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1156131/6344e986.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-2-bedroom-apartment-20-g-portland-street-lincoln-ln5-7jx-2407083803150?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "7d395de126104c409fd1a7bcb629c553"
    },
    {
        "room_name": "Room in a 7 Bedroom Apartment, 19 The Avenue, Lincoln LN1 1PD",
        "location": "The Avenue, Lincoln, LN1 1PD",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Recycling",
            "Lockable Bedroom",
            "Central Heating",
            "Recycling",
            "Close To Uni",
            "TV (Communal)",
            "From£134/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1157050/74e7ec81.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157015/16da5bab.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1157050/74e7ec81.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1157050/74e7ec81.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/room-in-a-7-bedroom-apartment-19-the-avenue-lincoln-ln1-1pd-2407094967570?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "b5b4e91d9eee4fb6bef6ce8dbf7d5be4"
    },
    {
        "room_name": "Room in a 6 Bedroom Apartment, 12 Richmond Road, Lincoln LN1 1LQ",
        "location": "Richmond Rd, Lincoln, LN1 1LQ",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "Contents Insurance",
            "Lockable Bedroom",
            "Bills Included",
            "Recycling",
            "Close To Uni",
            "TV (Communal)",
            "From£143/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943078/e2237185.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943071/aeae105b.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943078/e2237185.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943078/e2237185.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/studio-room-12-richmond-road-lincoln-ln1-1lq-2401186935110?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "a11327f27b5549ad997cb08b50eb19ef"
    },
    {
        "room_name": "1 Bedroom Apartment, 20 F Portland Street, Lincoln LN5 7JX",
        "location": "Portland St, Lincoln, LN5 7JX",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "Wifi",
            "Bills Included",
            "Recycling",
            "TV (Communal)",
            "Central Heating",
            "Modern Appliances",
            "Restaurants Nearby",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/943403/56ab3f41.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/942909/e2ac8cf6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/943403/56ab3f41.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/943403/56ab3f41.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/1-bedroom-apartment-20-f-portland-street-lincoln-ln5-7jx-2401186887144?ref=search&regionId=29",
        "city": "Lincoln",
        "room_id": "2f6ef72b42ad4dc0a288e9a9eb65ed03"
    },
    {
        "room_name": "Northernhay House, Exeter",
        "location": "Northernhay Pl, Exeter, EX4 3RY",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Car Parking",
            "CCTV",
            "Lifts To All Floors",
            "From£150/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147185/0e295770.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144593/570b44bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147185/0e295770.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147185/0e295770.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/northernhay-house-1904205371010?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "e9abf893ffc74667b48656c061eb2a3f"
    },
    {
        "room_name": "Central Living, Exeter",
        "location": "Paris St, Exeter, EX1 2JY",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Laundry Facility",
            "Games Area",
            "Bills Included",
            "Games Area",
            "From£152/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149790/d5ea427a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149779/3822e327.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149790/d5ea427a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149790/d5ea427a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/central-living-2004031090064?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "07462c4657a445848440df3756258a5e"
    },
    {
        "room_name": "Exeter One, Exeter",
        "location": "Cowley Bridge Rd, Exeter, EX4 5FH",
        "details": [
            "18 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "24h Security",
            "Laundry Facility",
            "Bills Included",
            "CCTV",
            "Garden",
            "Recycling",
            "From£154/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143127/38933340.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/138705/7d31f2ef.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143127/38933340.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143127/38933340.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/exeter-one-exeter-1801063238517?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "4eb0996847ed4cd5b3e758a4bd3d56c6"
    },
    {
        "room_name": "INTO Exeter University, Exeter",
        "location": "Stocker Rd, Exeter, EX4 4PY",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Weekly Room Cleaning Service",
            "Bills Included",
            "Badminton",
            "Picnic Table",
            "From£170/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1227830/f24d0873.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1227830/3711fefc.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1227830/f24d0873.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1227830/f24d0873.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/into-exeter-university-exeter-2408159453459?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "828d2042979a455385f48f046797ce67"
    },
    {
        "room_name": "Vita Student Portland House, Exeter",
        "location": "Longbrook St, Exeter, EX4 6AH",
        "details": [
            "6 Room Options",
            "3 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Cinema",
            "Study Area",
            "Movie Room",
            "Bike Store",
            "Pool Table",
            "Free Events",
            "From£277/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/140738/1cad8c0b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/139888/e5c2f55b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/140738/1cad8c0b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/140738/1cad8c0b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/vita-student-exeter-1803316546677?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "9b298516808a462494630a57e2e87df7"
    },
    {
        "room_name": "The Depot, Exeter",
        "location": "Bampfylde St, Exeter, EX1 2FW",
        "details": [
            "18 Room Options",
            "3 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Dual Occupancy",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Free Gym",
            "From£193/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/196686/675a4ca8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143763/dfc8e4c6.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/196686/675a4ca8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/196686/675a4ca8.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-depot-exeter-1812147336651?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "e2b2a7332dec47f2be8422a4eb5a3316"
    },
    {
        "room_name": "Collegiate Bonhay House, Exeter",
        "location": "Bonhay Rd, Exeter, EX4 4BG",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Room",
            "Bike Storage",
            "Bills Included",
            "Bike Storage",
            "From£209/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146401/8eb7e2bd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146393/af55cf6d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146401/8eb7e2bd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146401/8eb7e2bd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/bonhay-house-exeter-1907189698046?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "2ed464479b094e419ff4fe5558960be8"
    },
    {
        "room_name": "Collegiate King Edward Studios, Exeter",
        "location": "Cowley Bridge Rd, Exeter, EX4 4NA",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "24/7 Security/Support",
            "Laundry Room",
            "Bills Included",
            "Common Room",
            "From£219/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/146406/2361c261.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146405/c980a674.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146406/2361c261.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/146406/2361c261.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/king-edward-studios-exeter-1907184721191?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "0bab822476824f48945afa98198f2a94"
    },
    {
        "room_name": "Collegiate Point Exe, Exeter",
        "location": "Brunel Cl, Exeter, EX4 4FD",
        "details": [
            "2 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Wifi",
            "Laundry Facility",
            "Bike Storage",
            "Wi-Fi",
            "Lounges",
            "From£223/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/157680/17a1524b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/157680/0914f6cd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/157680/17a1524b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/157680/17a1524b.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/point-exe-exeter-2008114733418?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "e093f645c91d4f75a3a5c96defaed017"
    },
    {
        "room_name": "Walnut Gardens, Exeter",
        "location": "St. Davids Hill, Exeter, EX4",
        "details": [
            "10 Room Options",
            "2 Offers",
            "Instant Booking",
            "No Visa No Pay",
            "Pay In Instalment",
            "Study Area",
            "Laundry Facility",
            "Bike Storage",
            "Concierg",
            "From£227/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/165671/71b31b8d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/165671/be8d5bb3.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/165671/71b31b8d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/165671/71b31b8d.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/study-inn-walnut-gardens-exeter-2011064818889?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "a7a45748677749b3af0fd16bf5d2cd3c"
    },
    {
        "room_name": "Collegiate The Neighbourhood, Exeter",
        "location": "Mary Arches St, Exeter, EX4 3AZ",
        "details": [
            "14 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Laundry Facility",
            "Bills Included",
            "Cinema",
            "Laundry",
            "From£260/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/931221/0817678c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146515/bf6f1c88.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/931221/0817678c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/931221/0817678c.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-neighbourhood-exeter-1907259588495?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "1fa9898e3ea1448faa64fecb5fa00d34"
    },
    {
        "room_name": "The Leat Apartments, Exeter",
        "location": "Tudor St, Exeter, EX4 3FF",
        "details": [
            "7 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "Dual Occupancy",
            "Laundry Facility",
            "Bills Included",
            "Secure Covered Bike Storage",
            "From£295/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/588572/afd5fd32.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/588572/c61cba8f.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/588572/afd5fd32.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/588572/afd5fd32.jpeg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-leat-apartments-exeter-2309086212946?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "6d065b90a51645f282a905c551b544ea"
    },
    {
        "room_name": "Atlas House, Exeter",
        "location": "Heavitree Rd, Exeter, EX1 2FR",
        "details": [
            "13 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Pool Table",
            "Study Area",
            "Bike Storage",
            "Pool Table",
            "Study Area",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/141717/8cf4b341.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141717/dd0718bf.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/141717/8cf4b341.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/141717/8cf4b341.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/atlas-house-exeter-1806023849216?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "518d992eccde47fe96e8a50ef6000537"
    },
    {
        "room_name": "Clifford House, Exeter",
        "location": "Stadium Way, Exeter, EX4 6AQ",
        "details": [
            "4 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Yoga Studio",
            "Post Collection",
            "All Utility Bills Included",
            "Gym",
            "Garden",
            "Study Room",
            "From£185/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/1059052/85e7f136.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1058938/4c6a5bad.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/1059052/85e7f136.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/1059052/85e7f136.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/clifford-house-exeter-2405078842654?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "74c526c81086450799f3ce7d4c1151d8"
    },
    {
        "room_name": "Iron Bridge Studios, Exeter",
        "location": "Lower N St, Exeter, EX4 3EU",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Common Room",
            "Bike Storage",
            "Laundry Room",
            "Cleaner",
            "Common Room",
            "From£205/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/149353/fae49e70.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149351/584ad4ac.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/149353/fae49e70.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/149353/fae49e70.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/iron-bridge-studios-2003118722066?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "9abd1376f9e34babb5def9c86d04571d"
    },
    {
        "room_name": "West Gate, Exeter",
        "location": "Frog St, Exeter, EX1 1FE",
        "details": [
            "12 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Bike Storage",
            "Cinema",
            "Library",
            "Study Room",
            "From£225/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/189421/9aea307a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/154275/800ab8dd.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/189421/9aea307a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/189421/9aea307a.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/west-gate-2006259568591?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "239a7663b72c412eb872e122000cfe34"
    },
    {
        "room_name": "Renslade House, Exeter",
        "location": "Bonhay Rd, Exeter, EX4 3AY",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Gym",
            "Cinema Room",
            "All Bills Included",
            "Gym",
            "Recycling",
            "From£230/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/143659/36a327fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143659/4936701e.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/143659/36a327fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/143659/36a327fc.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/renslade-house-exeter-1812089499535?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "6cbdf0d8d26d4eeba712dd44ab9926b5"
    },
    {
        "room_name": "The Kingfisher, Exeter",
        "location": "Western Wy., Exeter, EX1 2FJ",
        "details": [
            "5 Room Options",
            "3 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Dual Occupancy",
            "Games Room",
            "On-Site Laundry",
            "Bills Included",
            "From£243/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132395/7f811774.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132395/a8198fb7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132395/7f811774.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132395/7f811774.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-kingfisher-exeter-1608300109394?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "853b579e8fdb4b7082a3e8671f2f3805"
    },
    {
        "room_name": "Queen Street Studios, Exeter",
        "location": "Queen St, Exeter, EX4 3FG",
        "details": [
            "8 Room Options",
            "2 Offers",
            "Instant Booking",
            "Pay In Instalment",
            "No Visa No Pay",
            "Study Area",
            "Games Area",
            "Vending Machine",
            "Lounge",
            "Laundry",
            "From£249/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/136934/0c641825.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136931/61495696.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/136934/0c641825.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/136934/0c641825.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/queen-street-studios-exeter-1708086328842?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "34bae6d3c41f440e92b452c43a20731a"
    },
    {
        "room_name": "The City Arcade, Exeter",
        "location": "John St, Exeter, EX1 1BL",
        "details": [
            "5 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Secure Bike Storage",
            "On-Site Maintenance",
            "Laundry Facility",
            "Communal Lounge",
            "Communal Kitchen",
            "From£149/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/171719/9af379f7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/171719/be66a1bb.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/171719/9af379f7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/171719/9af379f7.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-city-arcade-2012150648883?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "7600375db9e046bbbb239f60b03f1d5f"
    },
    {
        "room_name": "Boutique Student Living, Exeter",
        "location": "Blackboy Rd, Exeter, EX4 6SG",
        "details": [
            "9 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Gym",
            "Bills Included",
            "Cinema",
            "TV",
            "Lounge",
            "Games Area",
            "Cinema Room",
            "From£163/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/913943/612c0612.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/913943/1dc67ff2.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/913943/612c0612.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/913943/612c0612.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/boutique-student-living-exeter-2309182079195?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "ca38d8fea9234077947bb36a75d2d7eb"
    },
    {
        "room_name": "Trust House, Exeter",
        "location": "Blackboy Rd, Exeter, EX4 6TW",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Pay In Instalment",
            "Games Area",
            "Laundry Facility",
            "Bills Included",
            "Games Area",
            "From£175/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/147183/a9905931.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/144596/86feee49.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/147183/a9905931.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/147183/a9905931.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/exeter-trust-house-1904207313971?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "3f99155c6e7b4027a60caa4a55f14d50"
    },
    {
        "room_name": "The Northfield, Exeter",
        "location": "New N Rd, Exeter, EX4 4FB",
        "details": [
            "1 Room Option",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Study Area",
            "Bills Included",
            "Games Area",
            "Communal Lounge",
            "From£190/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/182781/812a80f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/182780/85dc8559.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/182781/812a80f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/182781/812a80f9.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/the-northfield-2104225462972?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "7bf4a57e082f42ca8e7933e20b8bad52"
    },
    {
        "room_name": "Yugo Clifford House, Exeter",
        "location": "Stadium Way, Exeter, EX4 6AQ",
        "details": [
            "3 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "High-Speed Wifi",
            "24-Hour Support",
            "On-Site Laundry",
            "Gym",
            "Courtyard",
            "Key Access",
            "From£199/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/148711/b53b0197.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/146505/7c5db04f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/148711/b53b0197.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/148711/b53b0197.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/clifford-house-exeter-1907247074373?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "1f615051979741a6a99dc06b14294fec"
    },
    {
        "room_name": "Verney Street Studios, Exeter",
        "location": "Verney St, Exeter, EX1 2AW",
        "details": [
            "3 Room Options",
            "2 Offers",
            "No Visa No Pay",
            "Pay In Instalment",
            "Gym",
            "Bills Included",
            "CCTV",
            "TV (Communal)",
            "Communal Space And Courtyard",
            "From£253/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/246962/1237ec17.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/246962/ea82100d.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/246962/1237ec17.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/246962/1237ec17.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/verney-street-studios-exeter-2212203072335?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "fb4bcc4c7f68492c8145de4d508eeeb7"
    },
    {
        "room_name": "Cricket Field Court, Exeter",
        "location": "Prince of Wales Rd, Exeter, EX4 4FU",
        "details": [
            "6 Room Options",
            "2 Offers",
            "Pay In Instalment",
            "No Visa No Pay",
            "Laundry Facility",
            "Bills Included",
            "Cycle Store",
            "Lounge",
            "Laundry",
            "Study Room",
            "From£205/ week"
        ],
        "image_urls_list": [
            "https://assets.amberstudent.com/inventories/132401/498b750f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132400/4bbc2805.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
            "https://assets.amberstudent.com/inventories/132401/498b750f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto"
        ],
        "image_url": "https://assets.amberstudent.com/inventories/132401/498b750f.jpg?w=480&h=360&fit=crop&q=70&auto=format&trim=auto",
        "room_link": "https://amberstudent.com/places/cricket-field-court-exeter-1608300988965?ref=search&regionId=50",
        "city": "Exeter",
        "room_id": "7bbb5ac3cba34b1295786fe74612ce90"
    }
]