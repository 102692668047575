
import React, { createContext, useContext, useState } from 'react';

const JobsContext = createContext();

export function JobsProvider({ children }) {
  const [selectedOption, setSelectedOption] = useState('Add Job Post');
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  const value = {
    selectedOption,
    setSelectedOption,
    jobs,
    setJobs,
    loading,
    setLoading
  };

  return (
    <JobsContext.Provider value={value}>
      {children}
    </JobsContext.Provider>
  );
}

export const useJobs = () => {
  const context = useContext(JobsContext);
  if (!context) {
    throw new Error('useJobs must be used within a JobsProvider');
  }
  return context;
};